.container {
  background: #f9f9f9;
  box-shadow: 4px 4px 4px 0px #0000001a;
  position: absolute;
  border-radius: 8px;
  top: 5rem !important;
  left: 0 !important;
  padding: 1rem;
  text-align: left;
  width: 100%;
  overflow-y: scroll;
  height: fit-content;
  max-height: 70vh;
}

.container::-webkit-scrollbar {
  background-color: #f9f9f9;
  width: 5px;
}

/* background of the scrollbar except button or resizer */
.container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
.container::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 16px;
  border: 4px solid #d7d7d7;
  background: transparent !important;
}

/* set button(top and bottom of the scrollbar) */
.container::-webkit-scrollbar-button {
  display: none;
}



.filterButton {
  background: #fcc02c !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  margin-top: 1rem !important;
}

.filterButton:disabled {
  background: #dfdfdf !important;
  color: #2b2b2b !important;
}

.filterButton:disabled:hover {
  background: #dfdfdf !important;
}

.filterButton:hover {
  background: #e5af28 !important;
}

.filterButton:active {
  background: #fcc02c !important;
}
@media (max-width: 768px) {
  .container {
    width: 15rem;
    position: absolute !important;
    top: 3.5rem !important;
    left: -5.25rem !important;
  }
}

@media (max-width: 380px) {
  .container {
    left: -6.5rem !important;
  }
}
