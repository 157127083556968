.wave {
    padding: 0 !important;
    margin-top: -.5rem;
    transform: rotate(180deg);
    width: 100%;
}

.header {
    background: #FCC02C;
    background-position: top;
    padding: 2rem 2.5rem 1rem;
    width: 100%;
}

.headerTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: -moz-fit-content;
    width: -webkit-fill-available;
    width: fit-content;
    position: relative;
    padding-left: 3rem;
}

.headerTitle {
    font-size: 25px;
    font-weight: normal;
    text-align: left;
    color: #FFFFFF;
    margin: 0 !important;
}

.headerTitle span {
    display: inline-block;
    font-weight: bold;
}

.headerIcon {
    position: absolute;
    left: 0;
    margin: auto;
    height: 30px;
    width: 30px;
}

@media screen and (min-width: 768px) {

    .header {
        padding: 5rem 0 2rem;
    }

    .headerTitleContainer {
        max-width: 90%;
    }
}

@media screen and (min-width: 1024px) {
    .headerTitle {
        font-size: 35px;
    }

    .headerTitle span {
        margin-left: 8px;
    }
}