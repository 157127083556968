.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 2rem;
    width: 100%;
}

.footerText {
    color: #808080;
    font-weight: 600;
    font-size: 16px;
    margin: 0 !important;
}

.footerLogo {
    margin-left: 5px;
    max-width: 150px;
    max-height: 75px;
}