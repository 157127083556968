.containerHeader {
    background-color: #FCC02C;
    /* position: fixed; */
    width: 100% !important;
}

.logoIcon {
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    margin-left: 3rem;
}

.leftCol {
    height: 75px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.rightCol {
    background-color: #E4056F;
    height: 75px !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex !important;
    justify-content: space-between !important;
    /* justify-content: flex-end !important; */
    align-items: center !important;
    padding: 0 50px;
}

.rightColContent {
    display: flex;
    justify-content: flex-end;
}

.userIcon {
    background-color: #FCC02C;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 22px;
}

.searchInputHeader {
    max-width: 380px !important;
    width: 50% !important;
    height: 50px !important;
    border-radius: 32px !important;
    margin: auto 0 !important;
    margin-left: -.5rem !important;
}

.searchIcon {
    cursor: pointer;
    font-size: 20px !important;
    padding-right: 8px !important;
    padding-left: 4px !important;
}

.headerSearchMobile {
    display: none;
}

.headerSearchWeb {
    display: flex;
    justify-content: space-between;
    /* margin-right: -90px !important; */
    width: 100% !important;
}

.menuResponsiveIconMobile {
    cursor: pointer;
    font-size: 35px !important;
    color: white !important
}

.labelLateralMenu {
    padding-left: 15px !important;
    padding-top: 7px !important;
    cursor: pointer !important;
}

.typeBoxLateralMenu {

    width: 2em !important;
    height: 2em !important;
    border-radius: 20px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 50% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
    font-size: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.searchIconMobile {
    cursor: pointer !important;
    font-size: 35px !important;
    padding-right: 30px !important;
    color: white !important
}

.searchMobileArea {
    background: #FCC02C !important;
    visibility: hidden;
    height: 0px;
    transition: all ease 0.5s;
    text-align: center;
}

.searchMobileAreaOpen {
    background: #FCC02C !important;
    visibility: visible;
    height: 70px;
    position: relative;
    z-index: 1000;
    transition: all ease 0.5s;
    text-align: center;
}

.searchInputHeaderMobileOpen {
    width: 90% !important;
    height: 50px !important;
    border-radius: 50px !important;
    margin-top: 10px;
    visibility: visible !important;
}

.searchInputHeaderMobile {
    visibility: hidden !important;
    opacity: 0 !important;
    width: 90% !important;
    height: 50px !important;
    border-radius: 50px !important;
    margin-top: 20px;
}

.userLabel {
    /* background-color: #727071; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    width: 50%;
}

.userLabelName,
.userLabelName * {
    margin: 0 !important;
    padding: 0 !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
}

.userLabelName a:hover {
    color: #FCC02C !important;
}

.userLabelIcon {
    cursor: pointer;
    transform: translateY(9%);
    margin-right: 1rem;
}

@media (max-width: 700px) {
    .searchMobileAreaOpen {
        height: 70px !important;
    }

    .userLabelName {
        display: none;
    }
}

@media (max-width: 768px) {
    .headerSearchWeb {
        /* visibility: hidden; */
        display: none;
        width: 0% !important;
    }

    .logoIcon{
        margin: 0 !important;
        margin-right: auto;
    }

    .headerSearchMobile {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100% !important;
    }

    .rightCol {
        padding-right: 25px;
    }
}