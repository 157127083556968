.rowContainer{
    margin-bottom: 2%;
    margin-top: 2%;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}


.rowContainer::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.rowContainer::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.rowContainer::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.rowContainer::-webkit-scrollbar-button {
    display:none;
}

.promotionsWeb{
    margin-top: 30px;
}

.typeBox{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.labelBox{
    background-color: white !important;
    border-radius: 40px !important;
    width: 90% !important;
    position: absolute;
    bottom: 10%;
    left: 5%;
}

.promotionBox{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

.leftButton{
    text-align: end;
    padding-right: 50px !important;
}

.rightButton{
    margin-left: 50px !important;
}

.promotionsMobile{
    visibility: hidden;
    height: 0px !important;
}

.loadingSpinner{
    padding-top: 40px !important;
}

.ant-spin-dot-item{
   background-color: red !important;
}

@media (max-width: 900px){

    .promotionsWeb{
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
    }

    .promotionsMobile{
        margin-top: 40px;
        visibility: visible;
        height: auto !important;
    }

    .promotionBoxMobile{
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    .typeBox{
        width: 13.125em !important;
        height: 13.125em !important;
    }

    .promotionBox{
        width: 13.125em !important;
        height: 13.125em !important;
    }

    .leftButton{
        text-align: end;
        padding-right: 5px !important;
    }
    
    .rightButton{
        text-align: start;
        margin-left: 5px !important;
    }
}

@media (max-width: 600px) {
    .typeBox{
        width: 10.125em !important;
        height: 10.125em !important;
    }

    .promotionBox{
        width: 10.125em !important;
        height: 10.125em !important;
    }

}

@media (max-width: 500px) {
    .typeBox{
        width: 9.125em !important;
        height: 9.125em !important;
    }

    .promotionBox{
        width: 9.125em !important;
        height: 9.125em !important;
    }

}