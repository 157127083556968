.container {
    max-width: 33rem !important;
    text-align: center !important;
    width: 85% !important;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.headerTitle {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.headerCloseIcon {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.headerCloseIcon svg {
    height: 22px;
    width: 22px;
}

.searchbar {
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 18rem;
    width: 80%;
    margin: 1rem auto;
}

.searchbarIcon {
    position: absolute;
    left: 1rem;
    top: 25%;
}

.searchbarInput {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px 12px 12px 50px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.searchbarInput:focus {
    border-color: #8a8a8a !important;
}

.searchbarInput::placeholder {
    color: #808080 !important;
}


.infiniteScroll {
    overflow-x: hidden;
    max-width: 25rem;
    width: 90%;
    margin: 2rem auto 1rem;
}

.list {
    display: flex;
    justify-content: -ms-space-evenly;
    justify-content: space-evenly;
    gap: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 !important;
}

.listSpin {
    margin: auto !important;
}

.listError {
    color: #808080;
    font-weight: 700;
    font-size: 15px;
}

.listItem {
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    -ms-max-width: -ms-fit-content;
    -webkit-max-width: fit-content;
    -o-max-width: fit-content;
    -moz-max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 1rem;
    border: 1px solid #eee;
    transition: all .3s;
}

.listItemSelected {
    border-color: #ccc;
    background: #80808033;
}

.listItemLogo {
    height: 45px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}


.button {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 80% !important;
    margin: 1rem auto 2rem !important;
}

.button span {
    display: block !important;
    line-height: 0 !important;
}

.button:hover {
    background: #E5AF28 !important;
}

@media screen and (min-width: 500px) {
    .headerTitle {
        font-size: 27px;
    }

    .headerCloseIcon {
        right: 2rem !important;
    }

    .button {
        max-width: 140px !important;
    }
}