.table {
  display: none !important;
}

.table div div div div div table,
.accordion {
  background-color: #fff !important;
  border: none !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px !important;
  padding: 1rem 2rem 1.5rem !important;
}

.accordion {
  padding: 1rem 0.5rem !important;
  margin-bottom: 5rem !important;
}

.accordion div {
  border-color: #eee !important;
}

.accordionPanel > div {
  font-size: 16px !important;
}

.accordionPanel > div span {
  font-size: 16px !important;
  color: #e4056f !important;
}

.accordionTitle,
.accordionContentItemTitle {
  font-weight: 500 !important;
  font-size: 17px !important;
  color: #e4056f !important;
  margin-left: 1rem !important;
}

.accordionTitle {
  text-align: center;
  color: black !important;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.accordionEmptyTitle {
  text-align: center;
  color: #8f8f8f !important;
}

.accordionContent {
  background: #e6e6e6 !important;
  border-radius: 8px !important;
  padding: 15px 10px !important;
  max-width: 400px;
  width: 108% !important;
  margin: auto;
  margin-left: -0.8rem !important;
}

.accordionContentItem {
  /* background-color: rgba(51, 51, 51, 0.226) !important; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.accordionContentItemTitle {
  font-size: 14px !important;
  margin-left: 0 !important;
}

.table div div div div div table thead tr th {
  border: none !important;
  text-align: center !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #e4056f !important;
  padding: 1rem 0 1.5rem !important;
}

.table div div div div div table thead tr th::before {
  background: transparent !important;
}

.table div div div div div table tbody tr td {
  padding: 10px 5px !important;
  border-radius: 0 !important;
  text-align: center !important;
}

.table div div ul li[class*="ant-pagination-item-active"] {
  border-color: #e4056f !important;
}
.table div div ul li[class*="ant-pagination-item-active"] a {
  color: #e4056f !important;
}

/*
.table div div div div div table tbody tr:hover td {
  background: transparent !important;
} */

.accordionNotificationId {
  display: block !important;
  color: #e4056f !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

.tableName,
.tableEmail {
  font-style: normal !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  color: #333 !important;
}

.tableSingleRowValue {
  color: #333 !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  text-align: left !important;
  margin-bottom: 0.5em !important;
}

.tableStatus {
  color: #fff !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background: #ddd;
  padding: 5px;
  border-radius: 8px;
}

.tableCenteredText {
  text-align: center !important;
}

.tableEmail {
  font-weight: 700 !important;
}

.tableActions {
  display: flex !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  max-width: 150px !important;
  margin: auto !important;
}

.tableActions button {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  width: 60px !important;
}

.tableActions button img {
  max-width: 40px;
}

.tableSwitch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (min-width: 450px) {
  .accordionContent {
    padding: 20px !important;
    margin: auto !important;
    width: 100% !important;
  }

  .accordionContentItem {
    justify-content: flex-start !important;
  }

  .accordionTitle {
    font-size: 18px !important;
  }

  .accordionContentItemTitle {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1024px) {
  .table {
    display: block !important;
  }

  .accordion {
    display: none !important;
  }
}

@media screen and (min-width: 1240px) {
  .table div div div div div table tbody tr td {
    padding: 10px !important;
  }

  .accordion {
    display: none !important;
  }
}
