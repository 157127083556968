.deskContainer {
    border: 1px solid green;
}

.emptyAccordion {
    width: 100% !important;
}

.noPromotions {
    width: 80% !important;
    height: fit-content !important;
    font-size: 1rem !important;
    margin: 0 auto !important;
}

.table {
    display: none !important;
}

.table div div div div div table,
.accordion,.emptyAccordion {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.accordion {
    padding: 1.5rem 1rem !important;
    margin-bottom: 5rem !important;
}

.accordion div {
    border-color: #eee !important;
}

.accordionPanel>div {
    font-size: 16px !important;
}

.accordionPanel>div>span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.accordionHeader {
    display: flex;
    align-items: center;
    margin: auto !important;
    width: 94% !important;
}

.accordionHeader h2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #E4056F !important;
    margin: 0 !important;
    width: 50%;
    max-width: 150px !important;
}

.accordionTitle,
.accordionContentItemTitle {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.accordionContent {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 15px !important;
    max-width: 400px;
    margin: auto;
    margin-left: -1rem;
    width: 112%;
}

.accordionContentItem {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 10px !important;
    margin-bottom: 15px;
}

.accordionContentItem p {
    margin-right: auto !important;
}

.accordionContentItemTitle {
    font-size: 14px !important;
    margin: 0 !important;
}

.panelHeader {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.panelHeaderTitle {
    font-weight: 400 !important;
    margin: 5px 0 !important;
}

.switchContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 130px;
}

.table div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.table div div div div div table thead tr th::before {
    background: transparent !important;
}

.table div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

.tableName,
.tableEmail {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.tableNameVisibleTrue,
.tableNameVisibleFalse {
    font-weight: 700 !important;
}

.tableNameVisibleTrue {
    color: #49BA35 !important;
}

.tableNameVisibleFalse {
    color: #FF3247 !important;
}

.tableNameStatusActive,
.tableNameStatusInactive,
.tableNameStatusVence {
    padding: 4px 8px;
    border-radius: 10px;
}

.tableNameStatusActive {
    background: #49BA3573 !important;
}

.tableNameStatusInactive {
    background: #80808073 !important;
}

.tableNameStatusVence {
    background: #FF324773 !important;
}

.tableEmail {
    font-weight: 700 !important;
}

.tableActions {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
    gap: 10px;
    align-items: center;
}


.tableActions button,
.tableDeskActions button {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
}

.tableActions button img,
.tableDeskActions button img {
    width: 100% !important;
    max-width: 50px !important;
    max-height: 50px;
}

.tableSwitch {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tableDeskActions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.panelHeaderTitle{
    font-size: 16px !important;
}

@media screen and (min-width: 450px) {
    .accordionContent {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .accordionContentItem {
        justify-content: space-between !important;
    }

    .accordionTitle {
        font-size: 18px !important;
    }

    .accordionContentItemTitle {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .table {
        display: block !important;
    }

    .accordion,.emptyAccordion {
        display: none !important;
    }
}