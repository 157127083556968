.rowTypePromotionContainer{
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    min-height: 30vh !important;
}

.promotionDescriptionContainer{
    border: 1px solid #E4056F;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
}

.promotionDescription{
    align-self: center;
    padding-top: 5em;
}

.promotionBoxContainer{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 70px !important;
}

.promotionListContainer{
    padding-left: 50px !important;
    padding-right: 70px !important;
    padding-top: 40px !important;
    padding-bottom: 70px !important;
}

.labelNoPromotionWithType{
    color: #ccc;
    text-align: center;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 28px;
    margin: 8rem auto;
}

.typeBoxTypeScreen{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.promotionBoxTypeScreen{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

@media (max-width: 992px) {
    .rowTypePromotionContainer{
        margin-bottom: -15px !important;
    }
    
    .colTypePromotion{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .promotionBoxContainer{
        margin-bottom: 20px !important;
    }
    .labelNoPromotionWithType{
        font-size: 20px;
    }
}