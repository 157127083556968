.DeleteUserModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
}
.deleteModal-DeleteUserModal {
  padding: 50px !important;
  text-align: center !important;
  margin: 0px auto !important;
}
.DeleteUserModal-content {
  padding: 10px 30px !important;
}
