@media screen and (max-width: 767px) {
    .container {
        display: none;
    }
}

.container div:last-child {
    height: auto !important;
}

.footerCollapseButton {
    background: #fff !important;
    border-radius: 100% !important;
    border: none !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px !important;
    margin: 1rem auto 3rem !important;
    outline: none !important;
    width: 35px !important;
}

.footerCollapseButton img {
    transition: all .5s !important;
    width: 60% !important;
}

.menuItem {
    margin-top: 1rem !important;
    width: 100% !important;
}

.menuItem:hover {
    text-decoration: underline !important;
}

.menuItemText {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500;
    margin-top: 1rem !important;
}

.menuItemLogout {
    background-color: rgb(122, 40, 40) !important;
    margin-top: auto !important;
}