/* GLOBAL STYLES */
#form-promotions {
  width: 100% !important;
  margin: 0px auto !important;
}
.form-border {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  padding: 10px 10px;
  margin-bottom: 20px;
}
.form-description {
  color: #e4056f !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 600;
  padding: 0px 0px 10px 0px !important;
}
/* CLOSE GLOBAL STYLES */

/* INPUTS  */
.promotion-form-input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 50px !important;
  width: 85% !important;
}

.formSelectSegment{    
  text-align: center;
  border-radius: 5px !important;
  height: auto !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: solid rgba(0, 0, 0, 0.15) 1px;
  width: 200px !important;
  }

@media (max-width: 1000px) {
  .promotion-form-input {
    width: 100% !important;
  }
}
.promotion-form-input:hover {
  border-color: #ee84b7 !important;
}
/* ANT style LABEL*/
.ant-form-item-label > label {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
/* CLOSE ANT style */
.promotion-form-inputTextarea {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
  border-radius: 20px !important;
  width: 85% !important;
  padding: 12px 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-inputTextarea {
    width: 100% !important;
  }
}
.promotion-form-inputTextarea:hover {
  border-color: #ee84b7 !important;
}
.promotion-form-input-localLogo {
  width: 40% !important;
  padding: 35px 0px !important;
  border-radius: 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-input-localLogo {
    width: 70% !important;
  }
}
/* CLOSE INPUTS */

/* BUTTONS OF STATE */
.buttonForm-createPromotions {
  border-radius: 30px !important;
  width: 100% !important;
  height: 45px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: white !important;
  min-width: 60px;
  font-size: 12px !important;
  padding: 0px !important;
}
.activate-button {
  background-color: #83c446 !important;
}
.inactive-button {
  background-color: #808080 !important;
}
.expire-button {
  background-color: #e4056f !important;
}
.preview-button {
  background-color: #fcc02c !important;
}
/*CLOSE BUTTONS OF STATE */

/* DATE SECTION ----> */
@media (min-width: 1000px) {
  .week-row {
    justify-content: space-between !important;
  }
}
.padding-col-CreatePromotions {
  padding: 0px 5px 10px 5px;
}
.week-button {
  background-color: #e4056f !important;
  border-radius: 30px !important;
  width: 100% !important;
  height: 30px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: white !important;
  min-width: 60px;
  font-size: 12px !important;
  padding: 0px !important;
}
.text-CreatePromotions {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
}
.swicth-iten-CreatePromotions {
  margin-left: 20px !important;
}
.displayFlex-switchs{
  display: flex;
}
.marginLeft-Switch{
  margin-left: 20px;
}
@media (max-width: 800px) {
  .displayFlex-switchs{
    display: inline-block;
  }
  .marginLeft-Switch{
    margin-left: 0px;
  }
}
/* ANT style SWITCH */
.ant-switch-checked {
  background-color: black !important;
}
/* CLOSE ANT style */
.datepicker-CreatePromotions {
  width: 80% !important;
  border-radius: 30px !important;
  padding: 10px !important;
}
.datepicker-CreatePromotions:hover {
  border-color: #ee84b7 !important;
}
.ant-picker-focused {
  border-color: #ee84b7 !important;
  box-shadow: 0px 0px 2px #e4056f !important;
}
/* CLOSE DATE SECTION ----->*/

/* LOCATIONS SECTION */
.promotion-form-inputLocation {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  border-radius: 50px !important;
  width: 85% !important;
  padding: 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-inputLocation {
    width: 100% !important;
  }
}
.promotion-form-inputLocation:hover {
  border-color: #ee84b7 !important;
}
/* CLOSE LOCATIONS SECTION */

/* SEGMENTS SECTION */
.col-img-CreatePromotions {
  padding: 8px;
  border-radius: 10px;
  min-width: 70px;
  max-height: 50px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
}
.row-Segments {
  justify-content: space-between !important;
  width: 100% !important;
  text-align: center !important;
  margin-bottom: -50px;
}
.background-yellow {
  background-color: #fcc02c;
}
.background-pink {
  background-color: #e4056f;
}
.segments-text-CreatePromotions {
  max-width: 100%;
  color: #000000 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
/* CLOSE SEGMENTS SECTION */

/* CONDITIONS SECTIONS */
.conditionsText-form {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  background-color: white;
  padding: 30px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  text-align: justify;
  margin-top: 10px;
}
/* CLOSE CONDITIONS SECTIONS */

/* APPEAR AND DISAPPEAR BUTTONS */
.disappearButtonsh-footer{
  width: 100%!important;
justify-content: space-between !important;
}
@media (max-width: 1050px) {
  .disappearButtonsh-hedear
  {
display: none !important;
  }
}
@media (min-width: 1050px) {
  .disappearButtonsh-footer
  {
display: none !important;
  }
}
/* CLOSE APPEAR AND DISAPPEAR BUTTONS */