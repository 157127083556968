/* ------------------------------------------------------ */
/*    Los estilos de este archivo NO se están usando      */
/*    en ModalPromotion pero al ser estilos globales      */
/*    están teniendo side effects en otros sitios de      */
/*    la app, por lo cual igual se importaron hacia       */
/*    el componente ModalPromotion para que estén         */
/*    disponibles de manera global.                       */
/* ------------------------------------------------------ */

.ant-modal-content{
   border-radius: 25px !important;
}

.closeModalButton{
    font-size: 25px !important;
    font-weight: bold !important;
    padding-top: 15px !important;
    background-color: #E4056F !important;
    border-top-right-radius: 10px !important;
    opacity: 0.7 !important;
    margin-right: -14px !important;
    display: none !important;
}

.modal-header{
    background-color: #E4056F;
    border-radius: 20px 20px 0px 0px;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
.modal-header button{
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
}


.closeModalButton:hover{
    opacity: 1 !important;
}

.background-modal{
    background-image: url(https://thumbs.dreamstime.com/b/verduras-anchas-de-la-foto-en-fondo-madera-133445680.jpg);
    background-repeat: no-repeat;
    background-size: cover !important;
    /* border-radius: 25px !important; */
    object-fit: contain;
    text-align: center;
    height: auto !important;
    width: 100% !important;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.ant-modal-body{
    padding: 0px!important;
}

.hart-img{
    position: absolute;
    width: 25px;
    top: 18px !important;
    right: 8% !important;
}

.modal-back-title{
    max-width: 60% !important;
    background-color: #E4056F;
    margin-bottom: 3rem;
    border-radius: 50px;
    margin: 10vh auto 8vh auto;
}

.modal-margin-title{
    padding: 10px;
    font-weight: 700;
    color: white;
}

.modal-display-subtitle{
    display: flex;
    justify-content: space-around;
    padding-top: 40px !important; 
}

.modal-back-info{ 
    width: 50%;
    padding: 0px 20px;
}

.modal-back-date{
    background-color: #E4056F;
    border-radius: 20px;
    padding-left: 30px;
    padding-top: 15px;
    padding-right: 30px;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight:700;
    max-width: 40%;
    margin-bottom: 20px;
    max-height: 15rem;
}


.modal-display-title{
    display: flex;
    justify-content: space-evenly;

}

.modal-logo{
    width: 120px !important;
    height: 120px !important;
    background-position: center;
    background-size: contain;
    border-radius: 20px;
}

.modal-h2-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #E4056F;
}

.modal-h3-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
}

.modal-p-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-list-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-h3-date{
    font-weight:700;
    font-size: 20px;
    color: #FFFFFF;
}

.model-p-date{
    font-size: 15px;
}
.model-p-date a{
    color:  #FCC02C;
}
.model-p-date a:hover{
    color:  #FCC02C;
}


.modal-display-commit{
    display: none;   
}
  
  .modal-h3-commit{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
    max-width: 300px; text-align: center;
  }
  
 .modal-h3-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
 }
 .modal-p-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: #0000009f;
 }

 .modal-footer{
     padding: 30px;
     background-color:#d1d1d1;
     border-radius: 0px 0px 25px 25px;
 }

@media (max-width: 1024px) {
    .hart-img{
        right: 15% !important
    }

    .modal-margin-title{
        font-size: 25px !important;
    }

    .modal-back-date{
       max-width: 70%;
        height: 15rem ;
    }
    .modal-h2-info{
        text-align: center;
        padding-top: 20px;
    }
}


@media (max-width: 800px) {
    .hart-img{
        right: 20% !important
    }

    .modal-back-title{
        max-width: 70% !important;
        border-radius: 40px;
        margin-top: 60px !important;
    }

    .modal-margin-title{
        font-size: 20px !important;
    }

    .modal-display-subtitle{
        display:inline;
    }

    .modal-back-info{ 
        max-width: 100% !important;
        width: 100% !important;
    
    }

    .modal-back-date{
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media (max-width: 450px) {
    .model-p-date{
        font-size: 13px;
    }
    .hart-img{
        right: 25% !important;
    }

    .modal-back-title{
        max-width: 90% !important;
        border-radius: 20px !important;
    }

    .modal-margin-title{
        font-size: 15px !important;
    }

    .modal-back-date{
        height: 19rem !important;   
    }

    .modal-h2-info{
        font-size: 25px;
    }
    
    .modal-h3-info{
        font-size: 17px;
    }
    
    .modal-p-info{
        font-size: 13px;
    }
    .modal-list-info{
        font-size: 13px;
    }
}
 

.ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.7) !important;
}