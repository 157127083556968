.mainRow {
    margin-bottom: 10rem !important;
}

.mainCol {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 3rem auto !important;
    gap: 2rem !important;
    width: 90% !important;
    padding: 0 1rem !important;
}

@media screen and (min-width: 768px) {
    .mainCol {
        justify-content: -ms-space-evenly !important;
        justify-content: space-evenly !important;
        gap: 3rem !important;
        width: 100% !important;
        padding: 0 !important;
    }
}