.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 2rem;
}

.title {
    font-size: 33px;
    font-weight: 700;
    margin: 0 !important;
    text-align: center;
    width: 100%;
}

.button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.button img {
    width: 100%;
}

.buttonGray {
    display: none;
}

.button span {
    display: none;
}

.listToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: .5rem;
    width: 100%;
}

.listToggleButton {
    background: transparent !important;
    color: #808080;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 !important;
    border: none !important;
    outline: none !important;
    transition: all .5s;
}

.listToggleButtonActive svg,
.listToggleButtonActive span {
    transition: all .5s;
    color: #E4056F;
    fill: #E4056F;
}


@media screen and (min-width: 768px) {
    .container{
        margin-top: -.5rem;
    }
    .listToggle {
        justify-content: flex-start;
    }

    .title {
        text-align: left;
        width: 40%;
    }

    .button {
        background: #E4056F;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .button:hover {
        background: #B20457;
    }

    .button img {
        display: none;
    }

    .button span {
        color: #fff;
        display: block;
        font-weight: 700;
    }

    .buttonGray {
        background: #B3B3B3;
        display: inline-block;
        padding: 8px 15px;
        margin-left: auto;
        margin-right: 20px;
        width: 150px;
    }

    .buttonGray:hover {
        background: #505050;
    }
}