.containerMain-PLS {
    background-color: #fcc02c;
    width: 100% !important;
    /* position: fixed;   */
    /* <PENDIENTE=FIJE EL NAV BAR> */
  }
  
  .logoIcon-Main-PLS {
    height: auto;
    max-width: 70%;
    margin-left: 5%;
    overflow: hidden;
  }
  
  .leftCol-Main-PLS {
    height: 75px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow-y: hidden !important;
    /* para ocultar el sobre click */
  }
  
  .rightCol-Main-PLS {
    background-color: #e4056f;
    height: 75px !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  
.logoutButtonHeaderCompany{
  background-color: transparent !important;
  border: none !important;
}

.logoutButtonHeaderCompany:hover{
  color: #E4056F !important
}

.headerColSelectCompanyWeb{
  visibility: visible;
}

.headerColSelectCompanyMobile{
  visibility: hidden;
  display: none;
}
  @media (max-width: 1000px) {
    .logoIcon-Main-PLS {
      max-width: 70%;
      overflow: hidden;
    }
  }
  
  @media (max-width: 800px) {
    .logoIcon-Main-PLS {
      max-width: 80%;
      overflow: hidden;
    }
  }
  
  @media (max-width: 500px) {
    .logoIcon-Main-PLS {
      max-width: 90%;
      overflow: hidden;
    }
  }
  
  .p-main-PLS {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    text-overflow: ellipsis;
    color: white;
    padding-top: 15px;
    font-style: italic;
  }
  .bgmain-PLS {
    position: absolute;
    overflow-x: hidden;
  }
  /* TERMINA NAV */
  
.siderLayoutCompany{
    background-color: #E4056F !important;
}

.containerCompanyLayout{
    min-height: 90vh !important;
    padding: 3%;
}

.ant-layout-sider-trigger{
    background-color: #E4056F !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #970048 !important;
    height: 50px !important;
    min-height: 50px !important;
}

.siderMenuCompanyDivider{
    background-color: white !important;
    min-width: 80% !important;
    width: 80% !important;
    margin-left: 10% !important;
    height: 3px !important;
    border-radius: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.headerCompanyWeb{
    color: white;
    font-style: italic;
    font-size: 24px;
    visibility: visible;
    font-family: "Montserrat", sans-serif;
    text-overflow: ellipsis;
    padding-top: 60px;
}

.superAdminSearchCompany{
  text-align: center;
  height: 30px;
  width: 45%;
  border-radius: 10px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8 !important;
  line-height: 1.5715;
  appearance:none;
  margin-left: 30%;
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
}

.headerCompanyMobile{
    visibility: hidden !important;
}

.companyHomeButtonDrawer{
    width: 50px !important;
    height: 50px !important;
    border-radius: 2vw !important;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 50% !important;
    background-size: 60% !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.companyHomeButtonDrawerLabel{
    font-weight: bold;
    padding-left: 10px !important;
    padding-top: 5px !important;
}

@media(max-width: 576px) {
    .siderLayoutCompany{
        display: none !important;
    }

    .headerCompanyWeb{
        visibility: hidden;
        display: none;
    }

    .headerCompanyMobile{
        visibility: visible !important;
        text-align: end;
        margin-right: 10%;
    }

    .headerColSelectCompanyMobile{
      visibility: visible;
      display: inherit;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .superAdminSearchCompany{
      width: 80%;
      margin-left: 10%;
    }
}
