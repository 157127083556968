.tagsCol {
    margin: auto !important;
    display: flex !important;
    gap: 1.5rem !important;
    overflow-x: auto !important;
    width: 100% !important;
    padding-bottom: 1rem !important;
    position: relative !important;
}

.mobileTitleCol {
    margin: auto !important;
    margin-top: 2rem !important;
}

.mobileTitle,
.tagsColDeskTitle {
    font-size: 15px !important;
    font-weight: 400;
    color: #E4056F;
    min-width: -moz-fit-content !important;
    min-width: -webkit-fill-available;
    min-width: fit-content !important
}

.mobileTitle {
    text-align: left;
    width: 100% !important;
}

.tagsColDeskTitle {
    display: none !important;
}

.tagsCol::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.tagsCol::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
.tagsCol::-webkit-scrollbar-thumb {
    background: transparent;
}

/* set button(top and bottom of the scrollbar) */
.tagsCol::-webkit-scrollbar-button {
    display: none;
}



.tagButton {
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #E4056F;
    outline: none;
    padding: 10px 16px;
    min-width: -moz-fit-content !important;
    min-width: -webkit-fill-available;
    min-width: fit-content !important;
}

.tagButton svg {
    font-size: 18px !important;
    margin-right: 3px !important;
}

@media screen and (min-width: 768px) {
    .tagsColDeskTitle {
        margin: auto 0 auto auto !important;
        display: block !important;
    }

    .mobileTitle {
        display: none !important;
    }

    .mobileTitleCol {
        display: none !important;
    }

    .tagsCol {
        padding-top: 0 !important;
        margin-top: 2rem !important;
    }

    /* scrollbar itself */
    .tagsCol::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 16px;
        border: 4px solid #fff;
    }
}