.collapse {
  margin: 1rem 6% 1.5rem !important;
  width: 100%;
}

.collapse div div {
  padding: 0 !important;
}

.collapse div div:last-of-type {
  padding: 1rem 0 !important;
}

.collapseTitle {
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  margin: 0 !important;
}

.collapseDescription {
  color: #808080;
  font-weight: normal;
  font-size: 13px;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media screen and (min-width: 768px) {
  .collapse {
    margin: 1rem 12% 1.5rem !important;
  }
}
