@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
}

*{
  font-family: 'Montserrat';
}

.ant-form-item-label > label {
  font-weight: normal !important;
}