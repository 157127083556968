.container {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.container h2 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
