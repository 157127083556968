.formContainer {
    background: #fff;
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 16px;
    margin-bottom: 3rem;
    padding: 20px 16px;
}

.formContainer:last-of-type{
    margin-bottom: 2rem;
}

.formTitle {
    color: #E4056F;
    font-size: 16px;
    font-weight: 600;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    text-align: center;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .formContainer{
        padding: 30px;
    }
    .formTitle {
        text-align: left;
    }
}