.formContainer {
    display: flex !important;
    justify-content: space-between !important;
    gap: 15px;
    flex-wrap: wrap !important;
    padding: 1rem .5rem 2rem;
}

.formItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.formItem>div:first-child {
    flex: unset !important;
}

.formItem>div:last-child {
    display: unset !important;
    flex: unset !important;
    width: 100% !important;
}

.formItemDate>div:last-child {
    display: unset !important;
    flex: unset !important;
    width: 100% !important;
    /* border: 1px solid #E6E6E6 !important; */
    border-radius: 16px !important;
}

.formItemDate>div:last-child div div div {
    border-radius: 16px !important;
    height: 44px !important;
    width: 100% !important;
}

.formItemDate>div:last-child div div div div span {
    color: #E4056F !important;
}

.daysContainer {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 15px !important;
    width: 100% !important;
    overflow: auto !important;
    padding: 10px !important;
}

.daysContainer::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.daysContainer::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
.daysContainer::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 16px;
    border: 4px solid #fff;
    background: transparent !important;
}

/* set button(top and bottom of the scrollbar) */
.daysContainer::-webkit-scrollbar-button {
    display: none;
}


.dayButton {
    background: #fff;
    border: none !important;
    border-radius: 32px !important;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    outline: none !important;
    padding: 8px 16px !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    transition: all .5s;
}

.dayButton:hover {
    background: rgba(238, 238, 238, 0.3);
}
.dayButton:active {
    background: rgba(238, 238, 238, 0.5);
}

.dayButtonText {
    font-weight: 400 !important;
}

.dayButtonCheck {
    color: #E4056F !important;
    font-size: 16px;
}

.dayButtonUnchecked {
    width: 16px;
    height: 16px;
    border: 2px solid #E4056F;
    border-radius: 100%;
}

.daysSwitchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.daysSwitchText {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    margin-left: -6rem;
}



@media screen and (min-width: 500px) {
    .daysSwitchContainer {
        width: 275px !important;
        margin: auto;
    }
}

@media screen and (min-width: 768px) {
    .daysContainer {
        padding-bottom: 1rem;
    }

    /* scrollbar itself */
    .daysContainer::-webkit-scrollbar-thumb {
        background: #ccc !important;
    }
}

@media screen and (min-width: 800px) {
    .formItemDate {
        width: 47% !important;
    }

    .daysContainer {
        justify-content: space-between !important;
    }

    .daysSwitchContainer {
        cursor: pointer;
        margin: 0 !important;

    }

}

@media screen and (min-width: 1100px) {
    .formContainer {
        padding-bottom: 1rem !important;
    }

    .daysSwitchContainer {
        margin-right: auto !important;
        width: 260px !important;
    }
    .daysSwitchText{
        margin-left: -10rem !important;
    }

    .formItemDate {
        width: 30% !important;
    }

    .formItemDate:first-of-type {
        margin-left: auto !important;
    }
}