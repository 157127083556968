.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.headerTitle {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin: 0 !important;
}

.headerTitle span {
    display: none;
}

.headerIcon {
    margin-right: 7px;
    height: 60px;
    width: 60px;
}



@media screen and (min-width:768px) {
    .headerTitle {
        font-size: 39px;
        max-width: 380px;
        line-height: 45px;
        margin-left: .5rem !important;
    }

    .headerTitle span {
        display: inline-block;
    }

    .headerIcon {
        transform: translateY(5px);
    }
}