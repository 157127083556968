.ListSegmentsModal-ul {
  list-style: none;
  margin: 0px auto;
}
.ListSegmentsModal-ul li {
  background-color: #e4056f;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
}

.ListSegmentsModal-ul h2 {
  color: white;
}
.ListSegmentsModal-content {
  padding: 15px 30px 20px !important;
}
