.container {
    background: #FCC02C;
    background-image: url("../../Assets/trans-pattern.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 1rem 3rem;

}
