.warning-modal {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.warning-modal__description {
    font-size: 20px;
    width: 95%;
    text-align: center;
    margin: 1rem auto;
    font-weight: bold;
}

.warning-modal__buttons-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1.5rem !important;
    width: 100% !important;
}

.warning-modal__close-button {
    display: none !important;
}

.warning-modal__button {
    background-color: transparent !important;
    border-radius: 25px !important;
    border: none !important;
    color: #000 !important;
    cursor: pointer !important;
    font-size: 18px !important;
    font-weight: bold !important;
    padding: .3rem 1.8rem !important;
    text-decoration: underline !important;
    transition: all .5s !important;
}

.warning-modal__button:hover {
    background: #E4056F !important;
    text-decoration: none !important;
    color: #fff !important;
}

.warning-modal__logo{
    width: 70%;
    margin: 1rem auto;
}

.warning-modal__title{
    font-weight: bold;
    margin: .5rem auto;
}