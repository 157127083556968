.container {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.title {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.stats {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1.5rem;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .stats {
    padding: 1rem;
  }
}
