body{
    overflow: unset !important;
    touch-action: unset !important ;
}
.headerCompanyWeb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding: 0 !important;
}

.headerCompanyWebCol {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 50px !important;
}

.userMobileName {
    margin: 20px 0 10px;
    color: #E4056F;
    font-size: 30px;
    font-weight: 700;
    margin: 30px auto 0;
    text-align: center;
    width: 90%;
}

.userLabel {
    margin: 0;
}

.logoIcon {
    max-width: 300px !important;
    width: 90% !important;
    height: auto !important;
    margin-left: 0 !important;
}

.logoIcon img {
    width: 100% !important;
    max-width: 240px !important;
}

.userIcon {
    width: 35px;
    height: 35px;
    padding: 8px;
    margin: auto 1rem auto .5rem;
}

.headerCompanyMobile {
    visibility: visible !important;
}

.headerCompanyMobile span {
    display: block !important;
    margin: auto !important;
}

.containerUserLayout {
    min-height: calc(100vh - 75px) !important;
}

.userLayoutCol {
    padding-bottom: 4rem;
}

@media screen and (max-width: 767px) {
    .headerCompanyWeb {
        display: none !important;
    }

    .containerUserLayout {
        align-content: flex-start !important;
    }
}


@media screen and (min-width: 768px) {
    .userMobileName {
        display: none;
    }

    .headerSearchMobile {
        display: none !important;
    }

    .logoIcon img {
        width: 90% !important;
    }

    .userLayoutCol {
        padding: 3rem 1rem !important;
    }

    .userNavigationCol {
        padding: 8rem 0 3rem;
    }
}

@media screen and (min-width: 1024px) {
    .userLayoutCol {
        padding: 3rem 2rem !important;
    }
}

@media screen and (min-width: 1200px) {
    .menuIcon {
        display: none !important;
    }
}