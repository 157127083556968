.userScreenContainer {
    background-color: blueviolet;
    min-height: 100vh;
    width: 100%;
}

.userScreenRow {
    max-width: 800px;
}

.userScreenContainer * {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
}

.userScreenCol {
    margin-top: 1.5rem;
    max-width: 850px !important;
}


.userScreenHeaderTitle {
    color: #E4056F;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.userScreenHeaderTitle span:last-child {
    margin-left: 8px;
}

.userScreenHeaderCol {
    max-width: 400px !important;
}

.actionButtonsCol {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem;
    position: fixed !important;
    bottom: 2rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.actionButton {
    border: none !important;
    outline: none !important;
    background: transparent !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    height: 50px !important;
    width: 45px !important;
}

.actionButtonHidden{
    display: none !important;
}

.actionButtonText {
    display: none !important;
}

.actionButtonIcon {
    width: 50px !important;
    height: 50px !important;
}

.actionButtonSave {
    order: -1 !important;
}

.actionButtonSave .actionButtonIcon {
    transition: all .3s !important;
}


.companies {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 5px;
    overflow-x: auto;
    width: auto;
}

.companies::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

.companies::-webkit-scrollbar-thumb {
    background-color: rgba(204, 204, 204, 0.3);
    border-radius: 16px;
    border: 4px solid #fff;
}

.companies::-webkit-scrollbar-track {
    background-color: #fff;
}

.companiesEmpty {
    color: #ccc;
    font-weight: 700;
    font-size: 15px;
    margin: 1rem 0;
}

.company {
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: -moz-fit-content;
    min-width: -ms-fit-content;
    min-width: -webkit-fill-available;
    min-width: fit-content;
    -ms-max-width: -ms-fit-content;
    -webkit-max-width: fit-content;
    -o-max-width: fit-content;
    -moz-max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 1rem 1.5rem;
    position: relative;
    transition: all .3s;
}

.companyDeleteIcon {
    position: absolute;
    right: 2px;
    top: 0;
    width: 17px;
    height: 17px;
}

.companySelected {
    border-color: #ccc;
    background: #80808033;
}

.companyLogo {
    height: 50px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (min-width: 768px) {
    .actionButtonsCol {
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        gap: 0 !important;
        margin-left: auto !important;
        max-width: 220px !important;
        position: static !important;
        bottom: unset !important;
        right: unset !important;
    }

    .actionButton {
        border: 1px solid #E4056F !important;
        border-radius: 8px !important;
        height: 40px !important;
        width: 100px !important;
    }

    .actionButtonAdd {
        background: #FCC02C !important;
        border: 1px solid #FCC02C !important;
        margin-left: .5rem !important;
    }

    .actionButtonAdd:hover {
        background: #E5AF28 !important;
    }

    .actionButtonDelete {
        background: #E4056F !important;
    }

    .actionButtonDelete:hover {
        background: #B20457 !important;
    }

    .actionButtonText {
        display: block !important;
        font-size: 15px !important;
        font-weight: 700 !important;
    }

    .actionButtonIcon {
        display: none !important;
    }

    .actionButtonEdit,
    .actionButtonEditPassword {
        color: #E4056F !important;
        border-color: #E4056F !important;
    }

    .actionButtonEditPassword {
        margin-top: auto;
        border-radius: 16px !important;
        height: 47px !important;
        width: 200px !important;
    }

    .actionButtonSave {
        background: rgba(255, 25, 136, 0.678) !important;
        color: #fff !important;
        cursor: not-allowed !important;
        margin-left: .5rem !important;
        order: 1 !important;
    }

    .actionButtonSaveHasChanged {
        background: #E4056F !important;
        cursor: pointer !important;
    }

    .actionButtonSaveHasChanged:hover {
        background: #B20457 !important;
    }

    .actionButtonCancel {
        border-color: #808080 !important;
        color: #808080 !important;
    }

    .actionButtonCancel:hover {
        border-color: #333 !important;
        color: #333 !important;
    }
}

@media screen and (min-width: 875px) {
    .actionButtonsCol {
        max-width: 250px !important;
    }

    .actionButton {
        width: 120px !important;
    }

    .actionButtonSave {
        margin-left: 1.5rem !important;
    }

    .actionButtonAdd {
        margin-left: 1.5rem !important;
    }
}