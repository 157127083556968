.collapse {
    max-width: 750px;
    margin: 1rem auto 0 !important;
    width: 90%;
}

.collapse div div {
    padding: 0 !important;
}

.collapse div div:last-of-type {
    padding: 1rem 0 !important;
}

.collapseTitle {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    margin: 0 !important;
}

.collapseDescription {
    color: #808080;
    font-weight: normal;
    font-size: 13px;
}