.container {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem 1rem 2rem !important;
}

.formItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.formItemLabel {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.formItemInput {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.formItem * {
    background-color: #fff !important;
}

.formItemInput:focus {
    border-color: #8a8a8a !important;
}

.formItemInput::placeholder {
    color: #ccc !important;
}

.formButton {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.formButton span {
    display: block !important;
    line-height: 0 !important;
}

.formButton:hover {
    background: #B20457 !important;
}

.footer {
    width: 100%;
}

.footerDescription {
    font-weight: 600;
    font-size: 13px;
    color: #808080;
}

.footerDescription a {
    color: #808080;
}

.footerDescription a:hover {
    color: #E4056F;
    text-decoration: underline;
}


.forgotPasswordText a {
    color: #808080;
}

.forgotPasswordText a:hover {
    color: #E4056F;
    text-decoration: underline;
}

.formTitle{
    color: #E4056F;
    font-weight: 600;
}

@media screen and (min-width: 770px) {

    .formButton {
        max-width: 180px !important;
    }

    .footerDescription {
        font-size: 15px;
    }
}