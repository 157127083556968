.container {
  margin-bottom: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.headerTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 0 !important;
  text-align: center;
  width: auto;
}

.headerButton {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.5s;
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  width: 60px;
  z-index: 100;
}

.headerPromotionsFiltersButton {
  background: #fcc02c;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 24px;
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.headerPromotionsFiltersButton:hover {
  background: #e5af28;
}

.headerButton img {
  width: 100%;
}

.buttonGray {
  display: none;
}

.searchInputsContainer {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
  align-items: flex-end !important;
  gap: 1rem !important;
  width: 100%;
}

.searchByInputContainer,
.searchValueInputContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  width: 100%;
}

.searchByInputContainer {
  min-width: fit-content !important;
  width: 40% !important;
}

.searchByInputContainer div {
  text-align: left !important;
  width: 100% !important;
}

.searchByInputContainer > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
}

.searchValueInputContainer input {
  height: 44px !important;
  border-radius: 10px !important;
}

.searchByInput > div {
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
  width: 100% !important;
}

.headerButton span {
  display: none;
}

.listToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.listToggleButton {
  background: transparent !important;
  color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  transition: all 0.5s;
}

.listToggleButtonActive svg,
.listToggleButtonActive span {
  transition: all 0.5s;
  color: #e4056f;
  fill: #e4056f;
}

.desktopPromotionsFilters {
  display: none;
}

@media screen and (min-width: 1024px) {
  .desktopPromotionsFilters {
    display: block;
  }
  .searchInputsContainer,
  .headerPromotionsFiltersButton {
    display: none !important;
  }
  .headerTitle {
    font-size: 34px;
  }
  .headerButton {
    background: #e4056f;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    outline: none;
    padding: .6rem 1rem;
    transition: all 0.5s;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    flex-direction: row;
    width: fit-content;
  }

  .headerButton:hover {
    background: #b20457;
  }

  .headerButtonPlusIcon {
    font-size: 1rem;
  }

  .headerButton img {
    width: 30px;
  }

  .headerButtonPlusImage {
    display: none !important;
  }

  .headerButton span {
    color: #fff;
    display: block;
    font-weight: 700;
  }
}

@media screen and (min-width: 768px) {
  .container {
    background-color: #fff !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    padding: 1.5rem 1rem 1rem !important;
    border-radius: 16px !important;
  }
  .header {
    margin-top: -0.5rem;
  }
  .listToggle {
    justify-content: flex-start;
  }

  .headerTitle {
    text-align: left;
    width: 40%;
  }

  .buttonGray {
    background: #b3b3b3;
    display: inline-block;
    padding: 8px 15px;
    margin-left: auto;
    margin-right: 20px;
    width: 150px;
  }

  .buttonGray:hover {
    background: #505050;
  }
}
