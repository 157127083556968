.container-ReportScreen {
  border-radius: 50px;
  background-color: white;
  margin-bottom: 50px;
  border: 2px solid #808080;
  padding: 50px;
}

.lock-container{
  text-align: center;
  width: 70%;
  margin-left: 15%;
}

.blurred-reports{
  width: 100%;
  visibility: visible;
}

.blurred-reports-mobile{
  visibility: hidden;
}

.container-ReportScreenModal {
  background-color: white;
  margin-bottom: 50px;
  border: none !important;
  padding: 50px;
  border-radius: 20px !important;
}

.container-ReportScreen-flex {
  display: flex;
  align-items: center;
}
.Icon-reportScreen {
  background-color: #fcc02c;
  border-radius: 10px;
  padding: 5px 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
  margin-right: 15px;
}
.text-ReportScreen {
  color: #808080;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}

.reportScreenCompanyLogo{
  width: 100px;
  margin-right: 40px;
}

.reportScreenTopDivider{
  margin-right: 40px;
  visibility: visible;
}

@media (max-width: 766px) {
  .blurred-reports{
    width: 100%;
    height: 0px;
    visibility: hidden;
  }
  
  .blurred-reports-mobile{
    width: 100%;
    visibility: visible;
  }
}

@media (max-width: 380px) {
  .reportScreenCompanyLogo{
    margin-left: 30%;
    margin-bottom: 20px;
  }

  .reportScreenTopDivider {
    visibility: hidden;
  }
}

@media (max-width: 800px) {
  .text-ReportScreen {
    font-size: 15px;
  }
}
.text-ReportScreen span {
  font-size: 36px;
  color: #e4056f;
}
@media (max-width: 800px) {
  .text-ReportScreen span {
    font-size: 25px;
  }
}

.container-ReportScreen h2 {
  padding-left: 60px;
  color: #e4056f;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 20px 0px;
}

@media (max-width: 500px) {
  .container-ReportScreen h2 {
    padding: 0px;
    text-align: center;
  }
}

.number-ReportScreen {
  color: #808080;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  /* padding: 0px 15px; */
}
.number-1-ReportScreen {
  color: #000000;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.row-ReportScreen {
  justify-content: space-between !important;
  width: 100% !important;
  text-align: center !important;
}

.row-1-ReportScreen {
  width: 100% !important;
  text-align: center !important;
  justify-content: space-evenly;
  padding: 10px 0px 30px;
  align-items: center;
}

.row-2-ReportScreen {
  width: 100% !important;
  text-align: center !important;
  justify-content: space-evenly;
  align-items: center;
}

.col-ReportScreen {
  padding: 0px 5px 10px 5px;
  max-width: 100%;
  color: #000000 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.col-img-ReportScreen {
  padding: 8px;
  border-radius: 10px;
  min-width: 70px;
  max-height: 50px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
}

.ExpiredButton {
  text-align: center;
  background-color: #e4056f;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.ActiveButton {
  text-align: center;
  background-color: #83c446;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.InactiveButton {
  text-align: center;
  background-color: #808080;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.Signs-img {
  margin-top: -30px;
}
