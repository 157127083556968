.searchPromotionTitle{
    text-align: center;
    font-weight: bold;
    padding-top: 40px;
    font-size: 26px;
}

.searchPromotionSuggestTitle{
    text-align: center;
    font-weight: bold;
    font-size: 26px;
}

.typeBoxSearchScreen{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.promotionBoxSearchScreen{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

.searchNotFoundIcon{
    font-size: 200px !important;
    margin-top: 50px !important;
    color: black !important;
    opacity: 0.3 !important;
}

.searchNotFoundLabel{
    font-size: 30px !important;
    padding-top: 20px !important;
    padding-bottom: 40px !important;
    font-weight: bold;

}

@media (max-width: 800px) {
    .searchPromotionTitle{
        font-size: 20px;
    }
    
    .searchPromotionSuggestTitle{
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .searchPromotionTitle{
        font-size: 16px;
    }
    
    .searchPromotionSuggestTitle{
        font-size: 16px;
    }
}