.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 2rem;
}

.title {
    font-size: 33px;
    font-weight: 700;
    margin: 0 !important;
    text-align: center;
    width: 100%;
}

.button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.button img {
    width: 100%;
}

.button span {
    display: none;
}

@media screen and (min-width: 768px) {
    .title {
        text-align: left;
    }

    .button {
        background: #E4056F;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .button:hover {
        background: #B20457;
    }

    .button img {
        display: none;
    }

    .button span {
        color: #fff;
        display: block;
        font-weight: 700;
    }
}