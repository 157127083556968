.container {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 200px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.content {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.closeIcon {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.header {
    margin-bottom: 1rem;
    text-align: left;
}

.headerTitle {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.headerSubtitle {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.footer {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.footerButton {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
    margin-top: 20px !important;
}

.footerButton:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.footerGhostButton {
    background: #fff !important;
    color: #E4056F !important;
    border: 1px solid #E4056F !important;
}

.footerGhostButton:hover {
    color: #E4056F !important;
    background: #e4057135 !important;
}


@media screen and (min-width: 500px) {
    .header {
        text-align: center;
    }

    .headerTitle {
        font-size: 25px;
    }

    .footerButton {
        max-width: 150px !important;
    }

    .footerDescription {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .closeIcon{
        right: 2.5rem;
    }
}