.container {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    padding-bottom: 3rem;
    margin-bottom: 4rem;
}

.title {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 1.5rem 2.5rem;
    width: 100%;
}

.subTitle {
    color: #E4056F;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
    /* width: 100%; */
}

.subTitle span {
    display: inline-block;
    margin-right: 5px;
    font-size: 33px;
    font-weight: 700;
}

.promoTypes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 1.5rem;
    overflow-x: auto !important;
    padding-bottom: 1rem !important;
    margin: auto;
    width: 80%;
}

.promoTypes::-webkit-scrollbar {
    background: transparent !important;
    width: 1px;
}

.promoTypes::-webkit-scrollbar-button {
    display: none;
}

.promoTypes::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: none !important;
    background: transparent !important;
}

.promoTypes::-webkit-scrollbar-track {
    background-color: transparent;
}


.promoTypesButton {
    background: #fff;
    border-radius: 32px;
    border: none;
    box-shadow: 0px 4px 10px 0px #0000001A;
    color: #E4056F;
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 500;
    min-width: -moz-fit-content;
    min-width: -webkit-fill-available;
    min-width: fit-content;
    outline: none;
    padding: 12px 24px;
    transition: all .3s;
}

.promoTypesButton:hover,
.promoTypesButtonActive {
    background: #E4056F;
    color: #fff;
}


.stats {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 0 2.5rem;
    flex-wrap: wrap;
}

.statsItem {
    background: #49BA35;
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    height: 105px;
    width: 115px;
}

.statsItem:nth-child(2) {
    background: #E4056F;
}

.statsItem:nth-child(3) {
    background: #B3B3B3;
}

.statsItem:nth-child(4) {
    background: #333333;
}

.statsItem:nth-child(5) {
    background: #B20457;
}

.statsItemTitle {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin: 0 !important;
    margin-top: -20px !important;
}

.statsItemAmount {
    font-size: 33px;
    font-weight: 700;
}


@media screen and (min-width:600px) {
    .promoTypes{
        width: 90%;
    }
}

@media screen and (min-width:768px) {
    .subTitle {
        text-align: left;
    }

    .stats{
        margin: 1.5rem auto 0 !important;
    }

    .promoTypes::-webkit-scrollbar-thumb {
        border: 4px solid #fff !important;
        background: #ccc !important;
    }

}

@media screen and (min-width:1024px) {
    .title {
        padding: 1.5rem 4rem;
    }

    .promoTypes {
        width: 90%;
        justify-content: space-between;
        padding: 0 .5rem;
    }

    .stats {
        padding: 0 5rem;
        margin-top: 1rem;
    }

    .subTitle {
        margin: 1rem auto;
        padding: 0 5rem;
    }
}

@media screen and (min-width:1200px) {
    .promoTypesButton {
        min-width: 110px;
    }
}

@media screen and (min-width:1700px) {
    .stats{
        justify-content: space-between;
    }
}