@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
}

*{
  font-family: 'Montserrat';
}

.ant-form-item-label > label {
  font-weight: normal !important;
}

.containerHeader {
    background-color: #FCC02C;
    /* position: fixed; */
    width: 100% !important;
}

.logoIcon {
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    margin-left: 3rem;
}

.leftCol {
    height: 75px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.rightCol {
    background-color: #E4056F;
    height: 75px !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex !important;
    justify-content: space-between !important;
    /* justify-content: flex-end !important; */
    align-items: center !important;
    padding: 0 50px;
}

.rightColContent {
    display: flex;
    justify-content: flex-end;
}

.userIcon {
    background-color: #FCC02C;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 22px;
}

.searchInputHeader {
    max-width: 380px !important;
    width: 50% !important;
    height: 50px !important;
    border-radius: 32px !important;
    margin: auto 0 !important;
    margin-left: -.5rem !important;
}

.searchIcon {
    cursor: pointer;
    font-size: 20px !important;
    padding-right: 8px !important;
    padding-left: 4px !important;
}

.headerSearchMobile {
    display: none;
}

.headerSearchWeb {
    display: flex;
    justify-content: space-between;
    /* margin-right: -90px !important; */
    width: 100% !important;
}

.menuResponsiveIconMobile {
    cursor: pointer;
    font-size: 35px !important;
    color: white !important
}

.labelLateralMenu {
    padding-left: 15px !important;
    padding-top: 7px !important;
    cursor: pointer !important;
}

.typeBoxLateralMenu {

    width: 2em !important;
    height: 2em !important;
    border-radius: 20px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 50% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
    font-size: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.searchIconMobile {
    cursor: pointer !important;
    font-size: 35px !important;
    padding-right: 30px !important;
    color: white !important
}

.searchMobileArea {
    background: #FCC02C !important;
    visibility: hidden;
    height: 0px;
    transition: all ease 0.5s;
    text-align: center;
}

.searchMobileAreaOpen {
    background: #FCC02C !important;
    visibility: visible;
    height: 70px;
    position: relative;
    z-index: 1000;
    transition: all ease 0.5s;
    text-align: center;
}

.searchInputHeaderMobileOpen {
    width: 90% !important;
    height: 50px !important;
    border-radius: 50px !important;
    margin-top: 10px;
    visibility: visible !important;
}

.searchInputHeaderMobile {
    visibility: hidden !important;
    opacity: 0 !important;
    width: 90% !important;
    height: 50px !important;
    border-radius: 50px !important;
    margin-top: 20px;
}

.userLabel {
    /* background-color: #727071; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    width: 50%;
}

.userLabelName,
.userLabelName * {
    margin: 0 !important;
    padding: 0 !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
}

.userLabelName a:hover {
    color: #FCC02C !important;
}

.userLabelIcon {
    cursor: pointer;
    transform: translateY(9%);
    margin-right: 1rem;
}

@media (max-width: 700px) {
    .searchMobileAreaOpen {
        height: 70px !important;
    }

    .userLabelName {
        display: none;
    }
}

@media (max-width: 768px) {
    .headerSearchWeb {
        /* visibility: hidden; */
        display: none;
        width: 0% !important;
    }

    .logoIcon{
        margin: 0 !important;
        margin-right: auto;
    }

    .headerSearchMobile {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100% !important;
    }

    .rightCol {
        padding-right: 25px;
    }
}
.NavDrawer_drawer__nyner {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
}

.NavDrawer_drawer__nyner>div {
    width: 100% !important;
}


.NavDrawer_drawerHeader__1hbpE {
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.NavDrawer_drawerHeaderUserIcon__3ykgv {
    background: #FCC02C;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    height: 35px;
    padding: 8px;
    width: 35px;
}

.NavDrawer_drawerHeaderUserName__2-A6S {
    color: #E4056F;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.NavDrawer_drawerHeaderMessage__2Pvgx,
.NavDrawer_drawerHeaderMessage__2Pvgx * {
    font-weight: 700;
    font-size: 19.2px;
    color: #E4056F;
}


.NavDrawer_drawerFooter__3Xnuy {
    text-align: center;
    padding: 10px 0;
}

.NavDrawer_drawerFooterButton__31ZC9 {
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #808080;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

.NavDrawer_drawerFooterButtonCompany__1qVGA {
    cursor: pointer;
    background: transparent;
    border: 1px solid #808080;
    outline: none;
    padding: 9px 32px;
    border-radius: 8px;
    margin-bottom: 1rem;
    color: #808080;
    font-weight: bold;
    width: 100%;
    transition: all .3s !important;
}

.NavDrawer_drawerFooterButtonCompany__1qVGA:hover {
    background: #808080 !important;
    color: #fff !important;
}

.NavDrawer_panelHeader__1fJl3 {
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.NavDrawer_panelHeader__1fJl3:hover {
    text-decoration: underline !important;
}

.NavDrawer_panelHeaderImage__1-ja0 {
    width: 24px;
    height: 24px;
}

.NavDrawer_panelHeaderTitle__gSrOQ {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.NavDrawer_panelNav__-YjRk {
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    gap: .5rem;
    padding-left: 2.5rem;
}

.NavDrawer_drawerFooterButton__31ZC9:hover {
    text-decoration: underline !important;
}


.NavDrawer_panelNavLink__7RU4C {
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #E4056F;
}

.NavDrawer_panelNavLink__7RU4C:hover {
    color: #E4056F !important;
    text-decoration: underline !important;
}

@media screen and (min-width: 769px) {
    .NavDrawer_drawer__nyner>div {
        max-width: 360px !important;
    }

    .NavDrawer_drawerHeader__1hbpE {
        display: none;
    }
}
.Promotions_container__eTXys {
  margin: 2rem auto 12rem;
  max-width: 2000px !important;
}

.Promotions_promotionsContainer__3Emei {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 100% !important;
}

.Promotions_headingContainer__1Lc7t {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 801;
}

.Promotions_headingContainer__1Lc7t[class*="stickyHeader"] {
  background: #fff;
}

.Promotions_headingContainer__1Lc7t > h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.Promotions_headingContainer__1Lc7t img {
  width: 50px;
  height: 50px;
  z-index: 10;
}

.Promotions_promotions__3b7i6 {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  width: 100% !important;
}

.Promotions_promotions__3b7i6 div[class="infinite-scroll-component__outerdiv"] {
  width: 100% !important;
}

.Promotions_promotionsFiltersSticky__2LUfo {
  margin-left: auto;
  z-index: 800;
}
.Promotions_noPromotions__3uijV {
  margin: auto !important;
  width: 100% !important;
}

.Promotions_promotionsGrid__1OCDN {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
  justify-content: space-between;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  grid-column-gap: 2rem;
  column-gap: 2rem;
  width: 100%;
  padding-bottom: 1.5rem;
}

.Promotions_promotionsFiltersGridBox__j04se {
  visibility: hidden;
  padding-left: 1.5rem;
}

@media (max-width: 1600px) {
  .Promotions_promotionsGrid__1OCDN {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-evenly;
  }
}

@media (min-width: 769px) {
  .Promotions_overlay__2UjJW {
    display: none;
  }
}

@media (max-width: 768px) {
  .Promotions_overlay__2UjJW {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    z-index: 800;
  }
  .Promotions_headingContainer__1Lc7t {
    padding-left: 0.5rem;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .Promotions_headingContainer__1Lc7t[class*="stickyHeader"] {
    padding: 1rem 0;
  }
  .Promotions_headingContainerWithFiltersVisible__2ulE8 {
    background: transparent !important;
  }
  .Promotions_headingContainerWithFiltersVisible__2ulE8[class*="stickyHeader"] > img,
  .Promotions_headingContainerWithFiltersVisible__2ulE8[class*="stickyHeader"] > h2 {
    opacity: 0;
  }
  .Promotions_promotionsFiltersGridBox__j04se {
    display: none;
  }
  .Promotions_headingContainer__1Lc7t > h2 {
    font-size: 20px;
  }
  .Promotions_headingContainer__1Lc7t img {
    width: 30px;
    height: 30px;
  }
  .Promotions_promotionsFilters__sUMsJ {
    top: 0;
  }
  .Promotions_promotionsFiltersSticky__2LUfo {
    top: 0 !important;
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
    padding: 0.6rem 0 !important;
  }
}

@media (max-width: 600px) {
  .Promotions_promotionsGrid__1OCDN {
    grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
  }
}

@media (max-width: 380px) {
  .Promotions_headingContainer__1Lc7t {
    grid-gap: 0.3rem;
    gap: 0.3rem;
  }
  .Promotions_headingContainer__1Lc7t > h2 {
    font-size: 18px;
  }
}

.rowContainer{
    margin-bottom: 2%;
    margin-top: 2%;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}


.rowContainer::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.rowContainer::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.rowContainer::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.rowContainer::-webkit-scrollbar-button {
    display:none;
}

.promotionsWeb{
    margin-top: 30px;
}

.typeBox{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.labelBox{
    background-color: white !important;
    border-radius: 40px !important;
    width: 90% !important;
    position: absolute;
    bottom: 10%;
    left: 5%;
}

.promotionBox{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

.leftButton{
    text-align: end;
    padding-right: 50px !important;
}

.rightButton{
    margin-left: 50px !important;
}

.promotionsMobile{
    visibility: hidden;
    height: 0px !important;
}

.loadingSpinner{
    padding-top: 40px !important;
}

.ant-spin-dot-item{
   background-color: red !important;
}

@media (max-width: 900px){

    .promotionsWeb{
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
    }

    .promotionsMobile{
        margin-top: 40px;
        visibility: visible;
        height: auto !important;
    }

    .promotionBoxMobile{
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    .typeBox{
        width: 13.125em !important;
        height: 13.125em !important;
    }

    .promotionBox{
        width: 13.125em !important;
        height: 13.125em !important;
    }

    .leftButton{
        text-align: end;
        padding-right: 5px !important;
    }
    
    .rightButton{
        text-align: start;
        margin-left: 5px !important;
    }
}

@media (max-width: 600px) {
    .typeBox{
        width: 10.125em !important;
        height: 10.125em !important;
    }

    .promotionBox{
        width: 10.125em !important;
        height: 10.125em !important;
    }

}

@media (max-width: 500px) {
    .typeBox{
        width: 9.125em !important;
        height: 9.125em !important;
    }

    .promotionBox{
        width: 9.125em !important;
        height: 9.125em !important;
    }

}
.ModalPromotion_modal__286To {
  max-width: 900px !important;
}

.ModalPromotion_modal__286To div:nth-child(2) {
  border-radius: 16px !important;
}

.ModalPromotion_modalCloseButton__1JeqN {
  font-size: 24px !important;
  background: transparent !important;
  border-radius: 100% !important;
  padding: 5px !important;
  color: #fff !important;
  font-weight: bold !important;
  margin: 1rem 3rem 0 0 !important;
  transition: all 0.5s !important;
}

.ModalPromotion_modalCloseButton__1JeqN:hover {
  color: #eee !important;
}

.ModalPromotion_container__547wj {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.PromotionHeader_header__MKaCi {
  background-position: center !important;
  background-size: cover !important;
  border-radius: 16px 16px 0 0 !important;
  min-height: 10rem !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-end !important;
  padding-left: 5% !important;
}

.PromotionHeader_headerLogo__-kGIN {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(50%);
  width: 10rem;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .PromotionHeader_header__MKaCi {
    height: 200px !important;
    padding-left: 10% !important;
  }
}

.PromotionTerms_collapse__1RKPX {
  margin: 1rem 6% 1.5rem !important;
  width: 100%;
}

.PromotionTerms_collapse__1RKPX div div {
  padding: 0 !important;
}

.PromotionTerms_collapse__1RKPX div div:last-of-type {
  padding: 1rem 0 !important;
}

.PromotionTerms_collapseTitle__1UcwN {
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  margin: 0 !important;
}

.PromotionTerms_collapseDescription__3kd6D {
  color: #808080;
  font-weight: normal;
  font-size: 13px;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media screen and (min-width: 768px) {
  .PromotionTerms_collapse__1RKPX {
    margin: 1rem 12% 1.5rem !important;
  }
}

.PromotionFooter_footer__16P3N {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  padding: 0 1rem 2rem;
  width: 100%;
}

.PromotionFooter_footerText__3TOoy {
  color: #808080;
  font-weight: 600;
  font-size: 16px;
  margin: 0 !important;
}

.PromotionFooter_footerLogo__rFJZu {
  margin-left: 5px;
  max-width: 150px;
  max-height: 75px;
}

.PromotionInfo_container__13zf9 {
  padding: 0 5%;
  padding-top: 5.5rem !important;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.PromotionInfo_info__1rtM0 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;
}

.PromotionInfo_infoTitle__3CJ1p {
  font-weight: 900;
  font-size: 28px;
  color: #333333;
  margin: 0 !important;
  width: 100%;
}

.PromotionInfo_infoPromotionName__1pyai {
  font-weight: 500;
  font-size: 16px;
  color: #e4056f;
  margin: 0 !important;
  text-wrap: balance;
  width: 100%;
}

.PromotionInfo_infoDiscount__3SGpT {
  color: #e4056f;
  font-size: 17px;
  margin: 0 !important;
  width: 100%;
}

.PromotionInfo_infoRating__3gA1x {
  width: 100%;
}

.PromotionInfo_infoRatingStars__2doVA {
  font-size: 30px !important;
}

.PromotionInfo_infoDescription__3nD01 {
  color: #333333;
  font-size: 15px;
  margin: 1rem 0 1.5rem !important;
  width: 100%;
}

.PromotionInfo_infoConditions__1HIIC {
  font-size: 16px;
  width: 100%;
}

.PromotionInfo_infoConditionsTitle__3eMEX {
  font-weight: 600;
}

.PromotionInfo_infoConditionsDescription__Z_l95 {
  font-weight: 400;
  padding-left: 1rem;
  width: 100%;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.PromotionInfo_infoCommunes__3-H1H {
  color: #b20457;
  font-weight: 600;
  font-size: 16px;
  text-wrap: balance;
  margin: 0.5rem 0 1rem;
  width: 100%;
}

.PromotionInfo_infoCommunes__3-H1H svg {
  font-size: 17px !important;
  margin-right: 5px;
}

.PromotionInfo_promotionType__3Mp6X {
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  grid-gap: 8px;
  gap: 8px;
  margin-left: auto;
}

.PromotionInfo_promotionTypeLeftSide__jcjX- {
  margin-left: 0;
  margin-bottom: 1rem;
  margin-right: auto;
}

.PromotionInfo_promotionTypeIcon__7Sj9_ {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .PromotionInfo_promotionTypeRightSide__jhwen {
    display: none !important;
  }
  .PromotionInfo_infoConditionsDescription__Z_l95{
    padding-left: 0;
  }
}

.PromotionInfo_promotionTypeLabel__32hoH {
  font-weight: 700;
  font-size: 10px;
}

.PromotionInfo_dates__QKoSb {
  background-color: #e6e6e6;
  border-radius: 20px;
  padding: 20px 25px 25px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0.5rem auto;
  max-width: 325px;
  width: 100%;
}

.PromotionInfo_datesTitle__dspbY {
  font-weight: 700;
  font-size: 19px;
  color: #000;
  margin-bottom: 0 !important;
}

.PromotionInfo_datesDescription__2wuBp {
  font-weight: normal;
}

.PromotionInfo_buttonBenefitURL__231xL {
  background: #e4056f !important;
  border: none !important;
  border-radius: 16px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  margin-top: 1.5rem !important;
  height: 32px !important;
}

.PromotionInfo_buttonBenefitURL__231xL p {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.PromotionInfo_buttonBenefitURL__231xL:hover {
  background: #b20457 !important;
}

.PromotionInfo_buttonBenefitURL__231xL:active {
  background: #e4056f !important;
}

.PromotionInfo_infoReviewsMessage__3_V8r {
  text-align: center;
  width: 100%;
  color: #e40571;
  font-size: 14px;
  margin: 0 !important;
  font-weight: 500;
  text-wrap: balance;
}

.PromotionInfo_infoReviewsMessageLink__2eehE {
  color: #e40571;
  display: inline-block;
  font-weight: 700 !important;
}

.PromotionInfo_infoReviewsMessageLink__2eehE:first-of-type {
  margin-right: 4px;
}

.PromotionInfo_infoReviewsMessageLink__2eehE:last-of-type {
  margin: 0 4px;
}

.PromotionInfo_infoReviewsMessageLink__2eehE:hover {
  color: #e40571 !important;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .PromotionInfo_container__13zf9 {
    padding: 0 10%;
    grid-template-columns: 60% 40%;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .PromotionInfo_dates__QKoSb {
    margin: 1.5rem auto 0.5rem;
  }
  .PromotionInfo_promotionTypeLeftSide__jcjX- {
    display: none;
  }
  .PromotionInfo_infoCommunes__3-H1H {
    margin: 1rem 0 !important;
  }
}

.PromotionReviews_infoReviewsMessage__C3dZL {
  text-align: center;
  width: 90%;
  color: #e40571;
  font-size: 14px;
  margin: 0 !important;
}

.PromotionReviews_infoReviewsMessageLink__zQU3_ {
  color: #e40571;
  display: inline-block;
  font-weight: bold;
}

.PromotionReviews_infoReviewsMessageLink__zQU3_:first-of-type {
  margin-right: 4px;
}

.PromotionReviews_infoReviewsMessageLink__zQU3_:last-of-type {
  margin: 0 4px;
}

.PromotionReviews_infoReviewsMessageLink__zQU3_:hover {
  color: #e40571 !important;
  text-decoration: underline;
}

.PromotionReviews_infoRatingStars__3sYEa {
  font-size: 21px !important;
}

.PromotionReviews_infoRatingStars__3sYEa * {
  transform: none !important;
}

.PromotionReviews_infoRatingStars__3sYEa:hover li[class="ant-rate-star ant-rate-star-full"] {
  transform: none !important;
  cursor: pointer;
  transition-duration: 0.1s !important;
  color: #e5af28 !important;
}

.PromotionReviews_infoRatingStars__3sYEa li[class="ant-rate-star ant-rate-star-full"]:hover {
  transform: none !important;
  transition-duration: 0.3s !important;
}

.PromotionReviews_infoReviews__23yKT {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
}

.PromotionReviews_infoReviews__23yKT button {
  border: 1px solid #e4056f;
  background-color: transparent;
  border-radius: 24px;
  color: #e4056f;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  padding: 8px 10px;
  transition: all 0.3s;
}

.PromotionReviews_infoReviews__23yKT button:hover {
  border-color: #b20457;
}

.PromotionReviews_infoReviews__23yKT button:last-child {
  background: #fcc02c;
  border: 1px solid #fcc02c;
  color: #fff;
}

.PromotionReviews_infoReviews__23yKT button:last-child:hover {
  background: #e5af28;
}

@media screen and (min-width: 600px) {
  .PromotionReviews_infoReviews__23yKT {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .PromotionReviews_infoReviews__23yKT button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.PromotionReviews_commentButton__1S6Fl {
  background: #e4056f !important;
  outline: none !important;
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.PromotionReviews_commentButton__1S6Fl:hover {
  background: #b20457 !important;
}
.PromotionReviews_commentButton__1S6Fl:active {
  background: #e4056f !important;
}

.ReviewList_header__1h3f5 {
    background: #E4056F;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.ReviewList_headerTitle__2l306 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
}

.ReviewList_headerCloseButton__3fuPJ {
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}

.ReviewList_review__2eQmB {
    text-align: center;
    margin: 0 auto;
    max-width: 30rem;
    padding: 0.5rem 1rem;
}

.ReviewList_reviewHeader__1aVZx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem auto;
    padding: 0 1rem;
}

.ReviewList_reviewHeaderTitle__3uttX {
    font-size: 20px;
    margin: 0;
}

.ReviewList_reviewHeaderStars__CsCLn {
    font-size: 30px !important;
}

.ReviewList_reviewComment__H44Yj {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 1px 1px 3px rgba(163, 163, 163, 0.1);
    font-size: .9rem;
    padding: 2rem 1.5rem;
}

.ReviewList_reviewEmpty__3OiU2 {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    padding: 1.5rem;
}

.ReviewList_buttonContainer__1e1G- {
    padding: .5rem 0 2rem;
    text-align: center;
}

.ReviewList_paginationButton__20Lnt {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 1.1rem;
    margin: auto;
    outline: none;
    text-decoration: underline;
    transition: all .4s;
}

.ReviewList_button__1AnoG:hover {
    background-color: #f71c86;
    transform: scale(1.05);
}

.ReviewList_button__1AnoG:active {
    background-color: #e7157b;
    transform: none;
}
.ReviewModal_header__1XZa2 {
    background: #E4056F;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.ReviewModal_headerTitle__11g5H {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
}

.ReviewModal_headerCloseButton__2m7Es {
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}

.ReviewModal_rating__CFD3N,
.ReviewModal_review___xOO8 {
    text-align: center;
    padding: 1rem;
}

.ReviewModal_ratingTitle__3owtp,
.ReviewModal_reviewTitle__1Q5xQ {
    font-size: 22px;
}

.ReviewModal_ratingStars__1eeuS {
    font-size: 35px !important;
}

.ReviewModal_reviewTextarea__3tlig {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
    font-size: .9rem;
    margin: 0 auto;
    min-height: 8rem;
    outline: none;
    padding: 1rem;
    -ms-resize: vertical;
    -o-resize: vertical;
    -webkit-resize: vertical;
    resize: vertical;
    width: 80%;
}

.ReviewModal_buttonContainer__2a69N {
    padding: .5rem 0;

}

.ReviewModal_button__23NQe {
    background: #E4056F;
    border: none;
    border-radius: 2rem;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1rem auto;
    padding: .3rem 4rem;
    transition: all .4s;
}

.ReviewModal_button__23NQe:hover {
    background-color: #f71c86;
    transform: scale(1.05);
}

.ReviewModal_button__23NQe:active {
    background-color: #e7157b;
    transform: none;
}
/* ------------------------------------------------------ */
/*    Los estilos de este archivo NO se están usando      */
/*    en ModalPromotion pero al ser estilos globales      */
/*    están teniendo side effects en otros sitios de      */
/*    la app, por lo cual igual se importaron hacia       */
/*    el componente ModalPromotion para que estén         */
/*    disponibles de manera global.                       */
/* ------------------------------------------------------ */

.ant-modal-content{
   border-radius: 25px !important;
}

.closeModalButton{
    font-size: 25px !important;
    font-weight: bold !important;
    padding-top: 15px !important;
    background-color: #E4056F !important;
    border-top-right-radius: 10px !important;
    opacity: 0.7 !important;
    margin-right: -14px !important;
    display: none !important;
}

.modal-header{
    background-color: #E4056F;
    border-radius: 20px 20px 0px 0px;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
.modal-header button{
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
}


.closeModalButton:hover{
    opacity: 1 !important;
}

.background-modal{
    background-image: url(https://thumbs.dreamstime.com/b/verduras-anchas-de-la-foto-en-fondo-madera-133445680.jpg);
    background-repeat: no-repeat;
    background-size: cover !important;
    /* border-radius: 25px !important; */
    object-fit: contain;
    text-align: center;
    height: auto !important;
    width: 100% !important;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.ant-modal-body{
    padding: 0px!important;
}

.hart-img{
    position: absolute;
    width: 25px;
    top: 18px !important;
    right: 8% !important;
}

.modal-back-title{
    max-width: 60% !important;
    background-color: #E4056F;
    margin-bottom: 3rem;
    border-radius: 50px;
    margin: 10vh auto 8vh auto;
}

.modal-margin-title{
    padding: 10px;
    font-weight: 700;
    color: white;
}

.modal-display-subtitle{
    display: flex;
    justify-content: space-around;
    padding-top: 40px !important; 
}

.modal-back-info{ 
    width: 50%;
    padding: 0px 20px;
}

.modal-back-date{
    background-color: #E4056F;
    border-radius: 20px;
    padding-left: 30px;
    padding-top: 15px;
    padding-right: 30px;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight:700;
    max-width: 40%;
    margin-bottom: 20px;
    max-height: 15rem;
}


.modal-display-title{
    display: flex;
    justify-content: space-evenly;

}

.modal-logo{
    width: 120px !important;
    height: 120px !important;
    background-position: center;
    background-size: contain;
    border-radius: 20px;
}

.modal-h2-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #E4056F;
}

.modal-h3-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
}

.modal-p-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-list-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-h3-date{
    font-weight:700;
    font-size: 20px;
    color: #FFFFFF;
}

.model-p-date{
    font-size: 15px;
}
.model-p-date a{
    color:  #FCC02C;
}
.model-p-date a:hover{
    color:  #FCC02C;
}


.modal-display-commit{
    display: none;   
}
  
  .modal-h3-commit{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
    max-width: 300px; text-align: center;
  }
  
 .modal-h3-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
 }
 .modal-p-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: #0000009f;
 }

 .modal-footer{
     padding: 30px;
     background-color:#d1d1d1;
     border-radius: 0px 0px 25px 25px;
 }

@media (max-width: 1024px) {
    .hart-img{
        right: 15% !important
    }

    .modal-margin-title{
        font-size: 25px !important;
    }

    .modal-back-date{
       max-width: 70%;
        height: 15rem ;
    }
    .modal-h2-info{
        text-align: center;
        padding-top: 20px;
    }
}


@media (max-width: 800px) {
    .hart-img{
        right: 20% !important
    }

    .modal-back-title{
        max-width: 70% !important;
        border-radius: 40px;
        margin-top: 60px !important;
    }

    .modal-margin-title{
        font-size: 20px !important;
    }

    .modal-display-subtitle{
        display:inline;
    }

    .modal-back-info{ 
        max-width: 100% !important;
        width: 100% !important;
    
    }

    .modal-back-date{
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media (max-width: 450px) {
    .model-p-date{
        font-size: 13px;
    }
    .hart-img{
        right: 25% !important;
    }

    .modal-back-title{
        max-width: 90% !important;
        border-radius: 20px !important;
    }

    .modal-margin-title{
        font-size: 15px !important;
    }

    .modal-back-date{
        height: 19rem !important;   
    }

    .modal-h2-info{
        font-size: 25px;
    }
    
    .modal-h3-info{
        font-size: 17px;
    }
    
    .modal-p-info{
        font-size: 13px;
    }
    .modal-list-info{
        font-size: 13px;
    }
}
 

.ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.PromotionsFilter_filter__PQYXk {
  position: relative !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.PromotionsFilter_filterButton__3B5TW {
  background: #fff !important;
  color: #e4056f !important;
  border: 2px solid #e4056f !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  display: flex !important;
  grid-gap: 1rem !important;
  gap: 1rem !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 8px 40px !important;
  height: 55px !important;
  outline: none !important;
  max-width: 35rem !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.PromotionsFilter_filterButton__3B5TW:hover {
  color: #c30963 !important;
  border-color: #c30963 !important;
}

.PromotionsFilter_filterButton__3B5TW img {
  width: 2rem !important;
  height: 2rem !important;
}

.PromotionsFilter_filterIconClosed__hHKGd {
  transform: rotate(0deg) !important;
  transition: transform 0.3s !important;
}
.PromotionsFilter_filterIconOpened__3KhfA {
  transform: rotate(180deg) !important;
  transition: transform 0.3s !important;
}

@media (max-width: 768px) {
  .PromotionsFilter_filterButton__3B5TW {
    font-size: 14px !important;
    height: auto !important;
    grid-gap: 0.4rem !important;
    gap: 0.4rem !important;
    padding: 8px 12px !important;
  }
  .PromotionsFilter_filterButton__3B5TW img {
    width: 20px !important;
    height: 20px !important;
  }
  .PromotionsFilter_filter__PQYXk {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 4rem !important;
  }
}

@media (max-width: 430px) {
  .PromotionsFilter_filterButton__3B5TW {
    border-radius: 6px !important;
    font-size: 14px !important;
    height: auto !important;
    grid-gap: 0.2rem !important;
    gap: 0.2rem !important;
  }
  .PromotionsFilter_filterButton__3B5TW img {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 380px) {
  .PromotionsFilter_filterButton__3B5TW {
    padding: 8px !important;
    font-size: 13px !important;
  }
}

.FiltersList_container__ATV5X {
  background: #f9f9f9;
  box-shadow: 4px 4px 4px 0px #0000001a;
  position: absolute;
  border-radius: 8px;
  top: 5rem !important;
  left: 0 !important;
  padding: 1rem;
  text-align: left;
  width: 100%;
  overflow-y: scroll;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 70vh;
}

.FiltersList_container__ATV5X::-webkit-scrollbar {
  background-color: #f9f9f9;
  width: 5px;
}

/* background of the scrollbar except button or resizer */
.FiltersList_container__ATV5X::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
.FiltersList_container__ATV5X::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 16px;
  border: 4px solid #d7d7d7;
  background: transparent !important;
}

/* set button(top and bottom of the scrollbar) */
.FiltersList_container__ATV5X::-webkit-scrollbar-button {
  display: none;
}



.FiltersList_filterButton__1uNK8 {
  background: #fcc02c !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  margin-top: 1rem !important;
}

.FiltersList_filterButton__1uNK8:disabled {
  background: #dfdfdf !important;
  color: #2b2b2b !important;
}

.FiltersList_filterButton__1uNK8:disabled:hover {
  background: #dfdfdf !important;
}

.FiltersList_filterButton__1uNK8:hover {
  background: #e5af28 !important;
}

.FiltersList_filterButton__1uNK8:active {
  background: #fcc02c !important;
}
@media (max-width: 768px) {
  .FiltersList_container__ATV5X {
    width: 15rem;
    position: absolute !important;
    top: 3.5rem !important;
    left: -5.25rem !important;
  }
}

@media (max-width: 380px) {
  .FiltersList_container__ATV5X {
    left: -6.5rem !important;
  }
}

.FilterByDay_container__2xB6A {
  padding: 0.8rem 0;
  width: 100%;
}

.FilterByDay_header__2lpem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.FilterByDay_headerTitle__RA3z0 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByDay_headerArrowButton__WJFuG {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.FilterByDay_rotate__1d2xb {
  transform: rotate(90deg);
}

.FilterByDay_headerArrowButton__WJFuG img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.FilterByDay_checkboxGroup__2ieD3 {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.FilterByDay_checkboxGroup__2ieD3 label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  grid-gap: 0.2rem !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.FilterByDay_checkboxGroup__2ieD3 .FilterByDay_ant-checkbox-inner__Lh2JX {
  border: 1px solid #000 !important;
}

.FilterByDay_checkboxGroup__2ieD3 .FilterByDay_ant-checkbox-wrapper-checked__3TWjz .FilterByDay_ant-checkbox-inner__Lh2JX {
  background-color: transparent !important;
  border: none !important;
}

.FilterByDay_checkboxGroup__2ieD3 label .FilterByDay_ant-checkbox-inner__Lh2JX {
  border: none !important;
}

.FilterByDay_checkboxGroup__2ieD3 label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.FilterByDay_checkboxGroup__2ieD3 label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.FilterByDay_checkboxGroup__2ieD3 label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.FilterByDay_checkboxGroup__2ieD3 label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.FilterByDay_checkboxGroup__2ieD3 label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .FilterByDay_headerTitle__RA3z0 {
    font-size: 16px;
  }
  .FilterByDay_checkboxGroup__2ieD3 label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .FilterByDay_checkboxGroup__2ieD3 label span {
    font-size: 14px !important;
  }
}

.FilterByCommune_container__2ZYzh {
  padding: 0.8rem 0;
  width: 100%;
}

.FilterByCommune_header__XkMUg {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.FilterByCommune_headerTitle__24xGw {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByCommune_headerArrowButton__2Qx9N {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.FilterByCommune_rotate__1-tI7 {
  transform: rotate(90deg);
}

.FilterByCommune_headerArrowButton__2Qx9N img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.FilterByCommune_checkboxGroup__3upgx {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.FilterByCommune_checkboxGroup__3upgx label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  grid-gap: 0.2rem !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.FilterByCommune_checkboxGroup__3upgx .FilterByCommune_ant-checkbox-inner__m-3cU {
  border: 1px solid #000 !important;
}

.FilterByCommune_checkboxGroup__3upgx .FilterByCommune_ant-checkbox-wrapper-checked__1KxUj .FilterByCommune_ant-checkbox-inner__m-3cU {
  background-color: transparent !important;
  border: none !important;
}

.FilterByCommune_checkboxGroup__3upgx label .FilterByCommune_ant-checkbox-inner__m-3cU {
  border: none !important;
}

.FilterByCommune_checkboxGroup__3upgx label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.FilterByCommune_checkboxGroup__3upgx label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.FilterByCommune_checkboxGroup__3upgx label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.FilterByCommune_checkboxGroup__3upgx label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.FilterByCommune_checkboxGroup__3upgx label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .FilterByCommune_headerTitle__24xGw {
    font-size: 16px;
  }
  .FilterByCommune_checkboxGroup__3upgx label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .FilterByCommune_checkboxGroup__3upgx label span {
    font-size: 14px !important;
  }
}

.FilterByDiscount_container__1XTKI {
  padding: 0.8rem 0;
  width: 100%;
}

.FilterByDiscount_header__1GGOq {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.FilterByDiscount_headerTitle__1mt7W {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByDiscount_headerArrowButton__3zR-F {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.FilterByDiscount_rotate__1y2Ty {
  transform: rotate(90deg);
}

.FilterByDiscount_headerArrowButton__3zR-F img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.FilterByDiscount_checkboxGroup__3oNWl {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.FilterByDiscount_checkboxGroup__3oNWl label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  grid-gap: 0.2rem !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.FilterByDiscount_checkboxGroup__3oNWl .FilterByDiscount_ant-checkbox-inner__hfe4V {
  border: 1px solid #000 !important;
}

.FilterByDiscount_checkboxGroup__3oNWl .FilterByDiscount_ant-checkbox-wrapper-checked__1NkGw .FilterByDiscount_ant-checkbox-inner__hfe4V {
  background-color: transparent !important;
  border: none !important;
}

.FilterByDiscount_checkboxGroup__3oNWl label .FilterByDiscount_ant-checkbox-inner__hfe4V {
  border: none !important;
}

.FilterByDiscount_checkboxGroup__3oNWl label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.FilterByDiscount_checkboxGroup__3oNWl label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.FilterByDiscount_checkboxGroup__3oNWl label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.FilterByDiscount_checkboxGroup__3oNWl label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.FilterByDiscount_checkboxGroup__3oNWl label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .FilterByDiscount_headerTitle__1mt7W {
    font-size: 16px;
  }
  .FilterByDiscount_checkboxGroup__3oNWl label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .FilterByDiscount_checkboxGroup__3oNWl label span {
    font-size: 14px !important;
  }
}

.FilterByCompanies_container__3dTb0 {
  padding: 0.8rem 0;
  width: 100%;
}

.FilterByCompanies_header__1KLqw {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.FilterByCompanies_headerTitle__2FJ0Y {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByCompanies_headerArrowButton__fi-Sd {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.FilterByCompanies_rotate__sXKet {
  transform: rotate(90deg);
}

.FilterByCompanies_headerArrowButton__fi-Sd img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.FilterByCompanies_checkboxGroup__1V8JP {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.FilterByCompanies_checkboxGroup__1V8JP label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  grid-gap: 0.2rem !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.FilterByCompanies_checkboxGroup__1V8JP .FilterByCompanies_ant-checkbox-inner__tKYzr {
  border: 1px solid #000 !important;
}

.FilterByCompanies_checkboxGroup__1V8JP .FilterByCompanies_ant-checkbox-wrapper-checked__1Uo2N .FilterByCompanies_ant-checkbox-inner__tKYzr {
  background-color: transparent !important;
  border: none !important;
}

.FilterByCompanies_checkboxGroup__1V8JP label .FilterByCompanies_ant-checkbox-inner__tKYzr {
  border: none !important;
}

.FilterByCompanies_checkboxGroup__1V8JP label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.FilterByCompanies_checkboxGroup__1V8JP label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.FilterByCompanies_checkboxGroup__1V8JP label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.FilterByCompanies_checkboxGroup__1V8JP label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.FilterByCompanies_checkboxGroup__1V8JP label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .FilterByCompanies_headerTitle__2FJ0Y {
    font-size: 16px;
  }
  .FilterByCompanies_checkboxGroup__1V8JP label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .FilterByCompanies_checkboxGroup__1V8JP label span {
    font-size: 14px !important;
  }
}

.FilterByCategories_container__nz-oQ {
  padding: 0.8rem 0;
  width: 100%;
}

.FilterByCategories_header__3gqbh {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.FilterByCategories_headerTitle__2Z0tI {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByCategories_headerArrowButton__1_f3e {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.FilterByCategories_rotate__3tjdB {
  transform: rotate(90deg);
}

.FilterByCategories_headerArrowButton__1_f3e img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.FilterByCategories_checkboxGroup__28v2c {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.FilterByCategories_checkboxGroup__28v2c label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  grid-gap: 0.2rem !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.FilterByCategories_checkboxGroup__28v2c .FilterByCategories_ant-checkbox-inner__1DHdM {
  border: 1px solid #000 !important;
}

.FilterByCategories_checkboxGroup__28v2c .FilterByCategories_ant-checkbox-wrapper-checked__23t-v .FilterByCategories_ant-checkbox-inner__1DHdM {
  background-color: transparent !important;
  border: none !important;
}

.FilterByCategories_checkboxGroup__28v2c label .FilterByCategories_ant-checkbox-inner__1DHdM {
  border: none !important;
}

.FilterByCategories_checkboxGroup__28v2c label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.FilterByCategories_checkboxGroup__28v2c label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.FilterByCategories_checkboxGroup__28v2c label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.FilterByCategories_checkboxGroup__28v2c label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.FilterByCategories_checkboxGroup__28v2c label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .FilterByCategories_headerTitle__2Z0tI {
    font-size: 16px;
  }
  .FilterByCategories_checkboxGroup__28v2c label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .FilterByCategories_checkboxGroup__28v2c label span {
    font-size: 14px !important;
  }
}

.FilterByMyPartners_container__38mgL {
  padding: 0.8rem 0 0;
  width: 100%;
}

.FilterByMyPartners_header__3HbhN {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}

.FilterByMyPartners_headerTitle__DOZTV {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterByMyPartners_optionsContainer__q9GQC {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.FilterByMyPartners_link__1e4ks {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #e4056f;
}
.FilterByMyPartners_link__1e4ks:hover {
  color: #af0959;
}
.FilterByMyPartners_link__1e4ks:active {
  color: #e4056f;
}

@media (max-width: 768px) {
  .FilterByMyPartners_headerTitle__DOZTV {
    font-size: 16px;
  }
}

.Switch_container__18htk {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    width: 160px !important;
}

.Switch_switch__1K3Zc {
    position: relative;
    transition: all .5s;
    width: 45px;
}

.Switch_switchBar__aNipr {
    display: block;
    border-radius: 32px;
    height: 20px !important;
    width: 45px !important;
}

.Switch_switchBarActive__3qHV4 {
    background: #E4056F40;
}

.Switch_switchBarDesactive__3SJyB {
    background: #B3B3B3;
}

.Switch_switchBall__LwXYw {
    display: block;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: -2px;
    transition: all .5s;
}

.Switch_switchBallActive__3Irid {
    background: #E4056F;
    right: 0;
    left: unset;
}

.Switch_text__2P47h {
    font-weight: 400;
    font-size: 15px;
    font-style: normal !important;
    color: #808080;
}
.PromotionCard_container__CmSVQ {
  border-radius: 16px !important;
  background-color: #fff !important;
  background-size: cover !important;
  cursor: pointer !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07) !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  position: relative;
  padding: 0 !important;
}

.PromotionCard_container__CmSVQ::after {
  border-radius: 16px 16px 0 0 !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background: rgba(228, 5, 111, 0.451);
  transition: all 0.5s;
  opacity: 0;
}

.PromotionCard_container__CmSVQ:hover::after {
  opacity: 1;
}

.PromotionCard_image__2zQ1J {
  border-radius: 16px 16px 0 0 !important;
  background: rgba(228, 5, 113, 0.4);
  cursor: pointer;
  height: 140px !important;
  object-fit: cover !important;
  object-position: center !important;
  width: 100% !important;
}

.PromotionCard_iconContainer__1aUQw {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 5;
}
.PromotionCard_icon__2Ik1W {
  margin: auto;
  width: 100%;
}

.PromotionCard_header__3bo_C {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0.5rem 1rem 0.8rem;
}

.PromotionCard_title__i28l3 {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  margin-bottom: unset !important;
}

.PromotionCard_discount__1D2kM {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: unset !important;
}

.NoPromotions_container__3yAZh {
    background: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 40vh;
    margin: 4rem auto !important;
}

.NoPromotions_message__2r32z {
    color: #ccc;
    font-size: 1.5rem;
    width: 90%;
}
.Instructions_wave__DUajb {
    width: 100%;
    margin-top: -.5rem;
    transform: rotate(180deg);
    margin-bottom: 2rem;
}

.Instructions_container__2aqbx {
    background: #FCC02C;
    width: 100%;
}

.Instructions_carousel__1-fJB {
    background: #E4056F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 0 !important;
}

.Instructions_carousel__1-fJB ul {
    bottom: 6rem !important;
    margin: 0 auto !important;
    width: 120px !important;
}

.Instructions_carousel__1-fJB ul li {
    background: #E4056F !important;
    border-radius: 100% !important;
    height: 16px !important;
    width: 16px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    opacity: 1 !important;
}

.Instructions_carousel__1-fJB ul li button {
    background: #808080 !important;
    border-radius: 100% !important;
    height: 16px !important;
    width: 16px !important;
}

.Instructions_carouselItem__3qyCK {
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 0 1rem;
    padding-top: 2rem;
    height: 320px !important;
    width: 30rem !important;
    text-align: center;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border: 2px solid #E4056F;
    min-height: 15rem;
    max-width: 600px !important;
    width: 95% !important;
}

.Instructions_carouselItemTitle__1MibO {
    font-weight: 600;
    font-size: 27px;
    color: #E4056F;
}

.Instructions_carouselItemDescription__2twAV {
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    margin: auto;
    width: 90%;
}

@media screen and (min-width: 650px) {
    .Instructions_container__2aqbx {
        margin-bottom: -1rem;
    }

    .Instructions_carousel__1-fJB {
        padding: 50px 0 25px !important;
    }

    .Instructions_carouselItem__3qyCK {
        height: 250px !important;
    }

    .Instructions_carousel__1-fJB ul {
        bottom: 5rem !important;
    }

    .Instructions_wave__DUajb {
        margin-bottom: 0;
    }
}
.Footer_container__2ZCdp {
    background: #FCC02C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.Footer_wave__2thTe {
    padding: 0 !important;
    margin-top: -4rem;
    margin-bottom: -.5rem;
    width: 100%;
}

.Footer_content__1OBJx {
    background: #FCC02C;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Footer_nav__1JN50 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    text-align: left;
    padding: 2rem 1rem 4rem;
    width: 100%;
}

.Footer_navLink__1DD2T {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 15rem;
}

.Footer_navLink__1DD2T:hover {
    color: #fff !important;
    text-decoration: underline !important;
}

@media screen and (min-width: 768px) {
    .Footer_content__1OBJx {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        max-width: 1100px;
        width: 90%;
        padding-bottom: 4rem;
        margin-top: -2rem;
    }

    .Footer_logo__3KuW5 {
        cursor: pointer;
        margin: auto;
        margin-left: 20%;
        max-width: 130px;
    }

    .Footer_nav__1JN50 {
        padding: 0;
        width: 15rem;
    }
}
#background{
    background-image: url(/static/media/background_main.51d4b2bc.svg);
    width:100%;
    height: 100%;
    background-size: cover;
    background-repeat: repeat-y;
}

.Banner_content__TIEbW {
    background: #FCC02C !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem 1rem 1rem;
    z-index: 150;
}

.Banner_contentTitle__1AuvN,
.Banner_contentDescription__1jRGN {
    color: #FFFFFF;
    margin: auto !important;
    max-width: 38rem;
    width: 95%;
}

.Banner_contentTitle__1AuvN {
    line-height: 38px;
    margin-bottom: 1rem !important;
    font-weight: 700;
    font-size: 26px;
}

.Banner_contentDescription__1jRGN {
    font-weight: 400;
    font-size: 16px;
}

.Banner_animation__33zJ7 {
    display: flex !important;
    position: relative;
}

.Banner_animation__33zJ7::after {
    content: '';
    background: #FCC02C;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Banner_animation__33zJ7 img {
    margin: auto;
    max-width: 40rem;
    width: 90%;
    z-index: 10;
}

.Banner_wave__34fsw {
    transform: rotate(180deg);
    margin-bottom: 1rem;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .Banner_container__3zx6e {
        background: #FCC02C !important;
        margin-bottom: -.5rem !important;
    }

    .Banner_content__TIEbW {
        padding: 3rem 0 !important;
        z-index: 100 !important;
    }

    .Banner_contentTitle__1AuvN,
    .Banner_contentDescription__1jRGN {
        width: 95%;
    }

    .Banner_animation__33zJ7 {
        align-items: center !important;
    }

    .Banner_animation__33zJ7 img {
        max-width: 35rem;
        width: 100%;
    }

    .Banner_wave__34fsw {
        margin-top: -1rem;
    }
}

@media screen and (min-width: 900px) {
    .Banner_contentTitle__1AuvN {
        font-size: 38px;
        line-height: 48px;
    }

    .Banner_contentDescription__1jRGN {
        font-size: 20px;
    }

    .Banner_wave__34fsw {
        margin-top: -2rem;
        margin-bottom: 3rem;
    }
}


@media screen and (min-width: 1024px) {

    .Banner_content__TIEbW {
        padding: 5rem 0 2rem !important;
        padding-right: 0 !important;
    }

    .Banner_animation__33zJ7 {
        padding-top: 2rem !important;
    }

}

@media screen and (min-width: 1300px) {
    .Banner_contentTitle__1AuvN {
        width: 91%;
    }

    .Banner_wave__34fsw {
        margin-top: -3rem;
    }

}
.ContactForm_form__20TXy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 2rem 1rem 0 !important;
    margin: 1rem auto 7rem !important;
}

.ContactForm_formItem__PXLwo {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 40rem;
    width: 90%;
}

.ContactForm_formItemLabel__1you9 {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 7px;
}

.ContactForm_formItemInput__3_wk_ {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #aaa !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.ContactForm_formItemInputTextarea__1JfJ8 {
    min-height: 7rem !important;
    -ms-resize: vertical !important;
    resize: vertical !important;
}

.ContactForm_formItemInputSelect__270vi div {
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    border-radius: 0 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    text-align: left !important;
    align-items: center !important;
    border-radius: 16px !important;
    width: 90% !important;
}

.ContactForm_formItemInput__3_wk_:focus {
    border-color: #8a8a8a !important;
}

.ContactForm_formItemInput__3_wk_::placeholder {
    color: #ccc !important;
}

.ContactForm_formButton__1qffi {
    background: #E4056F !important;
    border: none !important;
    border-radius: 20px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.ContactForm_formButton__1qffi:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 768px) {
    .ContactForm_formButton__1qffi {
        margin-left: auto !important;
        width: 200px !important;
    }
}
.ContactHeader_wave__BPBqS {
    padding: 0 !important;
    margin-top: -.5rem;
    transform: rotate(180deg);
    width: 100%;
}

.ContactHeader_header__1SRUY {
    background: #FCC02C;
    background-position: top;
    padding: 3rem 0 1rem;
    width: 100%;
}

.ContactHeader_headerTitleContainer__iRkGO {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactHeader_headerTitle__2ndYH {
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    margin: 0 !important;
}

.ContactHeader_headerIcon__JXTPe {
    height: 30px;
    margin-right: 7px;
    width: 30px;
}

.ContactHeader_headerDescription__21Q5_ {
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin: .5rem auto;
    max-width: 35rem;
    width: 85%;
}
.Promotions_mainRow__J3Fkm {
    margin-bottom: 10rem !important;
}

.Promotions_mainCol__3Bg5d {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 3rem auto !important;
    grid-gap: 2rem !important;
    gap: 2rem !important;
    width: 90% !important;
    padding: 0 1rem !important;
}

@media screen and (min-width: 768px) {
    .Promotions_mainCol__3Bg5d {
        justify-content: -ms-space-evenly !important;
        justify-content: space-evenly !important;
        grid-gap: 3rem !important;
        gap: 3rem !important;
        width: 100% !important;
        padding: 0 !important;
    }
}
.rowTypePromotionContainer{
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    min-height: 30vh !important;
}

.promotionDescriptionContainer{
    border: 1px solid #E4056F;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
}

.promotionDescription{
    align-self: center;
    padding-top: 5em;
}

.promotionBoxContainer{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 70px !important;
}

.promotionListContainer{
    padding-left: 50px !important;
    padding-right: 70px !important;
    padding-top: 40px !important;
    padding-bottom: 70px !important;
}

.labelNoPromotionWithType{
    color: #ccc;
    text-align: center;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 28px;
    margin: 8rem auto;
}

.typeBoxTypeScreen{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.promotionBoxTypeScreen{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

@media (max-width: 992px) {
    .rowTypePromotionContainer{
        margin-bottom: -15px !important;
    }
    
    .colTypePromotion{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .promotionBoxContainer{
        margin-bottom: 20px !important;
    }
    .labelNoPromotionWithType{
        font-size: 20px;
    }
}
.Banner_wave__7SQpe {
    padding: 0 !important;
    margin-top: -.5rem;
    transform: rotate(180deg);
    width: 100%;
}

.Banner_header__2GIl0 {
    background: #FCC02C;
    background-position: top;
    padding: 3rem 0 1rem;
    width: 100%;
}

.Banner_headerTitleContainer__19eZ- {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Banner_headerTitle__2nI8D {
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    margin: 0 !important;
}

.Banner_headerIcon__1jdeY {
    height: 30px;
    margin-right: 7px;
    width: 30px;
}

.Banner_headerDescription__3c1H6 {
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin: .5rem auto;
    max-width: 35rem;
    width: 85%;
}
.FilterTags_tagsCol__3qVP9 {
    margin: auto !important;
    display: flex !important;
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
    overflow-x: auto !important;
    width: 100% !important;
    padding-bottom: 1rem !important;
    position: relative !important;
}

.FilterTags_mobileTitleCol__139CM {
    margin: auto !important;
    margin-top: 2rem !important;
}

.FilterTags_mobileTitle__V-Yzw,
.FilterTags_tagsColDeskTitle__2SWQR {
    font-size: 15px !important;
    font-weight: 400;
    color: #E4056F;
    min-width: -moz-fit-content !important;
    min-width: -webkit-fill-available;
    min-width: -webkit-fit-content !important;
    min-width: fit-content !important
}

.FilterTags_mobileTitle__V-Yzw {
    text-align: left;
    width: 100% !important;
}

.FilterTags_tagsColDeskTitle__2SWQR {
    display: none !important;
}

.FilterTags_tagsCol__3qVP9::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.FilterTags_tagsCol__3qVP9::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
.FilterTags_tagsCol__3qVP9::-webkit-scrollbar-thumb {
    background: transparent;
}

/* set button(top and bottom of the scrollbar) */
.FilterTags_tagsCol__3qVP9::-webkit-scrollbar-button {
    display: none;
}



.FilterTags_tagButton__3DLl- {
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #E4056F;
    outline: none;
    padding: 10px 16px;
    min-width: -moz-fit-content !important;
    min-width: -webkit-fill-available;
    min-width: -webkit-fit-content !important;
    min-width: fit-content !important;
}

.FilterTags_tagButton__3DLl- svg {
    font-size: 18px !important;
    margin-right: 3px !important;
}

@media screen and (min-width: 768px) {
    .FilterTags_tagsColDeskTitle__2SWQR {
        margin: auto 0 auto auto !important;
        display: block !important;
    }

    .FilterTags_mobileTitle__V-Yzw {
        display: none !important;
    }

    .FilterTags_mobileTitleCol__139CM {
        display: none !important;
    }

    .FilterTags_tagsCol__3qVP9 {
        padding-top: 0 !important;
        margin-top: 2rem !important;
    }

    /* scrollbar itself */
    .FilterTags_tagsCol__3qVP9::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 16px;
        border: 4px solid #fff;
    }
}
.SearchHeader_wave__11ap0 {
    padding: 0 !important;
    margin-top: -.5rem;
    transform: rotate(180deg);
    width: 100%;
}

.SearchHeader_header__ndppE {
    background: #FCC02C;
    background-position: top;
    padding: 2rem 2.5rem 1rem;
    width: 100%;
}

.SearchHeader_headerTitleContainer__zM4O_ {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: -moz-fit-content;
    width: -webkit-fill-available;
    width: -webkit-fit-content;
    width: fit-content;
    position: relative;
    padding-left: 3rem;
}

.SearchHeader_headerTitle__1Jx6V {
    font-size: 25px;
    font-weight: normal;
    text-align: left;
    color: #FFFFFF;
    margin: 0 !important;
}

.SearchHeader_headerTitle__1Jx6V span {
    display: inline-block;
    font-weight: bold;
}

.SearchHeader_headerIcon__2M-EJ {
    position: absolute;
    left: 0;
    margin: auto;
    height: 30px;
    width: 30px;
}

@media screen and (min-width: 768px) {

    .SearchHeader_header__ndppE {
        padding: 5rem 0 2rem;
    }

    .SearchHeader_headerTitleContainer__zM4O_ {
        max-width: 90%;
    }
}

@media screen and (min-width: 1024px) {
    .SearchHeader_headerTitle__1Jx6V {
        font-size: 35px;
    }

    .SearchHeader_headerTitle__1Jx6V span {
        margin-left: 8px;
    }
}
.Promotions_container__1-4sp {
  margin: 1rem auto 4rem;
  max-width: 2000px !important;
  margin: 2rem auto 4rem !important;
  padding-bottom: 4rem;
}

.Promotions_promotionsContainer__1rtul {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 100% !important;
}

.Promotions_headingContainer__3m72A {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 801;
}

.Promotions_headingContainer__3m72A[class*="stickyHeader"] {
  background: #fff;
}

.Promotions_headingContainer__3m72A > h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.Promotions_headingContainer__3m72A img {
  width: 50px;
  height: 50px;
  z-index: 10;
}

.Promotions_promotions__32RPa {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  width: 100% !important;
}

.Promotions_promotions__32RPa div[class="infinite-scroll-component__outerdiv"] {
  width: 100% !important;
}

.Promotions_promotionsFiltersSticky__13AJo {
  margin-left: auto;
  z-index: 800;
}
.Promotions_noPromotions__i64nS {
  margin: auto !important;
  width: 100% !important;
}

.Promotions_promotionsGrid__1agRw {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
  justify-content: space-between;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  grid-column-gap: 2rem;
  column-gap: 2rem;
  width: 100%;
  padding-bottom: 1.5rem;
}

.Promotions_promotionsFiltersGridBox__xRKFT {
  visibility: hidden;
  padding-left: 1.5rem;
}

.Promotions_interestingContent__3Lcmr {
  margin-top: 2rem;
  width: 100%;
}

.Promotions_interestingContent__3Lcmr > h2 {
  color: #fcc02c;
  font-size: 25px;
  font-weight: 600;
  padding: 1rem;
  text-wrap: balance;
  text-align: center;
}

@media (max-width: 1600px) {
  .Promotions_promotionsGrid__1agRw {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-evenly;
  }
}

@media (min-width: 769px) {
  .Promotions_overlay__2MLSa {
    display: none;
  }
}

@media (max-width: 768px) {
  .Promotions_container__1-4sp {
    margin-top: 1rem !important;
  }
  .Promotions_interestingContent__3Lcmr > h2 {
    font-size: 1rem;
  }
  .Promotions_overlay__2MLSa {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    z-index: 800;
  }
  .Promotions_headingContainer__3m72A {
    padding-left: 0.5rem;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .Promotions_headingContainer__3m72A[class*="stickyHeader"] {
    padding: 1rem 0;
  }
  .Promotions_headingContainerWithFiltersVisible__CIPHd {
    background: transparent !important;
  }
  .Promotions_headingContainerWithFiltersVisible__CIPHd[class*="stickyHeader"] > img,
  .Promotions_headingContainerWithFiltersVisible__CIPHd[class*="stickyHeader"] > h2 {
    opacity: 0;
  }
  .Promotions_promotionsFiltersGridBox__xRKFT {
    display: none;
  }
  .Promotions_headingContainer__3m72A > h2 {
    font-size: 20px;
  }
  .Promotions_headingContainer__3m72A img {
    width: 30px;
    height: 30px;
  }
  .Promotions_promotionsFilters__3GBGq {
    top: 0;
  }
  .Promotions_promotionsFiltersSticky__13AJo {
    top: 0 !important;
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
    padding: 0.6rem 0 !important;
  }
}

@media (max-width: 600px) {
  .Promotions_promotionsGrid__1agRw {
    grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
  }
}

@media (max-width: 380px) {
  .Promotions_headingContainer__3m72A {
    grid-gap: 0.3rem;
    gap: 0.3rem;
  }
  .Promotions_headingContainer__3m72A > h2 {
    font-size: 18px;
  }
}

.searchPromotionTitle{
    text-align: center;
    font-weight: bold;
    padding-top: 40px;
    font-size: 26px;
}

.searchPromotionSuggestTitle{
    text-align: center;
    font-weight: bold;
    font-size: 26px;
}

.typeBoxSearchScreen{

    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 30% !important;
    background-size: 40% !important;
    flex: 0 0 auto !important;
}

.promotionBoxSearchScreen{
    background-color: gray !important;
    background-size: cover !important;
    width: 15.125em !important;
    height: 15.125em !important;
    border-radius: 30px !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-left: 3% !important;
    flex: 0 0 auto !important;

}

.searchNotFoundIcon{
    font-size: 200px !important;
    margin-top: 50px !important;
    color: black !important;
    opacity: 0.3 !important;
}

.searchNotFoundLabel{
    font-size: 30px !important;
    padding-top: 20px !important;
    padding-bottom: 40px !important;
    font-weight: bold;

}

@media (max-width: 800px) {
    .searchPromotionTitle{
        font-size: 20px;
    }
    
    .searchPromotionSuggestTitle{
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .searchPromotionTitle{
        font-size: 16px;
    }
    
    .searchPromotionSuggestTitle{
        font-size: 16px;
    }
}
.LoginScreen_container__3DxZW {
    background: #FCC02C;
    background-image: url(/static/media/trans-pattern.48a145c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 1rem 3rem;

}

.LoginForm_container__3Zhpy {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.LoginForm_form__15wuq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 1rem 2rem !important;
}

.LoginForm_formItem__1dznY {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.LoginForm_formItemLabel__5LsSG {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.LoginForm_formItemInput__xK831 {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.LoginForm_formItem__1dznY * {
    background-color: #fff !important;
}

.LoginForm_formItemInput__xK831:focus {
    border-color: #8a8a8a !important;
}

.LoginForm_formItemInput__xK831::placeholder {
    color: #ccc !important;
}

.LoginForm_formButton__23ko0 {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.LoginForm_formButton__23ko0 span {
    display: block !important;
    line-height: 0 !important;
}

.LoginForm_formButton__23ko0:hover {
    background: #B20457 !important;
}

.LoginForm_footer__3uHM5 {
    width: 100%;
}

.LoginForm_footerDescription__19xN2 {
    font-weight: 600;
    font-size: 13px;
    color: #808080;
}

.LoginForm_footerDescription__19xN2 a {
    color: #808080;
}

.LoginForm_footerDescription__19xN2 a:hover {
    color: #E4056F;
    text-decoration: underline;
}


.LoginForm_forgotPasswordText___qmh3 a {
    color: #808080;
}

.LoginForm_forgotPasswordText___qmh3 a:hover {
    color: #E4056F;
    text-decoration: underline;
}

@media screen and (min-width: 770px) {

    .LoginForm_formButton__23ko0 {
        max-width: 180px !important;
    }

    .LoginForm_footerDescription__19xN2 {
        font-size: 15px;
    }
}
.LoginHeader_header__2xKj4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.LoginHeader_headerTitle__2aAzd {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.LoginHeader_headerIcon__2uAZS {
    margin-right: 7px;
    height: 50px;
    width: 50px;
}
.RegisterScreen_container__1DCxA {
    background: #FCC02C;
    background-image: url(/static/media/trans-pattern.48a145c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 1rem 3rem;

}
.RegisterHeader_header__N4HAR {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.RegisterHeader_headerTitle__3jpWI {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.RegisterHeader_headerIcon__1PvSr {
    margin-right: 14px;
    border-radius: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    height: 35px;
    width: 35px;
}
.RegisterForm_container__38F_T {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.RegisterForm_form__2Bgs0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem !important;
}

.RegisterForm_formItem__2lp6a {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.RegisterForm_formItemLabel__2D8ZT {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.RegisterForm_formItemInput__1UL-J {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.RegisterForm_formItemInputSelect__2aqn6 div {
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    border-radius: 16px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    text-align: left !important;
}

.RegisterForm_formItemInputSelect__2aqn6 div:focus {
    border: none !important;
    outline: none !important;
}

.RegisterForm_formItemInput__1UL-J[disabled] {
    cursor: not-allowed !important;
}

.RegisterForm_formItemInput__1UL-J:focus {
    border-color: #8a8a8a !important;
}

.RegisterForm_formItemInput__1UL-J::placeholder {
    color: #ccc !important;
}

.RegisterForm_formButton__2u6G7 {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 1.5rem !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.RegisterForm_formButton__2u6G7 span {
    display: block !important;
    line-height: 0 !important;
}

.RegisterForm_formButton__2u6G7:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 770px) {

    .RegisterForm_formButton__2u6G7 {
        max-width: 180px !important;
        margin: auto;
    }

    .RegisterForm_form__2Bgs0 {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .RegisterForm_formItemSpecial__PPKig {
        width: 47% !important;
    }

}
.CompanyOnboarding_container__1VT-a {
    background: #FCC02C !important;
    background-image: url(/static/media/trans-pattern.48a145c1.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    padding: 4rem 1rem 2rem;
}

.CompanyOnboarding_illustrationCol__2WsHx {
    justify-content: center !important;
    text-align: center !important;
    order: 2 !important;
    padding-top: 1rem !important;
}

.CompanyOnboarding_illustration__2m0Om {
    margin: auto !important;
    max-width: 400px;
    width: 80%;
}

@media screen and (min-width: 768px) {
    .CompanyOnboarding_container__1VT-a {
        flex-wrap: nowrap;
        padding: 2rem 1rem;
    }

    .CompanyOnboarding_illustrationCol__2WsHx {
        order: -2 !important;
    }

    .CompanyOnboarding_illustration__2m0Om {
        max-width: 110%;
        max-height: 900px;
        width: 110% !important;
    }
}

@media screen and (min-width: 1024px) {
    .CompanyOnboarding_container__1VT-a {
        padding: 0 1rem 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .CompanyOnboarding_illustration__2m0Om {
        margin-left: 3rem !important;
    }
}
.Welcome_container__2HLg1 {
    background-color: #fff;
    border-radius: 16px;
    margin: auto;
    max-width: 600px;
    padding: 2rem;
    width: 90%;
    text-align: center;
}

.Welcome_title__35yox {
    font-weight: 700;
    font-size: 27.65px;
    color: #E4056F;
    line-height: 32px;
    text-align: left;
}

.Welcome_description__25bvY {
    text-align: left;
    margin: 1.5rem auto 1rem;
}

.Welcome_button__3wTCN {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 8px 0 !important;
    margin-top: 1rem;
    transition: all .5s;
    width: 180px !important;
}

.Welcome_button__3wTCN:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 768px) {

    .Welcome_container__2HLg1 {
        text-align: left;
    }

}

@media screen and (min-width: 1024px) {
    .Welcome_container__2HLg1 {
        padding: 3rem;
    }

}
.CompanyRegister_container__2VJlH {
    background: #FCC02C;
    background-image: url(/static/media/trans-pattern.48a145c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3rem 1rem;
}

@media screen and (min-width: 768px) {
    .CompanyRegister_container__2VJlH {
        padding: 4rem 1rem;
    }
}
.RegisterHeader_header__2Z-vY {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.RegisterHeader_headerTitle__1O4bo {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin: 0 !important;
}

.RegisterHeader_headerTitle__1O4bo span {
    display: none;
}

.RegisterHeader_headerIcon__Ym_9j {
    margin-right: 7px;
    height: 60px;
    width: 60px;
}



@media screen and (min-width:768px) {
    .RegisterHeader_headerTitle__1O4bo {
        font-size: 39px;
        max-width: 380px;
        line-height: 45px;
        margin-left: .5rem !important;
    }

    .RegisterHeader_headerTitle__1O4bo span {
        display: inline-block;
    }

    .RegisterHeader_headerIcon__Ym_9j {
        transform: translateY(5px);
    }
}
.RegisterForm_container__HeWz5 {
    background-color: #fff;
    border-radius: 16px;
    max-width: 475px;
    padding: 2rem 1.5rem;
    text-align: center;
    width: 90%;
}

.RegisterForm_header__3pcfd {
    margin-bottom: 1.5rem;
}

.RegisterForm_headerTitle__35aIC {
    color: #E4056F;
    font-size: 19px;
    font-weight: 600;
}

.RegisterForm_headerSubtitle__A15eJ {
    color: #E4056F;
    font-size: 14px;
}

.RegisterForm_footer__dfi4z {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.RegisterForm_footerButton__3Bbtj {
    background: #E4056F;
    border: 1px solid #E4056F;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 0;
    transition: all .5s;
    width: 100%;
}

.RegisterForm_footerButton__3Bbtj:hover {
    background: #B20457;
    border-color: #B20457;
}

.RegisterForm_footerGhostButton__3-uRL {
    background: #fff !important;
    color: #E4056F;
}

.RegisterForm_footerGhostButton__3-uRL:hover {
    color: #B20457;
}


@media screen and (min-width: 500px) {

    .RegisterForm_footerButton__3Bbtj {
        max-width: 150px !important;
    }

    .RegisterForm_footerDescription__paRGE {
        font-size: 15px;
    }
}
.StepOne_container__8T28_ {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.StepOne_form__3ykPu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 0 2rem !important;
}

.StepOne_formItem__2eH7M {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.StepOne_formItemLabel__1Fx4R {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.StepOne_formItemInput__2oj7c,
.StepOne_formItemInputSelect__EMEw7 {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.StepOne_formItemInputSelect__EMEw7 {
    height: unset !important;
    padding: 0 !important;
}

.StepOne_formItemInputSelect__EMEw7>div {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-radius: 20px !important;
    border: none !important;
    border-color: transparent !important;
    background: transparent !important;
    text-align: left !important;
    margin: auto !important;
    height: 48px !important;
    outline: none !important;
    box-shadow: none !important;
    width: 95% !important;
}

.StepOne_formItem__2eH7M * {
    background-color: #fff !important;
}

.StepOne_formItemInput__2oj7c:focus {
    border-color: #8a8a8a !important;
}

.StepOne_formItemInput__2oj7c::placeholder {
    color: #ccc !important;
}


@media screen and (min-width: 768px) {
    .StepOne_form__3ykPu {
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .StepOne_formItemFlex__1EKR- {
        width: 45% !important;
    }
}
.text-companyHomeScreen{
  max-width: 100%;
  color: #808080 !important;
  font-size: 28px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  max-width: 80%;
 margin: 0px auto;
 padding-bottom: 35px;
}

.text-companyHomeScreen span{
  font-size: 36px;
  color: #E4056F;
}

.img-CompanyHomeScreen{
margin-right: 30px !important;
}

.align-img-CompanyHomeScreen{
text-align: center;
padding-bottom: 100px;
}

.CompanyNav_nav__238Za {
    text-align: center;
}

.CompanyNav_navList__1nsp- {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 0 !important;
    margin: 0 !important;
}

.CompanyNav_navItem__2JnI7 {
    max-width: 400px;
    width: 95%;
}

.CompanyNav_navLink__qH-V6 {
    box-shadow: 0px 4px 10px 0px #0000001A;
    background-color: #fff;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 20px;
    transition: all .5s;
    width: 100%;
}

.CompanyNav_navLink__qH-V6:hover {
    background-color: rgba(238, 238, 238, 0.5);
}

.CompanyNav_navLink__qH-V6:active {
    background-color: rgba(238, 238, 238, 0.8);
}

.CompanyNav_navLinkIcon__1ZLw8 {
    max-height: 45px;
}

.CompanyNav_navLinkText__3EtsG {
    color: #E4056F;
    font-size: 16px;
    font-weight: 600;
}

@media screen and (min-width: 500px) {
    .CompanyNav_navItem__2JnI7 {
        width: 46%;
    }
}

@media screen and (min-width: 768px) {
    .CompanyNav_navList__1nsp- {
        justify-content: flex-start;
        grid-gap: 30px;
        gap: 30px;
    }

    .CompanyNav_navItem__2JnI7 {
        width: 45%;
        max-width: 350px;
    }
}

@media screen and (min-width: 1024px) {
    .CompanyNav_navItem__2JnI7 {
        width: 30%;
    }

    .CompanyNav_navLink__qH-V6 {
        padding: 25px;
    }
}
.CompanyStats_container__3ih5V {
    /* border: 2px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    list-style-type: none;
    overflow-x: auto !important;
    width: 97% !important;
    padding: 0 !important;
    margin: 1rem auto !important;
}

.CompanyStats_container__3ih5V::-webkit-scrollbar {
    background: transparent !important;
    width: 1px;
}

.CompanyStats_container__3ih5V::-webkit-scrollbar-button {
    display: none;
}

.CompanyStats_container__3ih5V::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: none !important;
    background: transparent !important;
}

.CompanyStats_container__3ih5V::-webkit-scrollbar-track {
    background-color: transparent;
}

.CompanyStats_item__1uq33 {
    background: #E4056F;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 15px;
    height: 105px;
    min-width: 165px;
    width: 165px;
}

.CompanyStats_itemValue__1C06O,
.CompanyStats_itemTitle__3O99y {
    color: #fff;
    width: 100%;
}

.CompanyStats_itemValue__1C06O {
    font-weight: 700;
    font-size: 33px;
}

.CompanyStats_itemTitle__3O99y {
    font-size: 13px;
    margin-top: -35px;
}

@media screen and (min-width: 768px) {
    .CompanyStats_container__3ih5V {
        width: 100% !important;
        padding-bottom: 8px !important;
    }


    .CompanyStats_container__3ih5V::-webkit-scrollbar-thumb {
        border: 4px solid #fff !important;
        background: #ccc !important;
    }

    .CompanyStats_item__1uq33 {
        width: 200px;
    }
}

@media screen and (min-width: 1024px) {
    .CompanyStats_container__3ih5V {
        margin-bottom: 2rem !important;
    }

}
.PromotionsTable_deskContainer__1LreD {
    border: 1px solid green;
}

.PromotionsTable_emptyAccordion__2rqJT {
    width: 100% !important;
}

.PromotionsTable_noPromotions__1MJv4 {
    width: 80% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    font-size: 1rem !important;
    margin: 0 auto !important;
}

.PromotionsTable_table__1C4_q {
    display: none !important;
}

.PromotionsTable_table__1C4_q div div div div div table,
.PromotionsTable_accordion__2Kw-X,.PromotionsTable_emptyAccordion__2rqJT {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.PromotionsTable_accordion__2Kw-X {
    padding: 1.5rem 1rem !important;
    margin-bottom: 5rem !important;
}

.PromotionsTable_accordion__2Kw-X div {
    border-color: #eee !important;
}

.PromotionsTable_accordionPanel__1vS57>div {
    font-size: 16px !important;
}

.PromotionsTable_accordionPanel__1vS57>div>span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.PromotionsTable_accordionHeader__MzFHa {
    display: flex;
    align-items: center;
    margin: auto !important;
    width: 94% !important;
}

.PromotionsTable_accordionHeader__MzFHa h2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #E4056F !important;
    margin: 0 !important;
    width: 50%;
    max-width: 150px !important;
}

.PromotionsTable_accordionTitle__b6_oq,
.PromotionsTable_accordionContentItemTitle__11D84 {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.PromotionsTable_accordionContent__1XQml {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 15px !important;
    max-width: 400px;
    margin: auto;
    margin-left: -1rem;
    width: 112%;
}

.PromotionsTable_accordionContentItem__337vG {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    margin-bottom: 15px;
}

.PromotionsTable_accordionContentItem__337vG p {
    margin-right: auto !important;
}

.PromotionsTable_accordionContentItemTitle__11D84 {
    font-size: 14px !important;
    margin: 0 !important;
}

.PromotionsTable_panelHeader__2ECJ4 {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.PromotionsTable_panelHeaderTitle__29pK2 {
    font-weight: 400 !important;
    margin: 5px 0 !important;
}

.PromotionsTable_switchContainer__1SbzX {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 130px;
}

.PromotionsTable_table__1C4_q div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.PromotionsTable_table__1C4_q div div div div div table thead tr th::before {
    background: transparent !important;
}

.PromotionsTable_table__1C4_q div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

.PromotionsTable_tableName__2Qo1W,
.PromotionsTable_tableEmail__3cntH {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.PromotionsTable_tableNameVisibleTrue__1-Zoh,
.PromotionsTable_tableNameVisibleFalse__1knWk {
    font-weight: 700 !important;
}

.PromotionsTable_tableNameVisibleTrue__1-Zoh {
    color: #49BA35 !important;
}

.PromotionsTable_tableNameVisibleFalse__1knWk {
    color: #FF3247 !important;
}

.PromotionsTable_tableNameStatusActive__Et1tz,
.PromotionsTable_tableNameStatusInactive__3-e4a,
.PromotionsTable_tableNameStatusVence__3--Np {
    padding: 4px 8px;
    border-radius: 10px;
}

.PromotionsTable_tableNameStatusActive__Et1tz {
    background: #49BA3573 !important;
}

.PromotionsTable_tableNameStatusInactive__3-e4a {
    background: #80808073 !important;
}

.PromotionsTable_tableNameStatusVence__3--Np {
    background: #FF324773 !important;
}

.PromotionsTable_tableEmail__3cntH {
    font-weight: 700 !important;
}

.PromotionsTable_tableActions__fFUVO {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}


.PromotionsTable_tableActions__fFUVO button,
.PromotionsTable_tableDeskActions__2MlAG button {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
}

.PromotionsTable_tableActions__fFUVO button img,
.PromotionsTable_tableDeskActions__2MlAG button img {
    width: 100% !important;
    max-width: 50px !important;
    max-height: 50px;
}

.PromotionsTable_tableSwitch__2K5fM {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.PromotionsTable_tableDeskActions__2MlAG {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.PromotionsTable_panelHeaderTitle__29pK2{
    font-size: 16px !important;
}

@media screen and (min-width: 450px) {
    .PromotionsTable_accordionContent__1XQml {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .PromotionsTable_accordionContentItem__337vG {
        justify-content: space-between !important;
    }

    .PromotionsTable_accordionTitle__b6_oq {
        font-size: 18px !important;
    }

    .PromotionsTable_accordionContentItemTitle__11D84 {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .PromotionsTable_table__1C4_q {
        display: block !important;
    }

    .PromotionsTable_accordion__2Kw-X,.PromotionsTable_emptyAccordion__2rqJT {
        display: none !important;
    }
}
/* TABLA ANTD */

.ant-table {
  box-sizing: border-box;
  margin: 0px auto !important;
  padding: 0;
  color: rgba(34, 15, 15, 0.562) !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  font-size: 1vw !important;
  background: rgba(7, 3, 3, 0) !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: italic !important;
  font-weight: 700 !important;
}

.ant-table-cell{
  max-width: 10vw !important;
}

.ant-table table {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  text-align: left !important;
  border-radius: 30px !important;
}

.ant-table-thead > tr > th {
  color: rgba(228, 5, 111, 0.45) !important;
  font-weight: 700 !important;
  text-align: left !important;
  background: none !important;
}
.ant-table-tbody > tr > td {
  border: none !important;
  border-radius: 0px 0px 30px 30px !important;
}

.promotionIsVisibleLabel{
  text-align: center;
  background-color: #83C446;
  border-radius: 20px;
  color: white;
}

.promotionIsNotVisibleLabel{
  text-align: center;
  background-color: #E4056F;
  border-radius: 20px;
  color: white;
}

.promotionActiveStatusLabel{
  text-align: center;
  background-color: #83C446;
  border-radius: 20px;
  color: white;
}

.promotionInactiveStatusLabel{
  text-align: center;
  background-color: #808080;
  border-radius: 20px;
  color: white;
}

.promotionExpiredStatusLabel{
  text-align: center;
  background-color: #E4056F;
  border-radius: 20px;
  color: white;
}

.removePromotionModalLabel{
  padding: 20px !important;
  padding-top: 10px !important;
}

.removePromotionModalRowButtons{
  padding-bottom: 20px !important;
}

@media(max-width: 768px){
  .ant-table {
    font-size: 2vw !important;
  }
}

@media(max-width: 400px){
  .ant-table {
    font-size: 3vw !important;
  }
}
.DeletePromotionModal_container__1jPpo {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 200px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.DeletePromotionModal_content__2sPQ2 {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.DeletePromotionModal_closeIcon__245xQ {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.DeletePromotionModal_header__2Oqan {
    margin-bottom: 1rem;
    text-align: left;
}

.DeletePromotionModal_headerTitle__3aovW {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.DeletePromotionModal_headerSubtitle__2s75J {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.DeletePromotionModal_footer__1f9fy {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.DeletePromotionModal_footerButton__3rpPm {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
    margin-top: 20px !important;
}

.DeletePromotionModal_footerButton__3rpPm:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.DeletePromotionModal_footerGhostButton__3ul8b {
    background: #fff !important;
    color: #E4056F !important;
    border: 1px solid #E4056F !important;
}

.DeletePromotionModal_footerGhostButton__3ul8b:hover {
    color: #E4056F !important;
    background: #e4057135 !important;
}


@media screen and (min-width: 500px) {
    .DeletePromotionModal_header__2Oqan {
        text-align: center;
    }

    .DeletePromotionModal_headerTitle__3aovW {
        font-size: 25px;
    }

    .DeletePromotionModal_footerButton__3rpPm {
        max-width: 150px !important;
    }

    .DeletePromotionModal_footerDescription__S5JD5 {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .DeletePromotionModal_closeIcon__245xQ{
        right: 2.5rem;
    }
}
.PromotionsExcelModal-title {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    font-size: 20px;
    padding: 20px  0px;
    width: 100%;
    margin: 0px auto;
  }
  @media (min-width: 1000px) {
    .PromotionsExcelModal-title {
        width: 55%;
      }
}
  .PromotionsExcelModal {
    padding: 50px !important;
    text-align: center !important;
    margin: 0px auto !important;
  }
  .PromotionsExcelModal-content {
    padding: 10px 30px !important;
    text-align: center !important;
  }
  .PromotionsExcelModal-h3{
      padding: 0px 0px 15px 0px;
      font-weight: bolder;
      color: #808080;
  }
/* HEADER BUTTONS */
.row-CreatePromotions {
    width: 85%;
    margin: 0px auto 50px auto;
    border-bottom: 2px solid #b3b3b3;
  }
  
  .h2-CreatePromotions {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-weight: 700 !important;
    font-size: 32px;
  }
  .p-CreatePromotions {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  /* CLOSE HEADER BUTTONS */
.FormHeader_container__2-1Up {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    margin: 0 auto 2rem !important;
}

.FormHeader_header__2pIzH {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    width: 100% !important;
}

.FormHeader_title__ygd5s {
    font-size: 33px !important;
    font-weight: 700 !important;
    margin: 0 !important;
}

.FormHeader_buttonsContainer__1zL2u {
    display: flex !important;
    flex-direction: column !important;
    grid-gap: 15px !important;
    gap: 15px !important;
    align-items: center !important;
    justify-content: center !important;
    position: fixed !important;
    bottom: 1.5rem !important;
    right: 1rem !important;
    z-index: 100 !important;
    width: 60px !important;

}

.FormHeader_button__2AHMw {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
}

.FormHeader_button__2AHMw img {
    width: 100% !important;
}

.FormHeader_buttonGray__K_ik0 {
    display: none !important;
}

.FormHeader_button__2AHMw span {
    display: none !important;
}


@media screen and (min-width: 900px) {
    .FormHeader_container__2-1Up {
        margin-top: -.5rem !important;
    }

    .FormHeader_header__2pIzH {
        flex-direction: row !important;
        justify-content: flex-start !important;
        grid-gap: 10px !important;
        gap: 10px !important;
        width: 40% !important;
    }
}

@media screen and (min-width: 1000px) {

    .FormHeader_buttonsContainer__1zL2u {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        position: static !important;
        max-width: 450px;
        width: 60% !important;
    }

    .FormHeader_button__2AHMw {
        background: #E4056F !important;
        border: none !important;
        border-radius: 24px !important;
        outline: none !important;
        transition: all .5s !important;
        height: 40px !important;
    }

    .FormHeader_button__2AHMw:hover {
        background: #B20457 !important;
    }

    .FormHeader_button__2AHMw img {
        display: none !important;
    }

    .FormHeader_button__2AHMw span {
        color: #fff;
        display: block !important;
        font-weight: 700; 
    }

    .FormHeader_buttonGray__K_ik0 {
        background: #B3B3B3 !important;
        display: inline-block !important;
        padding: 8px !important;
        width: 130px !important;
    }

    .FormHeader_buttonGray__K_ik0:hover {
        background: #808080 !important;
    }

    .FormHeader_buttonPreview__OSeNE {
        background: #FCC02C !important;
        width: 130px !important;
        padding: 8px !important;
    }

    .FormHeader_buttonPreview__OSeNE:hover {
        background: #E5AF28 !important;
    }
}
.FormContent_formContainer__2Lnu9 {
    background: #fff;
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 16px;
    margin-bottom: 3rem;
    padding: 20px 16px;
}

.FormContent_formContainer__2Lnu9:last-of-type{
    margin-bottom: 2rem;
}

.FormContent_formTitle__2oHCJ {
    color: #E4056F;
    font-size: 16px;
    font-weight: 600;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    text-align: center;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .FormContent_formContainer__2Lnu9{
        padding: 30px;
    }
    .FormContent_formTitle__2oHCJ {
        text-align: left;
    }
}
.DetailBoxForm_formContainer__1Ja2X {
    display: flex !important;
    justify-content: space-between !important;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap !important;
    padding-bottom: .7rem !important;
}

.DetailBoxForm_richTextInputContainer__1XRNd {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.DetailBoxForm_richTextInputContainer__1XRNd div div {
    border: none !important;
}

.DetailBoxForm_richTextInputContainer__1XRNd div div[class^="ql-toolbar ql-snow"] {
    border-bottom: 1px solid #ccc !important;
}

.DetailBoxForm_formItem__2Ss-F,
.DetailBoxForm_formItemTextarea__zzMhD,
.DetailBoxForm_formItemUpload__2IdmL {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.DetailBoxForm_formItem__2Ss-F>div:first-child,
.DetailBoxForm_formItemUpload__2IdmL>div:first-child {
    flex: unset !important;
}

.DetailBoxForm_formItem__2Ss-F>div:last-child,
.DetailBoxForm_formItemUpload__2IdmL>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.DetailBoxForm_formItem__2Ss-F>div:last-child,
.DetailBoxForm_formItemTextarea__zzMhD>div:last-child,
.DetailBoxForm_formItemSegment__1kkFv>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.DetailBoxForm_formItemInput__34CNd,
.DetailBoxForm_formItemInputTextarea__2EJPe {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.DetailBoxForm_formItemDiscount__2stR1,
.DetailBoxForm_formItemURL__2Kt6_,
.DetailBoxForm_formItemUpload__2IdmL,
.DetailBoxForm_formItemSegment__1kkFv,
.DetailBoxForm_formItemPublic__2Dtyj,
.DetailBoxForm_formItemVisible__2reyj,
.DetailBoxForm_formItemStatus__2MIbg,
.DetailBoxForm_formItemTextarea__zzMhD {
    width: 100% !important;
}

.DetailBoxForm_formItemInputTextarea__2EJPe {
    box-shadow: none !important;
    padding: 16px !important;
    min-height: 5rem !important;
}

.DetailBoxForm_formItemUpload__2IdmL {
    width: 100% !important;
}

.DetailBoxForm_formItemSegment__1kkFv>div:last-child>div>div>div {
    height: 44px !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
}

.DetailBoxForm_formItemSegment__1kkFv>div:last-child>div>div>div>div {
    background: transparent !important;
    border-radius: 16px !important;
    height: 44px !important;
    width: 100% !important;
    box-shadow: none !important;
}

.DetailBoxForm_formItemInputUpload__2G6qK {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 100% !important;
    position: relative !important;
}

.DetailBoxForm_formItemInputUpload__2G6qK img{
    margin: 5px auto !important;
    width: 75% !important;
    max-width: 125px !important;
}

.DetailBoxForm_formItemUpload__2IdmL>div:last-child {
    width: 100%;
}

.DetailBoxForm_formItemUpload__2IdmL>div:last-child span div {
    height: auto !important;
    width: 100% !important;
}

.DetailBoxForm_formItemInputUploadButton__1dZ2M {
    border: 1px solid #E6E6E6 !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    border-radius: 16px !important;
    padding: 10px !important;
    position: relative;
    padding-right: 40px !important;
    max-width: 100% !important;
    width: 100% !important;
}

.DetailBoxForm_formItemInputUploadButton__1dZ2M img {
    position: absolute;
    right: .8rem;
    top: 25%;
}

.DetailBoxForm_formItemInputUploadButton__1dZ2M input {
    background: transparent !important;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    width: 95% !important;
}

.DetailBoxForm_formItemData__1gnMP .DetailBoxForm_formItemInput__34CNd {
    color: #000 !important;
}


.DetailBoxForm_formItemPublic__2Dtyj>div:last-child>div>div>div>label>span:last-child,
.DetailBoxForm_formItemPublic__2Dtyj>div:last-child>div>div>div>label>span:first-child>span {
    color: #E4056F !important;
    border-color: #E4056F !important;
}

.DetailBoxForm_formItemPublic__2Dtyj>div:last-child>div>div>div>label>span:first-child>span::after {
    background-color: #E4056F !important;
}


.DetailBoxForm_formItemCode__2L9bf>div:last-child input {
    color: #000 !important;
}

.DetailBoxForm_statusSelect__1Xzdp {
    cursor: pointer;
    /* padding: 5px 10px !important; */
    /* max-height: 44px !important; */
    border-radius: 16px;
    width: 100%;
}

.DetailBoxForm_statusSelectActive__1f_h7 {
    border: 1px solid #49BA35 !important;
}

.DetailBoxForm_statusSelectInactive__1GxlE {
    border: 1px solid #ff0000 !important;
}

.DetailBoxForm_statusSelect__1Xzdp div {
    border: none !important;
    outline: none !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 44px !important;
    margin-right: auto !important;
}

.DetailBoxForm_statusSelectActive__1f_h7 span,
.DetailBoxForm_statusSelectActive__1f_h7>div>span {
    color: #49BA35 !important;
    font-weight: bold !important;
}

.DetailBoxForm_statusSelectInactive__1GxlE>div>span,
.DetailBoxForm_statusSelectInactive__1GxlE span {
    color: #ff0000 !important;
    font-weight: bold !important;
}



@media screen and (max-width: 600px) {
    .DetailBoxForm_formItemCode__2L9bf {
        width: 47% !important;
        order: -8 !important;
    }

    .DetailBoxForm_formItemStatusEditing__EXGZc {
        order: -7 !important;
        width: 47% !important;
    }
    .DetailBoxForm_formItemStatusCreating__3l419{
        order: -10 !important;
    }

    .DetailBoxForm_formItemURL__2Kt6_ {
        order: -6 !important;
    }

    .DetailBoxForm_formItemName__mnpo0 {
        order: -4 !important;
    }

    .DetailBoxForm_formItemPartnerNameEdit__3oEag {
        order: -5 !important;
    }

    .DetailBoxForm_formItemTextarea__zzMhD{
        order: -3 !important;
    }

    .DetailBoxForm_formItemPublicEditing__1Z1Cc {
        order: -2 !important;
        width: 190px !important;
    }

    .DetailBoxForm_formItemDiscountEditing__38qk9 {
        order: -1 !important;
        width: 30% !important;
    }

}

@media screen and (min-width: 601px) {
    .DetailBoxForm_formItemName__mnpo0 {
        width: 48% !important;
    }

    .DetailBoxForm_formItemSegment__1kkFv,
    .DetailBoxForm_formItemVisible__2reyj {
        width: 31% !important;
    }

    .DetailBoxForm_formItemUpload__2IdmL,
    .DetailBoxForm_formItemPublic__2Dtyj,
    .DetailBoxForm_formItemSegment__1kkFv {
        width: 45% !important;
    }

    .DetailBoxForm_formItemDiscount__2stR1,
    .DetailBoxForm_formItemURL__2Kt6_,
    .DetailBoxForm_formItemStatus__2MIbg {
        width: 30% !important;
    }

    .DetailBoxForm_formItemData__1gnMP {
        width: 30% !important;
    }

    .DetailBoxForm_formItemCode__2L9bf {
        width: 13% !important;
    }

    .DetailBoxForm_formItemNameEdit__2WP86 {
        width: 40% !important;
    }
}

@media screen and (min-width: 900px) {

    .DetailBoxForm_formItemUpload__2IdmL {
        width: 30% !important;
    }

    .DetailBoxForm_formItemPublic__2Dtyj {
        width: 30% !important;
        /* max-width: 190px !important; */
    }

    .DetailBoxForm_formItemSegment__1kkFv,
    .DetailBoxForm_formItemDiscount__2stR1,
    .DetailBoxForm_formItemURL__2Kt6_,
    .DetailBoxForm_formItemStatus__2MIbg {
        width: 20% !important;
    }

    .DetailBoxForm_formItemSegment__1kkFv,
    .DetailBoxForm_formItemStatus__2MIbg {
        width: 25% !important;
    }

    .DetailBoxForm_formItemNameEdit__2WP86 {
        width: 40% !important;
    }
}
.LifeBoxForm_formContainer__2vyiK {
    display: flex !important;
    justify-content: space-between !important;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap !important;
    padding: 1rem .5rem 2rem;
}

.LifeBoxForm_formItem__22D8B {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.LifeBoxForm_formItem__22D8B>div:first-child {
    flex: unset !important;
}

.LifeBoxForm_formItem__22D8B>div:last-child {
    display: unset !important;
    flex: unset !important;
    width: 100% !important;
}

.LifeBoxForm_formItemDate__2ks_r>div:last-child {
    display: unset !important;
    flex: unset !important;
    width: 100% !important;
    /* border: 1px solid #E6E6E6 !important; */
    border-radius: 16px !important;
}

.LifeBoxForm_formItemDate__2ks_r>div:last-child div div div {
    border-radius: 16px !important;
    height: 44px !important;
    width: 100% !important;
}

.LifeBoxForm_formItemDate__2ks_r>div:last-child div div div div span {
    color: #E4056F !important;
}

.LifeBoxForm_daysContainer__2JDTn {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    grid-gap: 15px !important;
    gap: 15px !important;
    width: 100% !important;
    overflow: auto !important;
    padding: 10px !important;
}

.LifeBoxForm_daysContainer__2JDTn::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

/* background of the scrollbar except button or resizer */
.LifeBoxForm_daysContainer__2JDTn::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
.LifeBoxForm_daysContainer__2JDTn::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 16px;
    border: 4px solid #fff;
    background: transparent !important;
}

/* set button(top and bottom of the scrollbar) */
.LifeBoxForm_daysContainer__2JDTn::-webkit-scrollbar-button {
    display: none;
}


.LifeBoxForm_dayButton__24jU_ {
    background: #fff;
    border: none !important;
    border-radius: 32px !important;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 7px;
    gap: 7px;
    outline: none !important;
    padding: 8px 16px !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    transition: all .5s;
}

.LifeBoxForm_dayButton__24jU_:hover {
    background: rgba(238, 238, 238, 0.3);
}
.LifeBoxForm_dayButton__24jU_:active {
    background: rgba(238, 238, 238, 0.5);
}

.LifeBoxForm_dayButtonText__3Ek1t {
    font-weight: 400 !important;
}

.LifeBoxForm_dayButtonCheck__2UWrj {
    color: #E4056F !important;
    font-size: 16px;
}

.LifeBoxForm_dayButtonUnchecked__2i5GJ {
    width: 16px;
    height: 16px;
    border: 2px solid #E4056F;
    border-radius: 100%;
}

.LifeBoxForm_daysSwitchContainer__3edui {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.LifeBoxForm_daysSwitchText__10564 {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    margin-left: -6rem;
}



@media screen and (min-width: 500px) {
    .LifeBoxForm_daysSwitchContainer__3edui {
        width: 275px !important;
        margin: auto;
    }
}

@media screen and (min-width: 768px) {
    .LifeBoxForm_daysContainer__2JDTn {
        padding-bottom: 1rem;
    }

    /* scrollbar itself */
    .LifeBoxForm_daysContainer__2JDTn::-webkit-scrollbar-thumb {
        background: #ccc !important;
    }
}

@media screen and (min-width: 800px) {
    .LifeBoxForm_formItemDate__2ks_r {
        width: 47% !important;
    }

    .LifeBoxForm_daysContainer__2JDTn {
        justify-content: space-between !important;
    }

    .LifeBoxForm_daysSwitchContainer__3edui {
        cursor: pointer;
        margin: 0 !important;

    }

}

@media screen and (min-width: 1100px) {
    .LifeBoxForm_formContainer__2vyiK {
        padding-bottom: 1rem !important;
    }

    .LifeBoxForm_daysSwitchContainer__3edui {
        margin-right: auto !important;
        width: 260px !important;
    }
    .LifeBoxForm_daysSwitchText__10564{
        margin-left: -10rem !important;
    }

    .LifeBoxForm_formItemDate__2ks_r {
        width: 30% !important;
    }

    .LifeBoxForm_formItemDate__2ks_r:first-of-type {
        margin-left: auto !important;
    }
}
.CategoryBoxForm_formContainer__PnW5Z {
    display: flex !important;
    justify-content: space-between !important;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap !important;
    padding-bottom: 1rem !important;
}

.CategoryBoxForm_formItem__eIJox,
.CategoryBoxForm_formItemCommunes__dUC_l {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.CategoryBoxForm_formItem__eIJox>div:first-child,
.CategoryBoxForm_formItemCommunes__dUC_l>div:first-child {
    flex: unset !important;
}

.CategoryBoxForm_formItem__eIJox>div:last-child,
.CategoryBoxForm_formItemCommunes__dUC_l>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.CategoryBoxForm_formItem__eIJox>div:last-child,
.CategoryBoxForm_formItemCommunes__dUC_l>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.CategoryBoxForm_formItemCommunes__dUC_l {
    cursor: pointer;
    width: 100% !important;
}

.CategoryBoxForm_formItemInputCommunes__3rS2C {
    border: 1px solid #E6E6E6;
    border-radius: 16px !important;
    padding: 6px 10px !important;
    width: 100%;
}

.CategoryBoxForm_formItemInputCommunes__3rS2C div {
    border: none !important;
    box-shadow: none !important;
}

.CategoryBoxForm_categorySelect__YYxEc {
    border: 1px solid #E6E6E6;
    cursor: pointer;
    padding: 5px 10px !important;
    max-height: 44px !important;
    border-radius: 16px;
    width: 100%;
}

.CategoryBoxForm_categorySelect__YYxEc div {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: 90% !important;
    margin-right: auto !important;
}

.CategoryBoxForm_categorySelect__YYxEc>span {
    color: #E4056F !important;
}

.CategoryBoxForm_categoryOption__1tmej {
    border-top: 1px solid #E6E6E6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    padding: 10px 10px !important;
}

.CategoryBoxForm_categoryOptionText__2ZJqn {
    font-weight: bold !important;
    color: #000 !important;
    font-size: 16px !important;
    display: inline-block !important;
    margin: auto !important;
    transform: translateY(2px) !important;
}

.CategoryBoxForm_categoryOptionIcon__3qKia {
    width: 20px !important;
    max-height: 20px !important;
    margin-right: 7px !important;
}

@media screen and (min-width: 600px) {

    .CategoryBoxForm_formItemCommunes__dUC_l,
    .CategoryBoxForm_formItemCategory__1kdVj {
        width: 48% !important;
    }
}
.ConditionsBoxForm_formContainer__2lWle {
    display: flex !important;
    justify-content: space-between !important;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap !important;
}

.ConditionsBoxForm_richTextInputContainer__10qHr {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.ConditionsBoxForm_richTextInputContainer__10qHr div div {
    border: none !important;
}

.ConditionsBoxForm_richTextInputContainer__10qHr div div[class^="ql-toolbar ql-snow"] {
    border-bottom: 1px solid #ccc !important;
}

.ConditionsBoxForm_formItem__1Jiam,
.ConditionsBoxForm_formItemTextarea__2q1UD {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.ConditionsBoxForm_formItem__1Jiam>div:first-child {
    flex: unset !important;
}

.ConditionsBoxForm_formItem__1Jiam>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.ConditionsBoxForm_formItem__1Jiam>div:last-child,
.ConditionsBoxForm_formItemTextarea__2q1UD>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.ConditionsBoxForm_formItemInputTextarea__le1tX {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.ConditionsBoxForm_formItemTextarea__2q1UD {
    width: 100% !important;
}

.ConditionsBoxForm_formItemInputTextarea__le1tX {
    box-shadow: none !important;
    padding: 16px !important;
    min-height: 4rem !important;
}
.PreviewPromotionModal_modal__ooQI4 {
  max-width: 900px !important;
}

.PreviewPromotionModal_modal__ooQI4 div:nth-child(2) {
  border-radius: 16px !important;
}

.PreviewPromotionModal_modalCloseButton__1WOj0 {
  font-size: 24px !important;
  background: transparent !important;
  border-radius: 100% !important;
  padding: 5px !important;
  color: #fff !important;
  font-weight: bold !important;
  margin: 1rem 3rem 0 0 !important;
  transition: all 0.5s !important;
}

.PreviewPromotionModal_modalCloseButton__1WOj0:hover {
  color: #eee !important;
}

.PreviewPromotionModal_container__1d-Br {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.PromotionHeader_header__EVpCs {
    background-position: center !important;
    background-size: cover !important;
    border-radius: 16px 16px 0 0 !important;
    min-height: 10rem !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    padding-left: 5% !important;
  }
  
  .PromotionHeader_headerLogo__10JOY {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(50%);
    width: 10rem;
    z-index: 10;
  }
  
  @media screen and (min-width: 768px) {
    .PromotionHeader_header__EVpCs {
      height: 200px !important;
      padding-left: 10% !important;
    }
  }
  
.PromotionTerms_collapse__1ybjz {
    max-width: 750px;
    margin: 1rem auto 0 !important;
    width: 90%;
}

.PromotionTerms_collapse__1ybjz div div {
    padding: 0 !important;
}

.PromotionTerms_collapse__1ybjz div div:last-of-type {
    padding: 1rem 0 !important;
}

.PromotionTerms_collapseTitle__GGOnd {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    margin: 0 !important;
}

.PromotionTerms_collapseDescription__2Go8s {
    color: #808080;
    font-weight: normal;
    font-size: 13px;
}
.PromotionFooter_footer__1R0Gz {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 2rem;
    width: 100%;
}

.PromotionFooter_footerText__2OnTS {
    color: #808080;
    font-weight: 600;
    font-size: 16px;
    margin: 0 !important;
}

.PromotionFooter_footerLogo__1bdsb {
    margin-left: 5px;
    max-width: 150px;
    max-height: 75px;
}
.PromotionInfo_container__1Kltv {
  padding: 0 5%;
  padding-top: 5.5rem !important;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.PromotionInfo_info__3rrjS {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;
}

.PromotionInfo_infoTitle__9kYFY {
  font-weight: 900;
  font-size: 28px;
  color: #333333;
  margin: 0 !important;
  width: 100%;
}

.PromotionInfo_infoPromotionName__1bqhR {
  font-weight: 500;
  font-size: 16px;
  color: #e4056f;
  margin: 0 !important;
  text-wrap: balance;
  width: 100%;
}

.PromotionInfo_infoDiscount__1NhYZ {
  color: #e4056f;
  font-size: 17px;
  margin: 0 !important;
  width: 100%;
}

.PromotionInfo_infoRating__1vSYj {
  width: 100%;
}

.PromotionInfo_infoRatingStars__3AVgC {
  font-size: 30px !important;
}

.PromotionInfo_infoDescription__ohQg3 {
  color: #333333;
  font-size: 15px;
  margin: 1rem 0 1.5rem !important;
  width: 100%;
}

.PromotionInfo_infoConditions__2woI1 {
  font-size: 16px;
  width: 100%;
}

.PromotionInfo_infoConditionsTitle__2lyFT {
  font-weight: 600;
}

.PromotionInfo_infoConditionsDescription__2CS5r {
  font-weight: 400;
  padding-left: 1rem;
  width: 100%;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.PromotionInfo_infoCommunes__737sl {
  color: #b20457;
  font-weight: 600;
  font-size: 16px;
  text-wrap: balance;
  margin: 0.5rem 0 1rem;
  width: 100%;
}

.PromotionInfo_infoCommunes__737sl svg {
  font-size: 17px !important;
  margin-right: 5px;
}

.PromotionInfo_promotionType__2gXwH {
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  grid-gap: 8px;
  gap: 8px;
  margin-left: auto;
}

.PromotionInfo_promotionTypeLeftSide__2tEuo {
  margin-left: 0;
  margin-bottom: 1rem;
  margin-right: auto;
}

.PromotionInfo_promotionTypeIcon__Bl2eC {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .PromotionInfo_promotionTypeRightSide__NcG-9 {
    display: none !important;
  }
  .PromotionInfo_infoConditionsDescription__2CS5r {
    padding-left: 0;
  }
}

.PromotionInfo_promotionTypeLabel__3mMus {
  font-weight: 700;
  font-size: 10px;
}

.PromotionInfo_dates__346P3 {
  background-color: #e6e6e6;
  border-radius: 20px;
  padding: 20px 25px 25px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0.5rem auto;
  max-width: 325px;
  width: 100%;
}

.PromotionInfo_datesTitle__HEdtr {
  font-weight: 700;
  font-size: 19px;
  color: #000;
  margin-bottom: 0 !important;
}

.PromotionInfo_datesDescription__li0D9 {
  font-weight: normal;
}

.PromotionInfo_buttonBenefitURL__1wxE1 {
  background: #e4056f !important;
  border: none !important;
  border-radius: 16px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  margin-top: 1.5rem !important;
  height: 32px !important;
}

.PromotionInfo_buttonBenefitURL__1wxE1 p {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.PromotionInfo_buttonBenefitURL__1wxE1:hover {
  background: #b20457 !important;
}

.PromotionInfo_buttonBenefitURL__1wxE1:active {
  background: #e4056f !important;
}

.PromotionInfo_infoReviewsMessage__2qgGh {
  text-align: center;
  width: 100%;
  color: #e40571;
  font-size: 14px;
  margin: 0 !important;
  font-weight: 500;
  text-wrap: balance;
}

.PromotionInfo_infoReviewsMessageLink__2B3T6 {
  color: #e40571;
  display: inline-block;
  font-weight: 700 !important;
}

.PromotionInfo_infoReviewsMessageLink__2B3T6:first-of-type {
  margin-right: 4px;
}

.PromotionInfo_infoReviewsMessageLink__2B3T6:last-of-type {
  margin: 0 4px;
}

.PromotionInfo_infoReviewsMessageLink__2B3T6:hover {
  color: #e40571 !important;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .PromotionInfo_container__1Kltv {
    padding: 0 10%;
    grid-template-columns: 60% 40%;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .PromotionInfo_dates__346P3 {
    margin: 1.5rem auto 0.5rem;
  }
  .PromotionInfo_promotionTypeLeftSide__2tEuo {
    display: none;
  }
  .PromotionInfo_infoCommunes__737sl {
    margin: 1rem 0 !important;
  }
}

/* ------------------------------------------------------ */
/*    Los estilos de este archivo NO se están usando      */
/*    en ModalPromotion pero al ser estilos globales      */
/*    están teniendo side effects en otros sitios de      */
/*    la app, por lo cual igual se importaron hacia       */
/*    el componente ModalPromotion para que estén         */
/*    disponibles de manera global.                       */
/* ------------------------------------------------------ */

.ant-modal-content{
   border-radius: 25px !important;
}

.closeModalButton{
    font-size: 25px !important;
    font-weight: bold !important;
    padding-top: 15px !important;
    background-color: #E4056F !important;
    border-top-right-radius: 10px !important;
    opacity: 0.7 !important;
    margin-right: -14px !important;
    display: none !important;
}

.modal-header{
    background-color: #E4056F;
    border-radius: 20px 20px 0px 0px;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
.modal-header button{
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
}


.closeModalButton:hover{
    opacity: 1 !important;
}

.background-modal{
    background-image: url(https://thumbs.dreamstime.com/b/verduras-anchas-de-la-foto-en-fondo-madera-133445680.jpg);
    background-repeat: no-repeat;
    background-size: cover !important;
    /* border-radius: 25px !important; */
    object-fit: contain;
    text-align: center;
    height: auto !important;
    width: 100% !important;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.ant-modal-body{
    padding: 0px!important;
}

.hart-img{
    position: absolute;
    width: 25px;
    top: 18px !important;
    right: 8% !important;
}

.modal-back-title{
    max-width: 60% !important;
    background-color: #E4056F;
    margin-bottom: 3rem;
    border-radius: 50px;
    margin: 10vh auto 8vh auto;
}

.modal-margin-title{
    padding: 10px;
    font-weight: 700;
    color: white;
}

.modal-display-subtitle{
    display: flex;
    justify-content: space-around;
    padding-top: 40px !important; 
}

.modal-back-info{ 
    width: 50%;
    padding: 0px 20px;
}

.modal-back-date{
    background-color: #E4056F;
    border-radius: 20px;
    padding-left: 30px;
    padding-top: 15px;
    padding-right: 30px;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight:700;
    max-width: 40%;
    margin-bottom: 20px;
    max-height: 15rem;
}


.modal-display-title{
    display: flex;
    justify-content: space-evenly;

}

.modal-logo{
    width: 120px !important;
    height: 120px !important;
    background-position: center;
    background-size: contain;
    border-radius: 20px;
}

.modal-h2-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #E4056F;
}

.modal-h3-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
}

.modal-p-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-list-info{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #00000057;
}

.modal-h3-date{
    font-weight:700;
    font-size: 20px;
    color: #FFFFFF;
}

.model-p-date{
    font-size: 15px;
}
.model-p-date a{
    color:  #FCC02C;
}
.model-p-date a:hover{
    color:  #FCC02C;
}


.modal-display-commit{
    display: none;   
}
  
  .modal-h3-commit{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
    max-width: 300px; text-align: center;
  }
  
 .modal-h3-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
 }
 .modal-p-footer{
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: #0000009f;
 }

 .modal-footer{
     padding: 30px;
     background-color:#d1d1d1;
     border-radius: 0px 0px 25px 25px;
 }

@media (max-width: 1024px) {
    .hart-img{
        right: 15% !important
    }

    .modal-margin-title{
        font-size: 25px !important;
    }

    .modal-back-date{
       max-width: 70%;
        height: 15rem ;
    }
    .modal-h2-info{
        text-align: center;
        padding-top: 20px;
    }
}


@media (max-width: 800px) {
    .hart-img{
        right: 20% !important
    }

    .modal-back-title{
        max-width: 70% !important;
        border-radius: 40px;
        margin-top: 60px !important;
    }

    .modal-margin-title{
        font-size: 20px !important;
    }

    .modal-display-subtitle{
        display:inline;
    }

    .modal-back-info{ 
        max-width: 100% !important;
        width: 100% !important;
    
    }

    .modal-back-date{
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media (max-width: 450px) {
    .model-p-date{
        font-size: 13px;
    }
    .hart-img{
        right: 25% !important;
    }

    .modal-back-title{
        max-width: 90% !important;
        border-radius: 20px !important;
    }

    .modal-margin-title{
        font-size: 15px !important;
    }

    .modal-back-date{
        height: 19rem !important;   
    }

    .modal-h2-info{
        font-size: 25px;
    }
    
    .modal-h3-info{
        font-size: 17px;
    }
    
    .modal-p-info{
        font-size: 13px;
    }
    .modal-list-info{
        font-size: 13px;
    }
}
 

.ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.7) !important;
}
/* GLOBAL STYLES */
#form-promotions {
  width: 100% !important;
  margin: 0px auto !important;
}
.form-border {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  padding: 10px 10px;
  margin-bottom: 20px;
}
.form-description {
  color: #e4056f !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 600;
  padding: 0px 0px 10px 0px !important;
}
/* CLOSE GLOBAL STYLES */

/* INPUTS  */
.promotion-form-input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 50px !important;
  width: 85% !important;
}

.formSelectSegment{    
  text-align: center;
  border-radius: 5px !important;
  height: auto !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: solid rgba(0, 0, 0, 0.15) 1px;
  width: 200px !important;
  }

@media (max-width: 1000px) {
  .promotion-form-input {
    width: 100% !important;
  }
}
.promotion-form-input:hover {
  border-color: #ee84b7 !important;
}
/* ANT style LABEL*/
.ant-form-item-label > label {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
/* CLOSE ANT style */
.promotion-form-inputTextarea {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
  border-radius: 20px !important;
  width: 85% !important;
  padding: 12px 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-inputTextarea {
    width: 100% !important;
  }
}
.promotion-form-inputTextarea:hover {
  border-color: #ee84b7 !important;
}
.promotion-form-input-localLogo {
  width: 40% !important;
  padding: 35px 0px !important;
  border-radius: 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-input-localLogo {
    width: 70% !important;
  }
}
/* CLOSE INPUTS */

/* BUTTONS OF STATE */
.buttonForm-createPromotions {
  border-radius: 30px !important;
  width: 100% !important;
  height: 45px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: white !important;
  min-width: 60px;
  font-size: 12px !important;
  padding: 0px !important;
}
.activate-button {
  background-color: #83c446 !important;
}
.inactive-button {
  background-color: #808080 !important;
}
.expire-button {
  background-color: #e4056f !important;
}
.preview-button {
  background-color: #fcc02c !important;
}
/*CLOSE BUTTONS OF STATE */

/* DATE SECTION ----> */
@media (min-width: 1000px) {
  .week-row {
    justify-content: space-between !important;
  }
}
.padding-col-CreatePromotions {
  padding: 0px 5px 10px 5px;
}
.week-button {
  background-color: #e4056f !important;
  border-radius: 30px !important;
  width: 100% !important;
  height: 30px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: white !important;
  min-width: 60px;
  font-size: 12px !important;
  padding: 0px !important;
}
.text-CreatePromotions {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
}
.swicth-iten-CreatePromotions {
  margin-left: 20px !important;
}
.displayFlex-switchs{
  display: flex;
}
.marginLeft-Switch{
  margin-left: 20px;
}
@media (max-width: 800px) {
  .displayFlex-switchs{
    display: inline-block;
  }
  .marginLeft-Switch{
    margin-left: 0px;
  }
}
/* ANT style SWITCH */
.ant-switch-checked {
  background-color: black !important;
}
/* CLOSE ANT style */
.datepicker-CreatePromotions {
  width: 80% !important;
  border-radius: 30px !important;
  padding: 10px !important;
}
.datepicker-CreatePromotions:hover {
  border-color: #ee84b7 !important;
}
.ant-picker-focused {
  border-color: #ee84b7 !important;
  box-shadow: 0px 0px 2px #e4056f !important;
}
/* CLOSE DATE SECTION ----->*/

/* LOCATIONS SECTION */
.promotion-form-inputLocation {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  border-radius: 50px !important;
  width: 85% !important;
  padding: 10px !important;
}
@media (max-width: 1000px) {
  .promotion-form-inputLocation {
    width: 100% !important;
  }
}
.promotion-form-inputLocation:hover {
  border-color: #ee84b7 !important;
}
/* CLOSE LOCATIONS SECTION */

/* SEGMENTS SECTION */
.col-img-CreatePromotions {
  padding: 8px;
  border-radius: 10px;
  min-width: 70px;
  max-height: 50px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
}
.row-Segments {
  justify-content: space-between !important;
  width: 100% !important;
  text-align: center !important;
  margin-bottom: -50px;
}
.background-yellow {
  background-color: #fcc02c;
}
.background-pink {
  background-color: #e4056f;
}
.segments-text-CreatePromotions {
  max-width: 100%;
  color: #000000 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
/* CLOSE SEGMENTS SECTION */

/* CONDITIONS SECTIONS */
.conditionsText-form {
  max-width: 100%;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  background-color: white;
  padding: 30px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  text-align: justify;
  margin-top: 10px;
}
/* CLOSE CONDITIONS SECTIONS */

/* APPEAR AND DISAPPEAR BUTTONS */
.disappearButtonsh-footer{
  width: 100%!important;
justify-content: space-between !important;
}
@media (max-width: 1050px) {
  .disappearButtonsh-hedear
  {
display: none !important;
  }
}
@media (min-width: 1050px) {
  .disappearButtonsh-footer
  {
display: none !important;
  }
}
/* CLOSE APPEAR AND DISAPPEAR BUTTONS */
.companyPromotionsLabel{
  font-weight: bold;
  font-style: italic;
  padding-left: 40px;
}

.companyPromotionsTopButtonsContainer{
  margin-left: 40px;
  margin-bottom: 40px;
}

.companyPromotionsTopButton{
  border: none !important;
  font-style: italic;
  background-color: transparent !important;
  box-shadow: transparent !important;
}
.Header_container__3xk2E {
  margin-bottom: 2rem;
}

.Header_header__2Xyjh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.Header_headerTitle__3z1dw {
  font-size: 20px;
  font-weight: 700;
  margin: 0 !important;
  text-align: center;
  width: auto;
}

.Header_headerButton__1zSBX {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.5s;
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  width: 60px;
  z-index: 100;
}

.Header_headerPromotionsFiltersButton__2rPfS {
  background: #fcc02c;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 24px;
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.Header_headerPromotionsFiltersButton__2rPfS:hover {
  background: #e5af28;
}

.Header_headerButton__1zSBX img {
  width: 100%;
}

.Header_buttonGray__3VpRL {
  display: none;
}

.Header_searchInputsContainer__lr_SS {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
  align-items: flex-end !important;
  grid-gap: 1rem !important;
  gap: 1rem !important;
  width: 100%;
}

.Header_searchByInputContainer__3QRYU,
.Header_searchValueInputContainer__1ukLr {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  width: 100%;
}

.Header_searchByInputContainer__3QRYU {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  width: 40% !important;
}

.Header_searchByInputContainer__3QRYU div {
  text-align: left !important;
  width: 100% !important;
}

.Header_searchByInputContainer__3QRYU > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
}

.Header_searchValueInputContainer__1ukLr input {
  height: 44px !important;
  border-radius: 10px !important;
}

.Header_searchByInput__1X7II > div {
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
  width: 100% !important;
}

.Header_headerButton__1zSBX span {
  display: none;
}

.Header_listToggle__3pY1a {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.Header_listToggleButton__20jT0 {
  background: transparent !important;
  color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  transition: all 0.5s;
}

.Header_listToggleButtonActive__2Efi7 svg,
.Header_listToggleButtonActive__2Efi7 span {
  transition: all 0.5s;
  color: #e4056f;
  fill: #e4056f;
}

.Header_desktopPromotionsFilters__29QJ3 {
  display: none;
}

@media screen and (min-width: 1024px) {
  .Header_desktopPromotionsFilters__29QJ3 {
    display: block;
  }
  .Header_searchInputsContainer__lr_SS,
  .Header_headerPromotionsFiltersButton__2rPfS {
    display: none !important;
  }
  .Header_headerTitle__3z1dw {
    font-size: 34px;
  }
  .Header_headerButton__1zSBX {
    background: #e4056f;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    outline: none;
    padding: .6rem 1rem;
    transition: all 0.5s;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: .5rem;
    gap: .5rem;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .Header_headerButton__1zSBX:hover {
    background: #b20457;
  }

  .Header_headerButtonPlusIcon__3KwV6 {
    font-size: 1rem;
  }

  .Header_headerButton__1zSBX img {
    width: 30px;
  }

  .Header_headerButtonPlusImage__bABPL {
    display: none !important;
  }

  .Header_headerButton__1zSBX span {
    color: #fff;
    display: block;
    font-weight: 700;
  }
}

@media screen and (min-width: 768px) {
  .Header_container__3xk2E {
    background-color: #fff !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    padding: 1.5rem 1rem 1rem !important;
    border-radius: 16px !important;
  }
  .Header_header__2Xyjh {
    margin-top: -0.5rem;
  }
  .Header_listToggle__3pY1a {
    justify-content: flex-start;
  }

  .Header_headerTitle__3z1dw {
    text-align: left;
    width: 40%;
  }

  .Header_buttonGray__3VpRL {
    background: #b3b3b3;
    display: inline-block;
    padding: 8px 15px;
    margin-left: auto;
    margin-right: 20px;
    width: 150px;
  }

  .Header_buttonGray__3VpRL:hover {
    background: #505050;
  }
}

.PromotionsFiltersModal_container__3gSJm {
  padding: 2rem 1.5rem;
}

.PromotionsFiltersModal_formItemsContainer__2eiCT {
  display: grid !important;
  grid-template-columns: 120px 1fr !important;
  align-items: end !important;
  grid-gap: 1rem !important;
  gap: 1rem !important;
  align-content: flex-end !important;
  padding-right: .5rem !important;
  width: 100%;
}

.PromotionsFiltersModal_formItem__WW1Ne {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;
}

.PromotionsFiltersModal_filterByFormItem__1z8jE {
  width: 200px !important;
}

.PromotionsFiltersModal_formItem__WW1Ne div {
  text-align: left !important;
  width: 100% !important;
}

.PromotionsFiltersModal_formItem__WW1Ne > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
}

.PromotionsFiltersModal_formItem__WW1Ne input {
  height: 44px !important;
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
}

.PromotionsFiltersModal_selectInputWithLabel__3_HkB > div,
.PromotionsFiltersModal_selectInputForValue__2bphB > div {
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
  /* width: 100% !important; */
}

.PromotionsFiltersModal_searchInputsContainer__CaPm9 {
  display: none !important;
}

.PromotionsFiltersModal_filterInputsContainer__1mk30 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.PromotionsFilters_container__35StU {
  margin-top: 2rem;
}

.PromotionsFilters_formItemsContainer__2Xgln {
  display: grid !important;
  grid-template-columns: 120px 1fr !important;
  align-items: end !important;
  grid-gap: 1rem !important;
  gap: 1rem !important;
  align-content: flex-end !important;
  width: 100%;
}

.PromotionsFilters_formItem__3Gybw {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;
}

.PromotionsFilters_filterInputsContainer__2hv-l {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 0.5rem !important;
}

.PromotionsFilters_formItem__3Gybw div {
  text-align: left !important;
  width: 100% !important;
}

.PromotionsFilters_formItem__3Gybw > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
  padding-bottom: 0.8rem !important;
}

.PromotionsFilters_formItem__3Gybw input {
  height: 44px !important;
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
}

.PromotionsFilters_selectInputWithLabel__2SK4v > div,
.PromotionsFilters_selectInputForValue__EC1ld > div {
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
}

.PromotionsFilters_footer__1ALgg {
  padding: 0 0.5rem;
  margin-top: 1rem;
}

.PromotionsFilters_footer__1ALgg button {
  background: #fcc02c;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 24px;
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100% !important;
  max-width: 200px;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-size: 1rem;
  margin: auto;
  font-weight: 700;
  margin-top: auto;
}

.PromotionsFilters_footer__1ALgg button:hover {
  background: #e5af28;
}

.PromotionsSeenByUsersBox_container__2vBwp {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  padding-bottom: 1rem;
  margin-bottom: 4rem;
}

.PromotionsSeenByUsersBox_containerBlurred__2tQnI {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.PromotionsSeenByUsersBox_blurredImage__1_Evg {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.PromotionsSeenByUsersBox_title__2lHob {
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.PromotionsSeenByUsersBox_statsVisualizations__1G0Ev {
  color: #e4056f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  padding: 1.5rem 2.5rem;
  /* width: 100%; */
}

.PromotionsSeenByUsersBox_statsVisualizations__1G0Ev span {
  display: inline-block;
  margin-right: 5px;
  font-size: 33px;
  font-weight: 700;
}

.PromotionsSeenByUsersBox_stats__2hQ8t {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
}

.PromotionsSeenByUsersBox_noData__3y_38 {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .PromotionsSeenByUsersBox_statsVisualizations__1G0Ev {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .PromotionsSeenByUsersBox_title__2lHob {
    padding: 1.5rem 5rem;
  }

  .PromotionsSeenByUsersBox_statsVisualizations__1G0Ev {
    margin: 1rem auto;
  }

  .PromotionsSeenByUsersBox_statsVisualizations__1G0Ev {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 1200px) {
  .PromotionsSeenByUsersBox_title__2lHob {
    padding: 1.2rem 3rem;
  }
  .PromotionsSeenByUsersBox_stats__2hQ8t {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 1700px) {
  .PromotionsSeenByUsersBox_stats__2hQ8t {
    justify-content: space-between;
  }
}

.PromotionsSeenByAge_container__2mysH {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.PromotionsSeenByAge_header__1Nlj4 {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.PromotionsSeenByAge_header__1Nlj4 h3 {
  color: black;
  font-size: 13px !important;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.card-ReportScreen {
    color: #808080;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
  }
.ActivePromotionsBox_container__1xVRi {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    padding-bottom: 3rem;
    margin-bottom: 4rem;
}

.ActivePromotionsBox_title__QSNP_ {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 1.5rem 2.5rem;
    width: 100%;
}

.ActivePromotionsBox_subTitle__2c5ms {
    color: #E4056F;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
    /* width: 100%; */
}

.ActivePromotionsBox_subTitle__2c5ms span {
    display: inline-block;
    margin-right: 5px;
    font-size: 33px;
    font-weight: 700;
}

.ActivePromotionsBox_promoTypes__1uKzI {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    overflow-x: auto !important;
    padding-bottom: 1rem !important;
    margin: auto;
    width: 80%;
}

.ActivePromotionsBox_promoTypes__1uKzI::-webkit-scrollbar {
    background: transparent !important;
    width: 1px;
}

.ActivePromotionsBox_promoTypes__1uKzI::-webkit-scrollbar-button {
    display: none;
}

.ActivePromotionsBox_promoTypes__1uKzI::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: none !important;
    background: transparent !important;
}

.ActivePromotionsBox_promoTypes__1uKzI::-webkit-scrollbar-track {
    background-color: transparent;
}


.ActivePromotionsBox_promoTypesButton__3gq9O {
    background: #fff;
    border-radius: 32px;
    border: none;
    box-shadow: 0px 4px 10px 0px #0000001A;
    color: #E4056F;
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 500;
    min-width: -moz-fit-content;
    min-width: -webkit-fill-available;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    outline: none;
    padding: 12px 24px;
    transition: all .3s;
}

.ActivePromotionsBox_promoTypesButton__3gq9O:hover,
.ActivePromotionsBox_promoTypesButtonActive__2H1JT {
    background: #E4056F;
    color: #fff;
}


.ActivePromotionsBox_stats__1kkvj {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 2.5rem;
    flex-wrap: wrap;
}

.ActivePromotionsBox_statsItem__3p0qq {
    background: #49BA35;
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    height: 105px;
    width: 115px;
}

.ActivePromotionsBox_statsItem__3p0qq:nth-child(2) {
    background: #E4056F;
}

.ActivePromotionsBox_statsItem__3p0qq:nth-child(3) {
    background: #B3B3B3;
}

.ActivePromotionsBox_statsItem__3p0qq:nth-child(4) {
    background: #333333;
}

.ActivePromotionsBox_statsItem__3p0qq:nth-child(5) {
    background: #B20457;
}

.ActivePromotionsBox_statsItemTitle__2pHjc {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin: 0 !important;
    margin-top: -20px !important;
}

.ActivePromotionsBox_statsItemAmount__2kaqM {
    font-size: 33px;
    font-weight: 700;
}


@media screen and (min-width:600px) {
    .ActivePromotionsBox_promoTypes__1uKzI{
        width: 90%;
    }
}

@media screen and (min-width:768px) {
    .ActivePromotionsBox_subTitle__2c5ms {
        text-align: left;
    }

    .ActivePromotionsBox_stats__1kkvj{
        margin: 1.5rem auto 0 !important;
    }

    .ActivePromotionsBox_promoTypes__1uKzI::-webkit-scrollbar-thumb {
        border: 4px solid #fff !important;
        background: #ccc !important;
    }

}

@media screen and (min-width:1024px) {
    .ActivePromotionsBox_title__QSNP_ {
        padding: 1.5rem 4rem;
    }

    .ActivePromotionsBox_promoTypes__1uKzI {
        width: 90%;
        justify-content: space-between;
        padding: 0 .5rem;
    }

    .ActivePromotionsBox_stats__1kkvj {
        padding: 0 5rem;
        margin-top: 1rem;
    }

    .ActivePromotionsBox_subTitle__2c5ms {
        margin: 1rem auto;
        padding: 0 5rem;
    }
}

@media screen and (min-width:1200px) {
    .ActivePromotionsBox_promoTypesButton__3gq9O {
        min-width: 110px;
    }
}

@media screen and (min-width:1700px) {
    .ActivePromotionsBox_stats__1kkvj{
        justify-content: space-between;
    }
}
.Components_container__2sA9q {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.Components_header__1WSuZ {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 0.5rem;
  justify-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.Components_header__1WSuZ h3 {
  color: black;
  font-size: 13px !important;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.PromotionsTableReports_deskContainer__2WsF0 {
    border: 1px solid green;
}

.PromotionsTableReports_table__3-RO4 {
    display: none !important;
}

.PromotionsTableReports_table__3-RO4 div div div div div table,
.PromotionsTableReports_accordion__2dlQr {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.PromotionsTableReports_accordion__2dlQr {
    padding: 1.5rem 1rem !important;
    margin-bottom: 5rem !important;
}

.PromotionsTableReports_accordion__2dlQr div {
    border-color: #eee !important;
}

.PromotionsTableReports_accordionPanel__1Qpe0>div {
    font-size: 16px !important;
}

.PromotionsTableReports_accordionPanel__1Qpe0>div>span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.PromotionsTableReports_accordionHeader__3bPYQ {
    display: flex;
    align-items: center;
    margin: auto !important;
    width: 94% !important;
}

.PromotionsTableReports_accordionHeader__3bPYQ h2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #E4056F !important;
    margin: 0 !important;
    width: 50%;
    max-width: 150px !important;
}

.PromotionsTableReports_accordionTitle__1q_Ou,
.PromotionsTableReports_accordionContentItemTitle__2CDaN {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.PromotionsTableReports_accordionContent__f3zV3 {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 15px !important;
    max-width: 400px;
    margin: auto;
    margin-left: -1rem;
    width: 112%;
}

.PromotionsTableReports_accordionContentItem__14Ent {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    margin-bottom: 15px;
}

.PromotionsTableReports_accordionContentItem__14Ent p {
    margin-right: auto !important;
}

.PromotionsTableReports_accordionContentItemTitle__2CDaN {
    font-size: 14px !important;
    margin: 0 !important;
}

.PromotionsTableReports_panelHeader__3YNcH {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.PromotionsTableReports_panelHeaderTitle__3Gb4u {
    font-weight: 400 !important;
    margin: 5px 0 !important;
}

.PromotionsTableReports_switchContainer__2oNsR {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 130px;
}

.PromotionsTableReports_table__3-RO4 div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.PromotionsTableReports_table__3-RO4 div div div div div table thead tr th::before {
    background: transparent !important;
}

.PromotionsTableReports_table__3-RO4 div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

.PromotionsTableReports_tableName__2Rzw3,
.PromotionsTableReports_tableEmail__2vlk4 {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.PromotionsTableReports_tableNameVisibleTrue__2Q3FO,
.PromotionsTableReports_tableNameVisibleFalse__2Z-EP {
    font-weight: 700 !important;
}

.PromotionsTableReports_tableNameVisibleTrue__2Q3FO {
    color: #49BA35 !important;
}

.PromotionsTableReports_tableNameVisibleFalse__2Z-EP {
    color: #FF3247 !important;
}

.PromotionsTableReports_tableNameStatusActive__xySls,
.PromotionsTableReports_tableNameStatusInactive__2eVDR,
.PromotionsTableReports_tableNameStatusVence__3N975 {
    padding: 4px 8px;
    border-radius: 10px;
}

.PromotionsTableReports_tableNameStatusActive__xySls {
    background: #49BA3573 !important;
}

.PromotionsTableReports_tableNameStatusInactive__2eVDR {
    background: #80808073 !important;
}

.PromotionsTableReports_tableNameStatusVence__3N975 {
    background: #FF324773 !important;
}

.PromotionsTableReports_tableEmail__2vlk4 {
    font-weight: 700 !important;
}

.PromotionsTableReports_tableActions__1tUVY {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}


.PromotionsTableReports_tableActions__1tUVY button,
.PromotionsTableReports_tableDeskActions__yMbxf button {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
}

.PromotionsTableReports_tableActions__1tUVY button img,
.PromotionsTableReports_tableDeskActions__yMbxf button img {
    width: 100% !important;
    max-width: 50px !important;
    max-height: 50px;
}

.PromotionsTableReports_tableSwitch__3Ia7p {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.PromotionsTableReports_tableDeskActions__yMbxf {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.PromotionsTableReports_panelHeaderTitle__3Gb4u{
    font-size: 16px !important;
}

@media screen and (min-width: 450px) {
    .PromotionsTableReports_accordionContent__f3zV3 {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .PromotionsTableReports_accordionContentItem__14Ent {
        justify-content: space-between !important;
    }

    .PromotionsTableReports_accordionTitle__1q_Ou {
        font-size: 18px !important;
    }

    .PromotionsTableReports_accordionContentItemTitle__2CDaN {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .PromotionsTableReports_table__3-RO4 {
        display: block !important;
    }

    .PromotionsTableReports_accordion__2dlQr {
        display: none !important;
    }
}

.VisibilityReports_container__lFFia {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.VisibilityReports_title__1SoyA {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.VisibilityReports_stats__3kv02 {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .VisibilityReports_stats__3kv02 {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 1700px) {
  .VisibilityReports_stats__3kv02 {
    justify-content: space-between;
  }
}

.Components_container__2oPxM {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.Components_header__4nHvm {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 0.5rem;
  justify-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.Components_header__4nHvm h3 {
  color: #E4056F;
  font-size: 13px !important;
  font-weight: 500;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.Components_header__4nHvm h3 strong {
  font-weight: 700;
  font-size: 20px;
}
.container-ReportScreen {
  border-radius: 50px;
  background-color: white;
  margin-bottom: 50px;
  border: 2px solid #808080;
  padding: 50px;
}

.lock-container{
  text-align: center;
  width: 70%;
  margin-left: 15%;
}

.blurred-reports{
  width: 100%;
  visibility: visible;
}

.blurred-reports-mobile{
  visibility: hidden;
}

.container-ReportScreenModal {
  background-color: white;
  margin-bottom: 50px;
  border: none !important;
  padding: 50px;
  border-radius: 20px !important;
}

.container-ReportScreen-flex {
  display: flex;
  align-items: center;
}
.Icon-reportScreen {
  background-color: #fcc02c;
  border-radius: 10px;
  padding: 5px 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
  margin-right: 15px;
}
.text-ReportScreen {
  color: #808080;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}

.reportScreenCompanyLogo{
  width: 100px;
  margin-right: 40px;
}

.reportScreenTopDivider{
  margin-right: 40px;
  visibility: visible;
}

@media (max-width: 766px) {
  .blurred-reports{
    width: 100%;
    height: 0px;
    visibility: hidden;
  }
  
  .blurred-reports-mobile{
    width: 100%;
    visibility: visible;
  }
}

@media (max-width: 380px) {
  .reportScreenCompanyLogo{
    margin-left: 30%;
    margin-bottom: 20px;
  }

  .reportScreenTopDivider {
    visibility: hidden;
  }
}

@media (max-width: 800px) {
  .text-ReportScreen {
    font-size: 15px;
  }
}
.text-ReportScreen span {
  font-size: 36px;
  color: #e4056f;
}
@media (max-width: 800px) {
  .text-ReportScreen span {
    font-size: 25px;
  }
}

.container-ReportScreen h2 {
  padding-left: 60px;
  color: #e4056f;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 20px 0px;
}

@media (max-width: 500px) {
  .container-ReportScreen h2 {
    padding: 0px;
    text-align: center;
  }
}

.number-ReportScreen {
  color: #808080;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  /* padding: 0px 15px; */
}
.number-1-ReportScreen {
  color: #000000;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.row-ReportScreen {
  justify-content: space-between !important;
  width: 100% !important;
  text-align: center !important;
}

.row-1-ReportScreen {
  width: 100% !important;
  text-align: center !important;
  justify-content: space-evenly;
  padding: 10px 0px 30px;
  align-items: center;
}

.row-2-ReportScreen {
  width: 100% !important;
  text-align: center !important;
  justify-content: space-evenly;
  align-items: center;
}

.col-ReportScreen {
  padding: 0px 5px 10px 5px;
  max-width: 100%;
  color: #000000 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.col-img-ReportScreen {
  padding: 8px;
  border-radius: 10px;
  min-width: 70px;
  max-height: 50px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.452);
}

.ExpiredButton {
  text-align: center;
  background-color: #e4056f;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.ActiveButton {
  text-align: center;
  background-color: #83c446;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.InactiveButton {
  text-align: center;
  background-color: #808080;
  border-radius: 20px;
  color: white;
  padding: 3px 8px;
}

.Signs-img {
  margin-top: -30px;
}

.Header_container__3Tu0v {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 2rem;
}

.Header_title__1PlK9 {
  color: #e4056f;
  font-size: 33px;
  font-weight: 700;
  margin: 0 !important;
  text-align: left;
  width: 100%;
}

.Header_datePickerContainer__2c1h3 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
  padding-right: 1rem !important;
  width: 100% !important;
}

.Header_datePickerContainer__2c1h3 label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: block;
  margin: auto;
  width: 100%;
}

.Header_datePickerContainer__2c1h3 [name="rangeDate"] {
  background-color: white;
  border: 1px solid #808080;
  font-size: 16px;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: auto;
  width: 100%;
  max-width: 300px;
}

.Header_filtersContainer__dufS0 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.Header_filtersContainer__dufS0 button {
  background: #e4056f;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  padding: 8px 32px;
  transition: all 0.5s;
  position: static;
  width: 120px;
  color: #fff;
  font-weight: 700;
}

.Header_filtersContainer__dufS0 button:hover {
  background: #b20457;
}

.Header_filtersContainer__dufS0 button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.Header_filtersContainer__dufS0 button img {
  display: none;
}

.Header_button__3syPU img {
  width: 100%;
}

.Header_buttonGray__2_m2G {
  display: none;
}

.Header_button__3syPU span {
  display: none;
}

.Header_listToggle__1bFfM {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.Header_listToggleButton__32dLR {
  background: transparent !important;
  color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  transition: all 0.5s;
}

.Header_listToggleButtonActive__1YvQi svg,
.Header_listToggleButtonActive__1YvQi span {
  transition: all 0.5s;
  color: #e4056f;
  fill: #e4056f;
}

@media screen and (min-width: 768px) {
  .Header_container__3Tu0v {
    margin-top: -0.5rem;
  }
  .Header_listToggle__1bFfM {
    justify-content: flex-start;
  }

  .Header_title__1PlK9 {
    text-align: left;
    width: 40%;
  }
}

@media screen and (max-width: 500px) {
  .Header_filtersContainer__dufS0 {
    flex-wrap: wrap;
  }
}

.InteractionReports_container__Y2qZE {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.InteractionReports_title__2n7y6 {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.InteractionReports_stats__3oap2 {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .InteractionReports_stats__3oap2 {
    padding: 1rem;
  }
}

.Components_container__jc2Jv {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.Components_header__1gDDR {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 0.5rem;
  justify-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.Components_header__1gDDR h3 {
  color: #000;
  font-size: 12px !important;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}
.Components_header__1gDDR strong {
  color: #e4056f;
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 1200px) {
  .Components_container__jc2Jv {
    max-width: 350px;
  }
}

.TrafficReports_container__35Hya {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.TrafficReports_title__1yWyt {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.TrafficReports_stats__3FqHh {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .TrafficReports_stats__3FqHh {
    padding: 1rem;
  }
}


.Components_container__ECa6C {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.Components_header__2zL9x {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 0.5rem;
  justify-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.Components_header__2zL9x h3 {
  color: #000;
  font-size: 13px !important;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.Components_header__2zL9x h3 strong {
  font-weight: 700;
  font-size: 20px;
}

@media screen and (min-width: 1200px) {
  .Components_container__ECa6C{
    max-width: 350px;
  }
}
.CompanyUsersScreen_tableName__2wDBh,
.CompanyUsersScreen_tableEmail__16tt9,
.CompanyUsersScreen_tableAcces__2nkLq,
.CompanyUsersScreen_tableSegment__1WgN8 {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.CompanyUsersScreen_tableSegment__1WgN8 {
    color: #808080 !important;
}

.CompanyUsersScreen_tableAcces__2nkLq {
    color: #E4056F !important;
}

.CompanyUsersScreen_tableEmail__16tt9 {
    font-weight: 700 !important;
}

.CompanyUsersScreen_tableActions__2WN9G {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.CompanyUsersScreen_tableActions__2WN9G button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.CompanyUsersScreen_tableActions__2WN9G button img {
    max-width: 40px;
}

.userModal-h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding: 20px 0px 0px 0px;
  padding-top: 50px;
}
.modalBody {
  width: 40% !important;
  margin: 0px auto !important;
}
.CreateAndEditUserModal-h2 {
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.modalForm {
  width: 60% !important;
  margin: 0px auto !important;
  padding: 15px 0px !important;
}

.createUserFormInput{
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  border-radius: 50px!important;
  width: 100% !important;
  padding: 10px 0px;
  padding-left: 15px !important;
}

.formSelectCreateUser{    
  text-align: center;
  border-radius: 500000px !important;
  height: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: solid rgba(0, 0, 0, 0.15) 1px;
  }

.CreateAndEditUserModal-Button {
  color: #070707 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
  margin-bottom: 30px;
}

.CreateAndEditUserModal-Button:hover {
  color: #3f3f3f !important;
}
.CreateAndEditUserModal-Button:focus {
  background-color: #e4056f !important;
}

@media (max-width: 700px) {
  .modalBody {
    width: 60% !important;
  }
}

@media (max-width: 500px) {
  .modalBody {
    width: 70% !important;
  }
}

@media (max-width: 400px) {
  .modalBody {
    width: 90% !important;
  }
}
.CreateAndEditSegmentModal {
  padding: 50px !important;
  text-align: center !important;
  margin: 0px auto !important;
}
.CreateAndEditSegmentModal-content {
  padding: 0px 30px !important;
}
.CreateAndEditSegmentModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
}

.CreateAndEditSegmentModal-Input {
  width: 100% !important;
}

.DeleteSegmentsModal {
  padding: 50px !important;
  text-align: center;
  margin: 0px auto !important;
}
.DeleteSegmentsModal-content {
  padding: 0px 30px !important;
}
.DeleteSegmentsModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
  text-align: justify !important;
}
.DeleteSegmentsModal p {
  text-align: justify !important;
}

.ListSegmentsModal-ul {
  list-style: none;
  margin: 0px auto;
}
.ListSegmentsModal-ul li {
  background-color: #e4056f;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
}

.ListSegmentsModal-ul h2 {
  color: white;
}
.ListSegmentsModal-content {
  padding: 15px 30px 20px !important;
}

.DeleteUserModal_container__lomuU {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 200px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.DeleteUserModal_content__31LXO {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.DeleteUserModal_closeIcon__2uRik {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.DeleteUserModal_header__2J2s- {
    margin-bottom: 1rem;
    text-align: left;
}

.DeleteUserModal_headerTitle__2Y7Rm {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.DeleteUserModal_headerSubtitle__21Prf {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.DeleteUserModal_footer__Nj-yn {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.DeleteUserModal_footerButton__3bbv4 {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
    margin-top: 20px !important;
}

.DeleteUserModal_footerButton__3bbv4:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.DeleteUserModal_footerGhostButton__rYywg {
    background: #fff !important;
    color: #E4056F !important;
    border: 1px solid #E4056F !important;
}

.DeleteUserModal_footerGhostButton__rYywg:hover {
    color: #E4056F !important;
    background: #e4057135 !important;
}


@media screen and (min-width: 500px) {
    .DeleteUserModal_header__2J2s- {
        text-align: center;
    }

    .DeleteUserModal_headerTitle__2Y7Rm {
        font-size: 25px;
    }

    .DeleteUserModal_footerButton__3bbv4 {
        max-width: 150px !important;
    }

    .DeleteUserModal_footerDescription__GvWf7 {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .DeleteUserModal_closeIcon__2uRik{
        right: 2.5rem;
    }
}
.DeleteUserModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
}
.deleteModal-DeleteUserModal {
  padding: 50px !important;
  text-align: center !important;
  margin: 0px auto !important;
}
.DeleteUserModal-content {
  padding: 10px 30px !important;
}

.userModal-h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding: 20px 0px 0px 0px;
  padding-top: 50px;
}
.modalBody {
  width: 40% !important;
  margin: 0px auto !important;
}
.CreateAndEditUserModal-h2 {
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.modalForm {
  width: 60% !important;
  margin: 0px auto !important;
  padding: 15px 0px !important;
}

.createUserFormInput{
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  border-radius: 50px!important;
  width: 100% !important;
  padding: 10px 0px;
  padding-left: 15px !important;
}

.formSelectCreateUser{    
  text-align: center;
  border-radius: 500000px !important;
  height: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: solid rgba(0, 0, 0, 0.15) 1px;
  }

.CreateAndEditUserModal-Button {
  color: #070707 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
  margin-bottom: 30px;
}

.CreateAndEditUserModal-Button:hover {
  color: #3f3f3f !important;
}
.CreateAndEditUserModal-Button:focus {
  background-color: #e4056f !important;
}

@media (max-width: 700px) {
  .modalBody {
    width: 60% !important;
  }
}

@media (max-width: 500px) {
  .modalBody {
    width: 70% !important;
  }
}

@media (max-width: 400px) {
  .modalBody {
    width: 90% !important;
  }
}
.DeleteEmployeeModal_container__3ruBu {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 200px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.DeleteEmployeeModal_content__32wdF {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.DeleteEmployeeModal_closeIcon__343C4 {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.DeleteEmployeeModal_header__tCB7n {
    margin-bottom: 1rem;
    text-align: left;
}

.DeleteEmployeeModal_headerTitle__122y9 {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.DeleteEmployeeModal_headerSubtitle__3j9R- {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.DeleteEmployeeModal_footer__m5Yo0 {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.DeleteEmployeeModal_footerButton__3OPTV {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
    margin-top: 20px !important;
}

.DeleteEmployeeModal_footerButton__3OPTV:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.DeleteEmployeeModal_footerGhostButton__1A94E {
    background: #fff !important;
    color: #E4056F !important;
    border: 1px solid #E4056F !important;
}

.DeleteEmployeeModal_footerGhostButton__1A94E:hover {
    color: #E4056F !important;
    background: #e4057135 !important;
}


@media screen and (min-width: 500px) {
    .DeleteEmployeeModal_header__tCB7n {
        text-align: center;
    }

    .DeleteEmployeeModal_headerTitle__122y9 {
        font-size: 25px;
    }

    .DeleteEmployeeModal_footerButton__3OPTV {
        max-width: 150px !important;
    }

    .DeleteEmployeeModal_footerDescription__EhIPj {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .DeleteEmployeeModal_closeIcon__343C4{
        right: 2.5rem;
    }
}
.DeleteUserModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
}
.deleteModal-DeleteUserModal {
  padding: 50px !important;
  text-align: center !important;
  margin: 0px auto !important;
}
.DeleteUserModal-content {
  padding: 10px 30px !important;
}

.UsersExcelModal-title {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    font-size: 20px;
    padding: 20px  0px;
    width: 100%;
    margin: 0px auto;
  }
  @media (min-width: 1000px) {
    .UsersExcelModal-title {
        width: 55%;
      }
}
  .UsersExcelModal {
    padding: 50px !important;
    text-align: center !important;
    margin: 0px auto !important;
  }
  .UsersExcelModal-content {
    padding: 10px 30px !important;
    text-align: center !important;
  }
  .UsersExcelModal-h3{
      padding: 0px 0px 15px 0px;
      font-weight: bolder;
      color: #808080;
  }
.UsersScreenButton {
  color: #808080 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center !important;
  border: none !important;
  font-weight: 700 !important;
  margin: 0px 0px 1px 0px !important;
}
.UsersScreenButton:hover {
  color: #e4056f !important;
}
.UsersScreenButton:focus {
  color: #e4056f !important;
}
.Users-h1-Label {
  font-weight: bold;
  font-style: italic;
  padding-left: 40px;
}
.container-UsersScreen {
  border-radius: 50px;
  background-color: white;
  margin-bottom: 50px !important;
  border: 2px solid #808080;
  padding: 20px !important;
}
.row-UsersScreen {
  justify-content: space-evenly;
  align-items: center;
  border: 1px gray solid;
  border-radius: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-UsersScreen h2{
  font-weight: bold;
  color: #E4056F;
  opacity: 0.5;
}

.row-UsersScreen p{
  font-size: 20px;
}
.Upload-form-users {
  width: 250px !important;
  margin: 0px auto !important;
}
.Upload-input-users {
  padding: 30px !important;
  border-radius: 20px !important;
}
.Upload-p-users {
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-align: center;
  margin-right: 10px;
}

.ant-table-row {
  border: 20px solid black !important;
}

.companyUsersScreenCompanyLogo{
  width: 10vw;
}

.addUserButton{
  background-color: #e4056f;
  border-radius: 50%;
  padding: 4px !important;
  cursor: pointer;
  color: white !important
}

.excelUsersIcon{
  width: 40px;
}

.companyUsersTopButtonsContainer{
  margin-bottom: 40px;
}

.companyUsersTopButton{
  border: none !important;
  font-style: italic;
  background-color: transparent !important;
  box-shadow: transparent !important;
}

@media (max-width: 992px) {
  .companyUsersScreenCompanyLogo{
    width: 15vw;
  }
}

@media (max-width: 700px) {
  .companyUsersScreenCompanyLogo{
    width: 20vw;
  }
}

@media (max-width: 500px) {
  .companyUsersScreenCompanyLogo{
    width: 30vw;
  }
}
.Header_container__3crqK {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 2rem;
}

.Header_title__bOhOg {
    font-size: 33px;
    font-weight: 700;
    margin: 0 !important;
    text-align: center;
    width: 100%;
}

.Header_button__1WrlW {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.Header_button__1WrlW img {
    width: 100%;
}

.Header_buttonGray__3uOjC {
    display: none;
}

.Header_button__1WrlW span {
    display: none;
}

.Header_listToggle__3Xjeb {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: .5rem;
    width: 100%;
}

.Header_listToggleButton__3WX4t {
    background: transparent !important;
    color: #808080;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 !important;
    border: none !important;
    outline: none !important;
    transition: all .5s;
}

.Header_listToggleButtonActive__3HAXB svg,
.Header_listToggleButtonActive__3HAXB span {
    transition: all .5s;
    color: #E4056F;
    fill: #E4056F;
}


@media screen and (min-width: 768px) {
    .Header_container__3crqK{
        margin-top: -.5rem;
    }
    .Header_listToggle__3Xjeb {
        justify-content: flex-start;
    }

    .Header_title__bOhOg {
        text-align: left;
        width: 40%;
    }

    .Header_button__1WrlW {
        background: #E4056F;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .Header_button__1WrlW:hover {
        background: #B20457;
    }

    .Header_button__1WrlW img {
        display: none;
    }

    .Header_button__1WrlW span {
        color: #fff;
        display: block;
        font-weight: 700;
    }

    .Header_buttonGray__3uOjC {
        background: #B3B3B3;
        display: inline-block;
        padding: 8px 15px;
        margin-left: auto;
        margin-right: 20px;
        width: 150px;
    }

    .Header_buttonGray__3uOjC:hover {
        background: #505050;
    }
}
.Employees_deskContainer__3fRP- {
    border: 1px solid green;
}

.Employees_table__3xnpJ {
    display: none !important;
}

.Employees_table__3xnpJ div div div div div table,
.Employees_accordion__2UR7J {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.Employees_accordionEmpty__29RlL {
    background: transparent !important;
    border: none !important;
    text-align: center;
    color: #aaa !important;
    font-weight: 700;
    padding: 1rem !important;
}

.Employees_accordion__2UR7J {
    padding: 1rem .5rem !important;
    margin-bottom: 5rem !important;
}

.Employees_accordion__2UR7J div {
    border-color: #eee !important;
}

.Employees_accordionPanel__13lKo>div {
    font-size: 16px !important;
}

.Employees_accordionPanel__13lKo>div span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.Employees_accordionPanelTitle__bpjdK {
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #000 !important;
}

.Employees_accordionTitle__2m6r9,
.Employees_accordionContentItemTitle__1dIfX {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.Employees_accordionContent__3feU_ {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 20px 15px !important;
    max-width: 400px;
    width: 100% !important;
    margin: auto;
}

.Employees_accordionContentItem__4I8Q5 {
    /* background-color: rgba(51, 51, 51, 0.226) !important; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 15px;
}

.Employees_accordionContentItemTitle__1dIfX {
    font-size: 14px !important;
    margin: 0 !important;
}

.Employees_table__3xnpJ div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.Employees_table__3xnpJ div div div div div table thead tr th::before {
    background: transparent !important;
}

.Employees_table__3xnpJ div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

/*
.table div div div div div table tbody tr:hover td {
    background: transparent !important;
} */

.Employees_tableName__3EP2v,
.Employees_tableEmail__M93Sn,
.Employees_tableAcces__22HS6,
.Employees_tableSegment__2mZYF {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.Employees_tableSegment__2mZYF {
    color: #808080 !important;
}

.Employees_tableAcces__22HS6 {
    color: #E4056F !important;
}


.Employees_tableEmail__M93Sn {
    font-weight: 700 !important;
}

.Employees_tableActions__1VRr1 {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.Employees_tableActions__1VRr1 button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.Employees_tableActions__1VRr1 button img {
    max-width: 40px;
}

.Employees_tableSwitch__3aTgq {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.Employees_accordionPanelTitle__bpjdK{
    font-size: 16px !important;
}

@media screen and (min-width: 450px) {
    .Employees_accordionContent__3feU_ {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .Employees_accordionTitle__2m6r9 {
        font-size: 18px !important;
    }

    .Employees_accordionContentItemTitle__1dIfX {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .Employees_table__3xnpJ {
        display: block !important;
    }

    .Employees_accordion__2UR7J {
        display: none !important;
    }
}
.Users_deskContainer__17pEU {
    border: 1px solid green;
}

.Users_table__25cN_ {
    display: none !important;
}

.Users_table__25cN_ div div div div div table,
.Users_accordion__1t6Tg {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.Users_accordionEmpty__9BQbs {
    background: transparent !important;
    border: none !important;
    text-align: center;
    color: #aaa !important;
    font-weight: 700;
    padding: 1rem !important;
}


.Users_accordion__1t6Tg {
    padding: 1rem .5rem !important;
    margin-bottom: 5rem !important;
}

.Users_accordion__1t6Tg div {
    border-color: #eee !important;
}

.Users_accordionPanel__3mmVU>div {
    font-size: 16px !important;
}

.Users_accordionPanel__3mmVU>div span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.Users_accordionPanelTitle__5IOBR {
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 16px !important;
}

.Users_accordionTitle__1GCt_,
.Users_accordionContentItemTitle__15Lfh {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.Users_accordionContent__11DLV {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 20px 15px !important;
    max-width: 400px;
    width: 100% !important;
    margin: auto;
}

.Users_accordionContentItem__1xU6P {
    /* background-color: rgba(51, 51, 51, 0.226) !important; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 15px;
}

.Users_accordionContentItemTitle__15Lfh {
    font-size: 14px !important;
    margin: 0 !important;
}

.Users_table__25cN_ div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.Users_table__25cN_ div div div div div table thead tr th::before {
    background: transparent !important;
}

.Users_table__25cN_ div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

/*
.table div div div div div table tbody tr:hover td {
    background: transparent !important;
} */

.Users_tableName__1TOCY,
.Users_tableEmail__sV8Dz,
.Users_tableAcces__1wCw9,
.Users_tableSegment__1PDoy {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.Users_tableSegment__1PDoy {
    color: #808080 !important;
}

.Users_tableAcces__1wCw9 {
    color: #E4056F !important;
}


.Users_tableEmail__sV8Dz {
    font-weight: 700 !important;
}

.Users_tableActions__3PtPV {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.Users_tableActions__3PtPV button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.Users_tableActions__3PtPV button img {
    max-width: 40px;
}

.Users_tableSwitch__3IACA {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (min-width: 450px) {
    .Users_accordionContent__11DLV {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .Users_accordionTitle__1GCt_ {
        font-size: 18px !important;
    }

    .Users_accordionContentItemTitle__15Lfh {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .Users_table__25cN_ {
        display: block !important;
    }

    .Users_accordion__1t6Tg {
        display: none !important;
    }
}
.PromotionsTableReviews_deskContainer__aHcwf {
    border: 1px solid green;
}

.PromotionsTableReviews_table__3zPF9 {
    display: none !important;
}

.PromotionsTableReviews_table__3zPF9 div div div div div table,
.PromotionsTableReviews_accordion__i-kex {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.PromotionsTableReviews_accordion__i-kex {
    padding: 1.5rem 1rem !important;
    margin-bottom: 5rem !important;
}

.PromotionsTableReviews_accordion__i-kex div {
    border-color: #eee !important;
}

.PromotionsTableReviews_accordionPanel__Wy3Zz>div {
    font-size: 16px !important;
}

.PromotionsTableReviews_accordionPanel__Wy3Zz>div>span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.PromotionsTableReviews_accordionHeader__e5Rjf {
    display: flex;
    align-items: center;
    margin: auto !important;
    width: 94% !important;
}

.PromotionsTableReviews_accordionHeader__e5Rjf h2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #E4056F !important;
    margin: 0 !important;
    width: 50%;
    max-width: 150px !important;
}

.PromotionsTableReviews_accordionTitle__1cRd7,
.PromotionsTableReviews_accordionContentItemTitle__2GVHd {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.PromotionsTableReviews_accordionContent__1igQq {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 15px !important;
    max-width: 400px;
    margin: auto;
    margin-left: -1rem;
    width: 112%;
}

.PromotionsTableReviews_accordionContentItem__2rJnr {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    margin-bottom: 15px;
}

.PromotionsTableReviews_accordionContentItemTitle__2GVHd {
    font-size: 14px !important;
    margin: 0 !important;
    margin-left: 0 !important;
    margin-right: auto !important;
    width: 90px !important;
}

.PromotionsTableReviews_panelHeader__2lCRy {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.PromotionsTableReviews_panelHeader__2lCRy h3 {
    margin: 0 !important;
    width: 50%;
    font-size: 16px !important;
    max-width: 150px !important;
    font-weight: 400 !important;
}

.PromotionsTableReviews_switchContainer__1q4Wu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 130px;
}

.PromotionsTableReviews_table__3zPF9 div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.PromotionsTableReviews_table__3zPF9 div div div div div table thead tr th::before {
    background: transparent !important;
}

.PromotionsTableReviews_table__3zPF9 div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

.PromotionsTableReviews_tableName__272u4,
.PromotionsTableReviews_tableEmail__3Vlvb {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.PromotionsTableReviews_tableEmail__3Vlvb {
    font-weight: 700 !important;
}

.PromotionsTableReviews_tableActions__1Kryo {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.PromotionsTableReviews_tableActions__1Kryo button:last-of-type {
    margin-right: auto;
}

.PromotionsTableReviews_tableActions__1Kryo button,
.PromotionsTableReviews_tableDeskActions__3PX5f button {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
}

.PromotionsTableReviews_tableActions__1Kryo button img,
.PromotionsTableReviews_tableDeskActions__3PX5f button img {
    width: 100% !important;
    max-width: 35px !important;
    max-height: 35px;
}

.PromotionsTableReviews_tableSwitch__7MiE0 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.PromotionsTableReviews_tableDeskActions__3PX5f {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}


@media screen and (min-width: 450px) {
    .PromotionsTableReviews_accordionContent__1igQq {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .PromotionsTableReviews_accordionContentItem__2rJnr {
        justify-content: space-between !important;
    }

    .PromotionsTableReviews_accordionTitle__1cRd7 {
        font-size: 18px !important;
    }

    .PromotionsTableReviews_accordionContentItemTitle__2GVHd {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .PromotionsTableReviews_table__3zPF9 {
        display: block !important;
    }

    .PromotionsTableReviews_accordion__i-kex {
        display: none !important;
    }
}
.warning-modal {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.warning-modal__description {
    font-size: 20px;
    width: 95%;
    text-align: center;
    margin: 1rem auto;
    font-weight: bold;
}

.warning-modal__buttons-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
    width: 100% !important;
}

.warning-modal__close-button {
    display: none !important;
}

.warning-modal__button {
    background-color: transparent !important;
    border-radius: 25px !important;
    border: none !important;
    color: #000 !important;
    cursor: pointer !important;
    font-size: 18px !important;
    font-weight: bold !important;
    padding: .3rem 1.8rem !important;
    text-decoration: underline !important;
    transition: all .5s !important;
}

.warning-modal__button:hover {
    background: #E4056F !important;
    text-decoration: none !important;
    color: #fff !important;
}

.warning-modal__logo{
    width: 70%;
    margin: 1rem auto;
}

.warning-modal__title{
    font-weight: bold;
    margin: .5rem auto;
}
.ReviewModal_header__Fs9Tn {
    background: #E4056F;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.ReviewModal_headerTitle__33iSA {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
}

.ReviewModal_headerCloseButton__2Xm6H {
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}

.ReviewModal_rating__26rjA,
.ReviewModal_review__1yeRd {
    text-align: center;
    padding: 1rem;
}

.ReviewModal_ratingTitle__FuXCv,
.ReviewModal_reviewTitle__C7R56 {
    font-weight: bold;
    font-size: 22px;
}

.ReviewModal_rating__26rjA ul{
    font-size: 2rem !important;
}

.ReviewModal_reviewText__2hlsB {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
    font-size: .9rem;
    margin: 0 auto;
    padding: 1.5rem;
    width: 80%;
}

.ReviewModal_buttonContainer__2R1c0 {
    padding: .5rem 0;

}

.ReviewModal_button__2g05Q {
    background: #E4056F;
    border: none;
    border-radius: 2rem;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1rem auto;
    padding: .3rem 4rem;
    transition: all .4s;
}

.ReviewModal_button__2g05Q:hover {
    background-color: #f71c86;
    transform: scale(1.05);
}

.ReviewModal_button__2g05Q:active {
    background-color: #e7157b;
    transform: none;
}
.Header_container__3kxwF {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 2rem;
}

.Header_main__3KZaO {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Header_mainTitle__JRLCv {
    font-size: 26px;
    font-weight: 700;
    margin: 0 !important;
}

.Header_mainButton__1ZJIf {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.Header_mainButton__1ZJIf img {
    width: 100%;
}

.Header_mainButton__1ZJIf span {
    display: none;
}

.Header_rating__3Cqio {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 5px;
    width: 100%;
}

.Header_ratingTitle__7nHgd {
    color: #E4056F;
    font-size: 16px;
    font-weight: 500;
    margin: 0 !important;
}

@media screen and (min-width: 768px) {
    .Header_main__3KZaO {
        justify-content: space-between;
    }

    .Header_mainTitle__JRLCv {
        font-size: 33px;
    }

    .Header_mainButton__1ZJIf {
        background: #FCC02C;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .Header_mainButton__1ZJIf:hover {
        background: #E5AF28;
    }

    .Header_mainButton__1ZJIf img {
        display: none;
    }

    .Header_mainButton__1ZJIf span {
        color: #fff;
        display: block;
        font-weight: 700;
    }

    .Header_rating__3Cqio {
        justify-content: flex-start;
    }

    .Header_ratingTitle__7nHgd {
        font-size: 19px;

    }
}
.Companies_deskContainer__3xIHc {
    border: 1px solid green;
}

.Companies_table__osAD6 {
    display: none !important;
}

.Companies_table__osAD6 div div div div div table,
.Companies_accordion__2gOvE {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.Companies_accordion__2gOvE {
    padding: 1rem .5rem !important;
    margin-bottom: 5rem !important;
}

.Companies_accordion__2gOvE div {
    border-color: #eee !important;
}

.Companies_accordionPanel__g0MSg>div {
    font-size: 16px !important;
}

.Companies_accordionPanel__g0MSg>div span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.Companies_accordionTitle__2SEzm,
.Companies_accordionContentItemTitle__27FtA {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.Companies_accordionContent__3x8Cq {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 15px 10px !important;
    max-width: 400px;
    width: 108% !important;
    margin: auto;
    margin-left: -.8rem !important;
}

.Companies_accordionContentItem__pExjW {
    /* background-color: rgba(51, 51, 51, 0.226) !important; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.Companies_accordionContentItemTitle__27FtA {
    font-size: 14px !important;
    margin-left: 0 !important;
    margin-right: auto !important;
}

.Companies_table__osAD6 div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.Companies_table__osAD6 div div div div div table thead tr th::before {
    background: transparent !important;
}

.Companies_table__osAD6 div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

/*
.table div div div div div table tbody tr:hover td {
    background: transparent !important;
} */

.Companies_tableName__3mdyu,
.Companies_tableEmail__1pogT {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.Companies_tableEmail__1pogT {
    font-weight: 700 !important;
}

.Companies_tableActions__3ldvP {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.Companies_tableActions__3ldvP button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.Companies_tableActions__3ldvP button img {
    max-width: 40px;
}

.Companies_tableSwitch__3UFAc {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (min-width: 450px) {
    .Companies_accordionContent__3x8Cq {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .Companies_accordionContentItem__pExjW {
        justify-content: space-between !important;
    }

    .Companies_accordionTitle__2SEzm {
        font-size: 18px !important;
    }

    .Companies_accordionContentItemTitle__27FtA {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .Companies_table__osAD6 {
        display: block !important;
    }

    .Companies_accordion__2gOvE {
        display: none !important;
    }
}
.DeleteCompanyModal_container__1MKHz {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 200px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.DeleteCompanyModal_content__1vbtr {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.DeleteCompanyModal_closeIcon__1qxc8 {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.DeleteCompanyModal_header__1iRi- {
    margin-bottom: 1rem;
    text-align: left;
}

.DeleteCompanyModal_headerTitle__3UbWw {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.DeleteCompanyModal_headerSubtitle__3uJmi {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.DeleteCompanyModal_footer__4zffy {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.DeleteCompanyModal_footerButton__2G65Z {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
    margin-top: 20px !important;
}

.DeleteCompanyModal_footerButton__2G65Z:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.DeleteCompanyModal_footerGhostButton__2b1i- {
    background: #fff !important;
    color: #E4056F !important;
    border: 1px solid #E4056F !important;
}

.DeleteCompanyModal_footerGhostButton__2b1i-:hover {
    color: #E4056F !important;
    background: #e4057135 !important;
}


@media screen and (min-width: 500px) {
    .DeleteCompanyModal_header__1iRi- {
        text-align: center;
    }

    .DeleteCompanyModal_headerTitle__3UbWw {
        font-size: 25px;
    }

    .DeleteCompanyModal_footerButton__2G65Z {
        max-width: 150px !important;
    }

    .DeleteCompanyModal_footerDescription__36yE5 {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .DeleteCompanyModal_closeIcon__1qxc8{
        right: 2.5rem;
    }
}
.Header_container__F2AsY {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 2rem;
}

.Header_title__3f2-3 {
    font-size: 33px;
    font-weight: 700;
    margin: 0 !important;
    text-align: center;
    width: 100%;
}

.Header_button__1T0gY {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.Header_button__1T0gY img {
    width: 100%;
}

.Header_button__1T0gY span {
    display: none;
}

@media screen and (min-width: 768px) {
    .Header_title__3f2-3 {
        text-align: left;
    }

    .Header_button__1T0gY {
        background: #E4056F;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .Header_button__1T0gY:hover {
        background: #B20457;
    }

    .Header_button__1T0gY img {
        display: none;
    }

    .Header_button__1T0gY span {
        color: #fff;
        display: block;
        font-weight: 700;
    }
}
.CreateCompanyModal_container__1A4K2 {
    border-radius: 25px;
    background-color: #fff;
    padding: 0 !important;
    position: relative;
    top: 50px !important;
    max-width: 520px !important;
    width: 90% !important;
}

.CreateCompanyModal_content__2VjWL {
    max-width: 475px;
    margin: auto !important;
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
    box-shadow: none !important;
}

.CreateCompanyModal_closeIcon__1iiW- {
    color: #E4056F !important;
    font-weight: bold !important;
    font-size: 20px;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
}

.CreateCompanyModal_header__gJab6 {
    margin-bottom: 1rem;
    text-align: left;
}

.CreateCompanyModal_headerTitle__1EZ5b {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.CreateCompanyModal_headerSubtitle__3pwLn {
    color: #E4056F;
    font-size: 17px;
    font-weight: 500;
    display: block;
    margin-top: -5px;
}

.CreateCompanyModal_footer__2YMym {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.CreateCompanyModal_footerButton__39xEb {
    background: #FCC02C !important;
    border: 1px solid #FCC02C !important;
    border-radius: 10px !important;
    color: #fff !important;
    cursor: pointer;
    font-size: 16px !important; 
    font-weight: 700 !important;
    padding: 7px 0;
    transition: all .5s !important;
    width: 100% !important;
    height: 40px !important;
}

.CreateCompanyModal_footerButton__39xEb:hover {
    background: #E5AF28 !important;
    border-color: #E5AF28 !important;
}

.CreateCompanyModal_footerGhostButton__2v-O- {
    background: #fff !important;
    color: #FCC02C !important;
}

.CreateCompanyModal_footerGhostButton__2v-O-:hover {
    color: #E5AF28;
}


@media screen and (min-width: 500px) {
    .CreateCompanyModal_header__gJab6 {
        text-align: center;
    }

    .CreateCompanyModal_headerTitle__1EZ5b {
        font-size: 25px;
    }

    .CreateCompanyModal_footerButton__39xEb {
        max-width: 150px !important;
    }

    .CreateCompanyModal_footerDescription__21ur1 {
        font-size: 15px;
    }
}

@media screen and (min-width:768px) {
    .CreateCompanyModal_closeIcon__1iiW-{
        right: 2.5rem;
    }
}
.StepOne_container__2n-xv {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.StepOne_form__2qadO {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 0 2rem !important;
}

.StepOne_formItem__3iXVv {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.StepOne_formItemLabel__3SIh1 {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.StepOne_formItemInput__3Cd99,
.StepOne_formItemInputSelect__f2cHT {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.StepOne_formItemInputSelect__f2cHT {
    height: unset !important;
    padding: 0 !important;
}

.StepOne_formItemInputSelect__f2cHT>div {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-radius: 20px !important;
    border: none !important;
    border-color: transparent !important;
    background: transparent !important;
    text-align: left !important;
    margin: auto !important;
    height: 48px !important;
    outline: none !important;
    box-shadow: none !important;
    width: 95% !important;
}

.StepOne_formItem__3iXVv * {
    background-color: #fff !important;
}

.StepOne_formItemInput__3Cd99:focus {
    border-color: #8a8a8a !important;
}

.StepOne_formItemInput__3Cd99::placeholder {
    color: #ccc !important;
}


@media screen and (min-width: 768px) {
    .StepOne_form__2qadO {
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .StepOne_formItemFlex__1o3bm {
        width: 45% !important;
    }
}
.CompanyNotifications_container__iHwjD {
  width: 100%;
}
.CompanyNotifications_title__3CqSM {
  color: #e4056f;
  font-weight: 700;
  font-size: 2rem;
}

.Notifications_table__19D20 {
  display: none !important;
}

.Notifications_table__19D20 div div div div div table,
.Notifications_accordion__2Ohtm {
  background-color: #fff !important;
  border: none !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px !important;
  padding: 1rem 2rem 1.5rem !important;
}

.Notifications_accordion__2Ohtm {
  padding: 1rem 0.5rem !important;
  margin-bottom: 5rem !important;
}

.Notifications_accordion__2Ohtm div {
  border-color: #eee !important;
}

.Notifications_accordionPanel__Tsjhe > div {
  font-size: 16px !important;
}

.Notifications_accordionPanel__Tsjhe > div span {
  font-size: 16px !important;
  color: #e4056f !important;
}

.Notifications_accordionTitle__2KnnJ,
.Notifications_accordionContentItemTitle__2t0Ql {
  font-weight: 500 !important;
  font-size: 17px !important;
  color: #e4056f !important;
  margin-left: 1rem !important;
}

.Notifications_accordionTitle__2KnnJ {
  text-align: center;
  color: black !important;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.Notifications_accordionEmptyTitle__1dO6x {
  text-align: center;
  color: #8f8f8f !important;
}

.Notifications_accordionContent__2m_oF {
  background: #e6e6e6 !important;
  border-radius: 8px !important;
  padding: 15px 10px !important;
  max-width: 400px;
  width: 108% !important;
  margin: auto;
  margin-left: -0.8rem !important;
}

.Notifications_accordionContentItem__DNfFh {
  /* background-color: rgba(51, 51, 51, 0.226) !important; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.Notifications_accordionContentItemTitle__2t0Ql {
  font-size: 14px !important;
  margin-left: 0 !important;
}

.Notifications_table__19D20 div div div div div table thead tr th {
  border: none !important;
  text-align: center !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #e4056f !important;
  padding: 1rem 0 1.5rem !important;
}

.Notifications_table__19D20 div div div div div table thead tr th::before {
  background: transparent !important;
}

.Notifications_table__19D20 div div div div div table tbody tr td {
  padding: 10px 5px !important;
  border-radius: 0 !important;
  text-align: center !important;
}

.Notifications_table__19D20 div div ul li[class*="ant-pagination-item-active"] {
  border-color: #e4056f !important;
}
.Notifications_table__19D20 div div ul li[class*="ant-pagination-item-active"] a {
  color: #e4056f !important;
}

/*
.table div div div div div table tbody tr:hover td {
  background: transparent !important;
} */

.Notifications_accordionNotificationId__2BTfJ {
  display: block !important;
  color: #e4056f !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

.Notifications_tableName__1oZPv,
.Notifications_tableEmail__2t69w {
  font-style: normal !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  color: #333 !important;
}

.Notifications_tableSingleRowValue__r4iVX {
  color: #333 !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  text-align: left !important;
  margin-bottom: 0.5em !important;
}

.Notifications_tableStatus__hjAUS {
  color: #fff !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background: #ddd;
  padding: 5px;
  border-radius: 8px;
}

.Notifications_tableCenteredText__XqLVR {
  text-align: center !important;
}

.Notifications_tableEmail__2t69w {
  font-weight: 700 !important;
}

.Notifications_tableActions__3Nn8z {
  display: flex !important;
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  max-width: 150px !important;
  margin: auto !important;
}

.Notifications_tableActions__3Nn8z button {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  width: 60px !important;
}

.Notifications_tableActions__3Nn8z button img {
  max-width: 40px;
}

.Notifications_tableSwitch__TlRFy {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (min-width: 450px) {
  .Notifications_accordionContent__2m_oF {
    padding: 20px !important;
    margin: auto !important;
    width: 100% !important;
  }

  .Notifications_accordionContentItem__DNfFh {
    justify-content: flex-start !important;
  }

  .Notifications_accordionTitle__2KnnJ {
    font-size: 18px !important;
  }

  .Notifications_accordionContentItemTitle__2t0Ql {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1024px) {
  .Notifications_table__19D20 {
    display: block !important;
  }

  .Notifications_accordion__2Ohtm {
    display: none !important;
  }
}

@media screen and (min-width: 1240px) {
  .Notifications_table__19D20 div div div div div table tbody tr td {
    padding: 10px !important;
  }

  .Notifications_accordion__2Ohtm {
    display: none !important;
  }
}

.SchedulteNotificationModal_container__16ULk {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SchedulteNotificationModal_header__3df5_ {
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}
.SchedulteNotificationModal_header__3df5_ h2 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #e4056f;
}

.SchedulteNotificationModal_datePickerContainer__3GfyX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 1rem auto 2rem;
  padding: 0 0.5rem;
  width: 100%;
}

.SchedulteNotificationModal_datePickerContainer__3GfyX strong {
  font-size: 15px;
  font-weight: 700;
  color: #808080;
  width: 100%;
}

.SchedulteNotificationModal_datePickerContainer__3GfyX button {
  background: transparent;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  color: #333;
  border: none;
  outline: none;
  font-weight: 600;
}

.SchedulteNotificationModal_datePicker__MS1W8 {
  padding: 0.5rem !important;
  border-radius: 6px !important;
  width: 90%;
}

.SchedulteNotificationModal_footer__3tRuz {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.SchedulteNotificationModal_footer__3tRuz button {
  background: transparent;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  color: #333;
  border: 1px solid #333;
  font-weight: 600;
}

.SchedulteNotificationModal_confirmButton__35yLn {
  background-color: #e4056f !important;
  color: white !important;
  border: none !important;
}

.SchedulteNotificationModal_confirmButton__35yLn:hover {
  background-color: #c10560 !important;
}

.SchedulteNotificationModal_confirmButton__35yLn:active {
  background-color: #e4056f !important;
}

.SchedulteNotificationModal_confirmButton__35yLn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.SchedulteNotificationModal_cancelButton__2W_ea:hover {
  border-color: #000;
  color: #000;
}

@media (max-width: 768px) {
  .SchedulteNotificationModal_datePicker__MS1W8 {
    width: 80%;
  }
}

.SendTestEmailNotificationModal_container__2s9Ws {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SendTestEmailNotificationModal_header__1WK2n {
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}
.SendTestEmailNotificationModal_header__1WK2n h2 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #e4056f;
}

.SendTestEmailNotificationModal_inputContainer__1fjfI {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 1rem auto 2rem;
  padding: 0 0.5rem;
  width: 100%;
}

.SendTestEmailNotificationModal_inputContainer__1fjfI label {
  font-size: 15px;
  font-weight: 700;
  color: #808080;
}

.SendTestEmailNotificationModal_emailInput__yOHcf {
  border: 1px solid #333;
  padding: 0.5rem !important;
  border-radius: 6px !important;
  width: 100%;
}

.SendTestEmailNotificationModal_footer__2dXxK {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.SendTestEmailNotificationModal_footer__2dXxK button {
  background: transparent;
  padding: 6px 14px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  color: #333;
  border: 1px solid #333;
  font-weight: 600;
}

.SendTestEmailNotificationModal_confirmButton__32Egq {
  background-color: #e4056f !important;
  border: 1px solid #e4056f !important;
  color: white !important;
  padding: 6px 36px !important;
  border: none !important;
}

.SendTestEmailNotificationModal_confirmButton__32Egq:hover {
  background-color: #c10560 !important;
}

.SendTestEmailNotificationModal_confirmButton__32Egq:active {
  background-color: #e4056f !important;
}

.SendTestEmailNotificationModal_confirmButton__32Egq:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.SendTestEmailNotificationModal_cancelButton__3njbF:hover {
  border-color: #000;
  color: #000;
}

.SendTestEmailResultModal_container__2bz5Q {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}

.SendTestEmailResultModal_container__2bz5Q h2 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}

.NotificationsManagement_container__zBZ82 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 1rem 4rem;
  gap: 1rem 4rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.NotificationsManagement_title__2qwkD {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
}

.NotificationsManagement_switch__3CgtW {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.NotificationsManagement_switchContainer__1emhw {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.NotificationsManagement_button__9h_iL {
  background-color: #e4056f;
  border: none;
  color: white;
  outline: none;
  padding: 8px 30px;
  border-radius: 2rem;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.NotificationsManagement_button__9h_iL:hover {
  background-color: #d0045f;
}

.NotificationsManagement_button__9h_iL:active {
  background-color: #c0034f;
}

@media (max-width: 768px) {
  .NotificationsManagement_container__zBZ82 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .NotificationsManagement_title__2qwkD {
    font-size: 1rem;
  }
}

.NotificationDetails_container__3DYOu {
  width: 100%;
}

.NotificationDetails_header__26T4o {
  width: 100%;
}

.NotificationDetails_header__26T4o button {
  background-color: #e4056f;
  border: none;
  color: white;
  outline: none;
  padding: 8px 16px;
  border-radius: 2rem;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.NotificationDetails_header__26T4o h2 {
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 700;
  color: #e4056f;
  margin: 1rem 0 1.5rem;
}

.NotificationDetails_notificationDetailsContainer__22enU {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
  align-items: flex-start !important;
  padding-right: 1rem !important;
}

.NotificationDetails_notificationDetailsInputContainer__3U-iH {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
  padding-right: 1rem !important;
  width: 100% !important;
}

.NotificationDetails_notificationDetailsInputContainer__3U-iH label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: block;
  margin: auto;
  width: 100%;
}

.NotificationDetails_notificationDetailsInputContainer__3U-iH input {
  background-color: white;
  border: 1px solid #808080;
  font-size: 16px;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: auto;
  width: 100%;
}

.NotificationDetails_notificationBenefitsContainer__3cxRz {
  width: 99%;
  margin-top: auto !important;
}

.NotificationDetails_notificationBenefitsContainer__3cxRz h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
}

.NotificationDetails_notificationBenefitsList__3SxUf {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #808080;
  padding: 0.8rem 1rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: flex-start;
  align-items: center;
  padding-right: 3rem;
}

.NotificationDetails_notificationBenefitsList__3SxUf strong {
  color: white;
  background-color: #e4056f;
  border-radius: 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  padding: 0.5rem 0.8rem;
}

.NotificationDetails_emailIframeContainer__1s7qb {
  border: 1px solid #ccc !important;
  background: white;
  border-radius: 8px;
  position: relative;
  min-height: 35rem !important;
  overflow-y: hidden;
  padding: 1rem 1rem 3rem;
}

.NotificationDetails_emailIframeContainer__1s7qb strong {
  display: block;
  border-bottom: 2px solid #333;
  margin-bottom: 1rem;
  width: 100%;
}

.NotificationDetails_emailIframeContainer__1s7qb iframe {
  width: 100%;
  border: none !important;
  height: 100% !important;
}

@media (max-width: 1000px) {
  .NotificationDetails_header__26T4o h2 {
    margin: 1rem 0;
  }
  .NotificationDetails_notificationDetailsInputContainer__3U-iH {
    padding: 0 0.5rem !important;
  }
  .NotificationDetails_notificationDetailsContainer__22enU {
    padding-right: 0;
    margin-bottom: 2rem;
  }
  .NotificationDetails_notificationBenefitsContainer__3cxRz {
    margin-top: 1.5rem;
  }
  .NotificationDetails_notificationBenefitsList__3SxUf {
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1.2rem 0.6rem;
  }
  .NotificationDetails_emailIframeContainer__1s7qb strong {
    text-align: center;
    border-bottom: none;
    font-size: 1.2rem;
    color: #808080;
  }
}

.PromotionsTable_container__2V0Ht {
  width: 100%;
}

.PromotionsTable_table__psGUO {
  display: none !important;
}

.PromotionsTable_table__psGUO div div div div div table,
.PromotionsTable_accordion__1aUUf {
  background-color: #fff !important;
  border: none !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px !important;
  padding: 1rem 1rem 1.5rem !important;
}

.PromotionsTable_accordion__1aUUf {
  padding: 1rem 0.5rem !important;
}

.PromotionsTable_accordion__1aUUf div {
  border-color: #eee !important;
}

.PromotionsTable_accordionPanel__q5z3a > div {
  font-size: 16px !important;
}

.PromotionsTable_accordionPanel__q5z3a > div span {
  font-size: 16px !important;
  color: #e4056f !important;
}

.PromotionsTable_accordionTitle__dQusK,
.PromotionsTable_accordionContentItemTitle__1Uij2 {
  font-weight: 500 !important;
  font-size: 17px !important;
  color: #e4056f !important;
  margin-left: 1rem !important;
}

.PromotionsTable_accordionTitle__dQusK {
  text-align: center;
  color: black !important;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.PromotionsTable_accordionEmptyTitle__3ir9f {
  text-align: center;
  color: #8f8f8f !important;
}

.PromotionsTable_accordionContent__w4FJx {
  background: #e6e6e6 !important;
  border-radius: 8px !important;
  padding: 15px 10px !important;
  max-width: 400px;
  width: 108% !important;
  margin: auto;
  margin-left: -0.8rem !important;
}

.PromotionsTable_accordionContentItem__3LZJC {
  /* background-color: rgba(51, 51, 51, 0.226) !important; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.PromotionsTable_accordionContentItemTitle__1Uij2 {
  font-size: 14px !important;
  margin-left: 0 !important;
}

.PromotionsTable_table__psGUO div div div div div table thead tr th {
  border: none !important;
  text-align: center !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #e4056f !important;
  padding: 1rem 0 1.5rem !important;
}

.PromotionsTable_table__psGUO div div div div div table thead tr th::before {
  background: transparent !important;
}

.PromotionsTable_table__psGUO div div div div div table tbody tr td {
  padding: 10px 5px !important;
  border-radius: 0 !important;
  text-align: center !important;
}

.PromotionsTable_table__psGUO div div ul li[class*="ant-pagination-item-active"] {
  border-color: #e4056f !important;
}
.PromotionsTable_table__psGUO div div ul li[class*="ant-pagination-item-active"] a {
  color: #e4056f !important;
}

/*
.table div div div div div table tbody tr:hover td {
  background: transparent !important;
} */

.PromotionsTable_accordionNotificationId__kiDd5 {
  display: block !important;
  color: #e4056f !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

.PromotionsTable_tableName__Ly-hR,
.PromotionsTable_tableEmail__1mqRj {
  font-style: normal !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  color: #333 !important;
}

.PromotionsTable_tableSingleRowValue__2KbIa {
  color: #333;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  text-align: center !important;
  margin-bottom: 0.5em !important;
}

.PromotionsTable_tableStatus__1BSJU {
  color: #fff !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background: #ddd;
  padding: 5px;
  border-radius: 8px;
}

.PromotionsTable_tableCenteredText__tp_dY {
  text-align: center !important;
}

.PromotionsTable_tableEmail__1mqRj {
  font-weight: 700 !important;
}

.PromotionsTable_tableActions__2NUXP {
  display: flex !important;
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  max-width: 150px !important;
  margin: auto !important;
}

.PromotionsTable_tableActions__2NUXP button {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  width: 60px !important;
}

.PromotionsTable_tableActions__2NUXP button img {
  max-width: 40px;
}

.PromotionsTable_tableSwitch__1Yx0k {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (min-width: 450px) {
  .PromotionsTable_accordionContent__w4FJx {
    padding: 20px !important;
    margin: auto !important;
    width: 100% !important;
  }

  .PromotionsTable_accordionContentItem__3LZJC {
    justify-content: flex-start !important;
  }

  .PromotionsTable_accordionTitle__dQusK {
    font-size: 18px !important;
  }

  .PromotionsTable_accordionContentItemTitle__1Uij2 {
    font-size: 15px !important;
    font-weight: 700 !important;
  }
}

@media screen and (min-width: 1400px) {
  .PromotionsTable_table__psGUO {
    display: block !important;
  }

  .PromotionsTable_accordion__1aUUf {
    display: none !important;
  }
}

@media screen and (min-width: 1240px) {
  .PromotionsTable_table__psGUO div div div div div table tbody tr td {
    padding: 10px !important;
  }
}

.CompanyLayout_container__30hEu {
    /* background-color: aqua; */
    padding-bottom: 4rem !important;
    max-width: 100% !important;
    width: 100% !important;
    overflow-x: hidden !important;
}
.containerMain-PLS {
    background-color: #fcc02c;
    width: 100% !important;
    /* position: fixed;   */
    /* <PENDIENTE=FIJE EL NAV BAR> */
  }
  
  .logoIcon-Main-PLS {
    height: auto;
    max-width: 70%;
    margin-left: 5%;
    overflow: hidden;
  }
  
  .leftCol-Main-PLS {
    height: 75px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow-y: hidden !important;
    /* para ocultar el sobre click */
  }
  
  .rightCol-Main-PLS {
    background-color: #e4056f;
    height: 75px !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  
.logoutButtonHeaderCompany{
  background-color: transparent !important;
  border: none !important;
}

.logoutButtonHeaderCompany:hover{
  color: #E4056F !important
}

.headerColSelectCompanyWeb{
  visibility: visible;
}

.headerColSelectCompanyMobile{
  visibility: hidden;
  display: none;
}
  @media (max-width: 1000px) {
    .logoIcon-Main-PLS {
      max-width: 70%;
      overflow: hidden;
    }
  }
  
  @media (max-width: 800px) {
    .logoIcon-Main-PLS {
      max-width: 80%;
      overflow: hidden;
    }
  }
  
  @media (max-width: 500px) {
    .logoIcon-Main-PLS {
      max-width: 90%;
      overflow: hidden;
    }
  }
  
  .p-main-PLS {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    text-overflow: ellipsis;
    color: white;
    padding-top: 15px;
    font-style: italic;
  }
  .bgmain-PLS {
    position: absolute;
    overflow-x: hidden;
  }
  /* TERMINA NAV */
  
.siderLayoutCompany{
    background-color: #E4056F !important;
}

.containerCompanyLayout{
    min-height: 90vh !important;
    padding: 3%;
}

.ant-layout-sider-trigger{
    background-color: #E4056F !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #970048 !important;
    height: 50px !important;
    min-height: 50px !important;
}

.siderMenuCompanyDivider{
    background-color: white !important;
    min-width: 80% !important;
    width: 80% !important;
    margin-left: 10% !important;
    height: 3px !important;
    border-radius: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.headerCompanyWeb{
    color: white;
    font-style: italic;
    font-size: 24px;
    visibility: visible;
    font-family: "Montserrat", sans-serif;
    text-overflow: ellipsis;
    padding-top: 60px;
}

.superAdminSearchCompany{
  text-align: center;
  height: 30px;
  width: 45%;
  border-radius: 10px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8 !important;
  line-height: 1.5715;
  appearance:none;
  margin-left: 30%;
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
}

.headerCompanyMobile{
    visibility: hidden !important;
}

.companyHomeButtonDrawer{
    width: 50px !important;
    height: 50px !important;
    border-radius: 2vw !important;
    background-repeat: no-repeat !important;
    background-position-x: 50% !important;
    background-position-y: 50% !important;
    background-size: 60% !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.companyHomeButtonDrawerLabel{
    font-weight: bold;
    padding-left: 10px !important;
    padding-top: 5px !important;
}

@media(max-width: 576px) {
    .siderLayoutCompany{
        display: none !important;
    }

    .headerCompanyWeb{
        visibility: hidden;
        display: none;
    }

    .headerCompanyMobile{
        visibility: visible !important;
        text-align: end;
        margin-right: 10%;
    }

    .headerColSelectCompanyMobile{
      visibility: visible;
      display: inherit;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .superAdminSearchCompany{
      width: 80%;
      margin-left: 10%;
    }
}

@media screen and (max-width: 767px) {
    .CompanySider_container__oS7EZ {
        display: none;
    }
}

.CompanySider_container__oS7EZ div:last-child {
    height: auto !important;
}

.CompanySider_footerCollapseButton__MQfWZ {
    background: #fff !important;
    border-radius: 100% !important;
    border: none !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px !important;
    margin: 1rem auto 3rem !important;
    outline: none !important;
    width: 35px !important;
}

.CompanySider_footerCollapseButton__MQfWZ img {
    transition: all .5s !important;
    width: 60% !important;
}

.CompanySider_menuItem__247tx {
    margin-top: 1rem !important;
    width: 100% !important;
}

.CompanySider_menuItem__247tx:hover {
    text-decoration: underline !important;
}

.CompanySider_menuItemText__7BFDE {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500;
    margin-top: 1rem !important;
}

.CompanySider_menuItemLogout__1RMWo {
    background-color: rgb(122, 40, 40) !important;
    margin-top: auto !important;
}
.CompanyDrawer_container__11IUJ {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
}

.CompanyDrawer_container__11IUJ>div {
    width: 100% !important;
}

.CompanyDrawer_container__11IUJ>div>div>div {
    background-color: #E4056F !important;
    color: #fff !important;
}

.CompanyDrawer_header__10h2z {
    cursor: pointer;
    text-align: center;
    padding: 5px;
    margin: -.5rem auto 1rem !important;
}

.CompanyDrawer_headerTitle__J6VAa {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    font-size: 19px;
    font-weight: 700;
    margin: 5px auto !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    grid-gap: 10px !important;
    gap: 10px !important;
    text-align: center !important;
    width: 100% !important;
}


.CompanyDrawer_headerSelectCompany__2ZXpW {
    border: none !important;
    background: transparent !important;
    color: #fff !important;
    border-radius: 18px !important;
    box-shadow: none !important;
    outline: none !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    max-width: 600px !important;
    width: 100% !important;
}

.CompanyDrawer_headerSelectCompany__2ZXpW div:first-child {
    border: 1px solid #fff !important;
    background: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
    border-radius: 18px !important;
    padding: 3px 15px !important;
    padding-left: 20px !important;
    outline: none !important;
    height: auto !important;
    font-weight: 500 !important;
    text-align: left !important;
}

.CompanyDrawer_headerSelectCompany__2ZXpW div:first-child.CompanyDrawer_ant-select__3amt7:not(.CompanyDrawer_ant-select-customize-input__wXYcx) .CompanyDrawer_ant-select-selector__ZL9I5 {
    background: transparent !important;
    border: 1px solid #fff !important;
    text-align: left !important;
    border-radius: 18px !important;
}

.CompanyDrawer_headerSelectCompany__2ZXpW span span svg {
    color: #fff !important;
}


.CompanyDrawer_navList__vazRh {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 0 !important;
    margin: 0 !important;
}

.CompanyDrawer_navItem__q7I49 {
    width: 150px !important;
}

.CompanyDrawer_navLink__rni2X {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    transition: all .5s !important;
}

.CompanyDrawer_navLink__rni2X:hover{
    text-decoration: underline !important;
}

.CompanyDrawer_footer__bRiyD {
    text-align: center;
    padding: 10px 0;
    margin-bottom: 1rem !important;
}

.CompanyDrawer_footer__bRiyD button {
    display: flex;
    align-items: center;
    grid-gap: .8rem;
    gap: .8rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

@media screen and (min-width: 768px) {
    .CompanyDrawer_container__11IUJ {
        display: none;
    }
}
.CompanyNavbar_container__1KGh4 {
    height: 75px !important;
    background-color: #fff !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.9) !important;
}

.CompanyNavbar_logoCol__1Cj7X {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-left: 1rem !important;
}

.CompanyNavbar_logoImage__8HrtT {
    cursor: pointer;
    max-height: 45px !important;
}

.CompanyNavbar_content__1ekNj {
    padding-right: 3% !important;
}

.CompanyNavbar_contentTitle__WrlNf {
    display: none !important;
}

.CompanyNavbar_menuIcon__1wKtx {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
}


@media screen and (min-width:768px) {
    .CompanyNavbar_menuIcon__1wKtx {
        display: none !important;
    }

    .CompanyNavbar_contentTitle__WrlNf {
        color: #E4056F;
        font-size: 19px;
        font-weight: 700;
        margin: auto !important;
        display: flex !important;
        grid-gap: 10px !important;
        gap: 10px !important;
    }

    .CompanyNavbar_contentSelectCompany__3l8Tl {
        border: none !important;
        color: #E4056F !important;
        border-radius: 18px !important;
        box-shadow: none !important;
        outline: none !important;
        min-width: 150px !important;
        font-weight: 500 !important;
    }

    .CompanyNavbar_contentSelectCompany__3l8Tl div:first-child {
        border: 1px solid #E4056F !important;
        box-shadow: none !important;
        color: #E4056F !important;
        border-radius: 18px !important;
        padding: 1px 15px !important;
        outline: none !important;
        height: auto !important;
        font-weight: 500 !important;
        text-align: left !important;
        min-width: 150px !important;
    }

    .CompanyNavbar_contentSelectCompany__3l8Tl span span svg {
        color: #E4056F !important;
    }

}
body{
    overflow: unset !important;
    touch-action: unset !important ;
}
.UserLayout_headerCompanyWeb__1p5lA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding: 0 !important;
}

.UserLayout_headerCompanyWebCol__1Tteh {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 50px !important;
}

.UserLayout_userMobileName__1BBs4 {
    margin: 20px 0 10px;
    color: #E4056F;
    font-size: 30px;
    font-weight: 700;
    margin: 30px auto 0;
    text-align: center;
    width: 90%;
}

.UserLayout_userLabel__1j4cB {
    margin: 0;
}

.UserLayout_logoIcon__2iPgE {
    max-width: 300px !important;
    width: 90% !important;
    height: auto !important;
    margin-left: 0 !important;
}

.UserLayout_logoIcon__2iPgE img {
    width: 100% !important;
    max-width: 240px !important;
}

.UserLayout_userIcon__1TilD {
    width: 35px;
    height: 35px;
    padding: 8px;
    margin: auto 1rem auto .5rem;
}

.UserLayout_headerCompanyMobile__1pk7x {
    visibility: visible !important;
}

.UserLayout_headerCompanyMobile__1pk7x span {
    display: block !important;
    margin: auto !important;
}

.UserLayout_containerUserLayout__1TJf3 {
    min-height: calc(100vh - 75px) !important;
}

.UserLayout_userLayoutCol__5yK-C {
    padding-bottom: 4rem;
}

@media screen and (max-width: 767px) {
    .UserLayout_headerCompanyWeb__1p5lA {
        display: none !important;
    }

    .UserLayout_containerUserLayout__1TJf3 {
        align-content: flex-start !important;
    }
}


@media screen and (min-width: 768px) {
    .UserLayout_userMobileName__1BBs4 {
        display: none;
    }

    .UserLayout_headerSearchMobile__wju96 {
        display: none !important;
    }

    .UserLayout_logoIcon__2iPgE img {
        width: 90% !important;
    }

    .UserLayout_userLayoutCol__5yK-C {
        padding: 3rem 1rem !important;
    }

    .UserLayout_userNavigationCol__1YU04 {
        padding: 8rem 0 3rem;
    }
}

@media screen and (min-width: 1024px) {
    .UserLayout_userLayoutCol__5yK-C {
        padding: 3rem 2rem !important;
    }
}

@media screen and (min-width: 1200px) {
    .UserLayout_menuIcon__l8Y4I {
        display: none !important;
    }
}
.UserDrawer_drawer__2sP3F {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
}
.UserDrawer_drawer__2sP3F>div {
    width: 100% !important;
}

.UserDrawer_drawerHeader__2_KqN {
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.UserDrawer_drawerHeaderUserIcon__qXgRO {
    background: #FCC02C;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    height: 35px;
    padding: 8px;
    width: 35px;
}

.UserDrawer_drawerHeaderUserName__2Jwg5 {
    color: #E4056F;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.UserDrawer_drawerFooter__3MqU7 {
    text-align: center;
    padding: 10px 0;
}

.UserDrawer_drawerFooter__3MqU7 button {
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #808080;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

.UserDrawer_panelHeader__UBddh {
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.UserDrawer_panelHeaderImage__iypbp {
    width: 24px;
    height: 24px;
}

.UserDrawer_panelHeaderTitle__3x2f0 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.UserDrawer_panelNav__1T0LP {
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    gap: .5rem;
    padding-left: 2.5rem;
}

.UserDrawer_panelNavLink__9i_3M {
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #E4056F;

}

.UserDrawer_panelHeaderTitle__3x2f0:hover,
.UserDrawer_panelNavLink__9i_3M:hover,
.UserDrawer_drawerFooter__3MqU7:hover {
    text-decoration: underline !important;
}

.UserDrawer_panelNavLink__9i_3M:hover {
    color: #E4056F;
}

@media screen and (min-width: 768px) {
    .UserDrawer_drawer__2sP3F>div {
        max-width: 360px !important;
    }
    .UserDrawer_drawerHeader__2_KqN {
        display: none;
    }
}
.UserDesktopDrawer_drawer__1IEC8 {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
    display: none !important;
}

.UserDesktopDrawer_drawerHeader__1JoAW {
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.UserDesktopDrawer_drawerHeaderUserIcon__3MovO {
    background: #FCC02C;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    height: 35px;
    padding: 8px;
    width: 35px;
}

.UserDesktopDrawer_drawerHeaderUserName__QgIGt {
    color: #E4056F;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.UserDesktopDrawer_drawerFooter__1DN9H {
    text-align: center;
    padding: 10px 0;
}

.UserDesktopDrawer_drawerFooter__1DN9H button {
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #808080;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

.UserDesktopDrawer_panelHeader__1zPgc {
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.UserDesktopDrawer_panelHeaderTitle__HTkOq:hover,
.UserDesktopDrawer_panelNavLink__2VdqU:hover,
.UserDesktopDrawer_drawerFooter__1DN9H:hover {
    text-decoration: underline !important;
}

.UserDesktopDrawer_panelNavLink__2VdqU:hover {
    color: #E4056F;
}

.UserDesktopDrawer_panelHeaderImage__1sEJ9 {
    width: 24px;
    height: 24px;
}

.UserDesktopDrawer_panelHeaderTitle__HTkOq {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.UserDesktopDrawer_panelNav__2u7E3 {
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    gap: .5rem;
    padding-left: 2.5rem;
}

.UserDesktopDrawer_panelNavLink__2VdqU {
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #E4056F;

}

@media screen and (min-width: 768px) {
    .UserDesktopDrawer_drawerHeader__1JoAW {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .UserDesktopDrawer_drawer__1IEC8 {
        display: block !important;
        z-index: 0 !important;
    }
}
.UserHomeScreen_userScreenContainer__3GOBY {
    background-color: blueviolet;
    min-height: 100vh;
    width: 100%;
}

.UserHomeScreen_userScreenRow__YB0-d {
    max-width: 800px;
}

.UserHomeScreen_userScreenContainer__3GOBY * {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
}

.UserHomeScreen_userScreenCol__20OQc {
    margin-top: 1.5rem;
    max-width: 850px !important;
}


.UserHomeScreen_userScreenHeaderTitle__ElvNX {
    color: #E4056F;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.UserHomeScreen_userScreenHeaderTitle__ElvNX span:last-child {
    margin-left: 8px;
}

.UserHomeScreen_userScreenHeaderCol__DLX4n {
    max-width: 400px !important;
}

.UserHomeScreen_actionButtonsCol__3vJeH {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    grid-gap: 1rem;
    gap: 1rem;
    position: fixed !important;
    bottom: 2rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.UserHomeScreen_actionButton__1Uq-X {
    border: none !important;
    outline: none !important;
    background: transparent !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    height: 50px !important;
    width: 50px !important;
}

.UserHomeScreen_actionButtonHidden__AAnfb{
    display: none !important;
}

.UserHomeScreen_actionButtonText__2Kpv9 {
    display: none !important;
}

.UserHomeScreen_actionButtonIcon__WIqEd {
    width: 50px !important;
    height: 50px !important;
}

.UserHomeScreen_actionButtonSave__GLnHX {
    order: -1 !important;
}

.UserHomeScreen_actionButtonSave__GLnHX .UserHomeScreen_actionButtonIcon__WIqEd {
    opacity: .5 !important;
    transition: all .3s !important;
}

.UserHomeScreen_actionButtonSaveHasChanged__1BiX5 .UserHomeScreen_actionButtonIcon__WIqEd {
    opacity: 1 !important;
}

.UserHomeScreen_dataContainer__x7oAi {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
    width: 100%;
}

.UserHomeScreen_dataContainerItem__1dapS {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.UserHomeScreen_dataContainerItem__1dapS label {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.UserHomeScreen_dataContainerItemInput__kYUGV {
    color: #333 !important;
    background: #E6E6E6 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    transition: all .3s;
}

.UserHomeScreen_dataContainerItemInputGender__2OCmp {
    border: 1px solid #E6E6E6 !important;
    background: #E6E6E6 !important;
    border-radius: 16px !important;
    height: 45px !important;
    padding: 0 12px !important;
    transition: all .3s;
    width: 100% !important;
}

.UserHomeScreen_dataContainerItemInputGender__2OCmp div {
    background: #E6E6E6 !important;
    height: 40px !important;
    border: none !important;
    border-radius: 0 !important;
    margin-top: 2px !important;
    padding: 12px 0 !important;
    transition: all .3s;
    width: 100% !important;
}

.UserHomeScreen_dataContainerItemInputGender__2OCmp div span:last-child {
    line-height: 15px !important;
    color: #333 !important;
}

.UserHomeScreen_dataContainerItemInputPassword__I_Ght {
    background: #E6E6E6 !important;
    border-radius: 16px !important;
    padding: 0 12px !important;
}

.UserHomeScreen_dataContainerItemInputPassword__I_Ght input {
    color: #333 !important;
    font-size: 15px !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    text-align: left !important;
    width: 95% !important;
}

.UserHomeScreen_dataContainerItemInputPassword__I_Ght.UserHomeScreen_dataContainerItemInputEditing__34smQ input {
    cursor: pointer !important;
}

.UserHomeScreen_dataContainerItemInputEditing__34smQ,
.UserHomeScreen_dataContainerItemInputEditing__34smQ div {
    background-color: #fff !important;
}




@media screen and (min-width: 768px) {
    .UserHomeScreen_actionButtonsCol__3vJeH {
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        margin-left: auto !important;
        max-width: 220px !important;
        position: static !important;
        bottom: unset !important;
        right: unset !important;
    }

    .UserHomeScreen_actionButton__1Uq-X {
        border: 1px solid #E4056F !important;
        border-radius: 8px !important;
        height: 40px !important;
        width: 100px !important;
    }

    .UserHomeScreen_actionButtonText__2Kpv9 {
        display: block !important;
        font-size: 15px !important;
        font-weight: 700 !important;
    }

    .UserHomeScreen_actionButtonIcon__WIqEd {
        display: none !important;
    }

    .UserHomeScreen_actionButtonEdit__2U-KX,
    .UserHomeScreen_actionButtonEditPassword__1_Rjo {
        color: #E4056F !important;
        border-color: #E4056F !important;
    }

    .UserHomeScreen_actionButtonEditPassword__1_Rjo {
        margin-top: auto;
        border-radius: 16px !important;
        height: 47px !important;
        width: 200px !important;
    }

    .UserHomeScreen_actionButtonSave__GLnHX {
        background: rgba(255, 25, 136, 0.678) !important;
        color: #fff !important;
        cursor: not-allowed !important;
        margin-left: .5rem !important;
        order: 1 !important;
    }

    .UserHomeScreen_actionButtonSaveHasChanged__1BiX5 {
        background: #E4056F !important;
        cursor: pointer !important;
    }

    .UserHomeScreen_actionButtonSaveHasChanged__1BiX5:hover {
        background: #B20457 !important;
    }

    .UserHomeScreen_actionButtonCancel__LlcTx {
        border-color: #808080 !important;
        color: #808080 !important;
    }

    .UserHomeScreen_actionButtonCancel__LlcTx:hover {
        border-color: #333 !important;
        color: #333 !important;
    }



    .UserHomeScreen_dataContainer__x7oAi {
        flex-direction: row;
    }

    .UserHomeScreen_dataContainerItem__1dapS {
        width: auto;
    }

    .UserHomeScreen_dataContainerItemInputPassword__I_Ght input {
        width: 8rem !important;
    }

    .UserHomeScreen_dataContainerItemInputGender__2OCmp {
        width: 10rem !important;
    }
}

@media screen and (min-width: 875px) {
    .UserHomeScreen_actionButtonsCol__3vJeH {
        max-width: 250px !important;
    }

    .UserHomeScreen_actionButton__1Uq-X {
        width: 120px !important;
    }

    .UserHomeScreen_actionButtonSave__GLnHX {
        margin-left: 1.5rem !important;
    }
}
.UserScreenSection_UserScreenContent__3Ydqd {
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: auto;
    max-width: 500px;
    padding: 24px 32px;
    width: 90%;
    position: relative !important;
    z-index: 10 !important;
}

.UserScreenSection_UserScreenContentTitle__2tua1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
    .UserScreenSection_UserScreenContent__3Ydqd {
        margin: 0;
        max-width: 1200px;
        width: 100%;
    }   
}
.UpdatePasswordModal_container__3HM6e {
    max-width: 30rem !important;
    width: 85% !important;
}

.UpdatePasswordModal_header__3x-b6 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.UpdatePasswordModal_headerTitle__127sE {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.UpdatePasswordModal_headerCloseIcon__2LdKQ {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.UpdatePasswordModal_headerCloseIcon__2LdKQ svg {
    height: 22px;
    width: 22px;
}

.UpdatePasswordModal_form__1DmZw {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 0 1rem 2rem !important;
}

.UpdatePasswordModal_formItem__c-som {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 20rem;
    width: 90%;
}

.UpdatePasswordModal_formItemLabel__11sTo {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.UpdatePasswordModal_formItemInput__1p09E {
    color: #333 !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.UpdatePasswordModal_formItemInput__1p09E[disabled] {
    background-color: #e6e6e6 !important;
    cursor: not-allowed !important;
}

.UpdatePasswordModal_formItemInput__1p09E:focus {
    border-color: #8a8a8a !important;
}

.UpdatePasswordModal_formItemInput__1p09E::placeholder {
    color: #808080 !important;
}

.UpdatePasswordModal_formItemValidationList__2QNe1 {
    margin: 13px auto 5px !important;
    padding: 0;
    list-style: none;
    width: 100%;
}

.UpdatePasswordModal_formItemValidationListItem__1RCQs {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 12px;
    margin-bottom: 8px;
    width: 100%;
}

.UpdatePasswordModal_formItemValidationListItem__1RCQs:last-of-type {
    margin-bottom: 0;
}

.UpdatePasswordModal_pending__2VLa4 {
    color: #B3B3B3;
}

.UpdatePasswordModal_success__1lVQq {
    color: #49BA35;
}

.UpdatePasswordModal_failed__3jfWh {
    color: #FF3247;
}

.UpdatePasswordModal_formButton__2T_oC {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    max-width: 20rem;
    padding: 10px 0 !important;
    width: 90% !important;
}

.UpdatePasswordModal_formButton__2T_oC span {
    display: block !important;
    line-height: 0 !important;
}

.UpdatePasswordModal_formButton__2T_oC:hover {
    background: #E5AF28 !important;
}

@media screen and (min-width: 500px) {
    .UpdatePasswordModal_headerTitle__127sE {
        font-size: 27px;
    }

    .UpdatePasswordModal_headerCloseIcon__2LdKQ {
        right: 2rem !important;
    }

    .UpdatePasswordModal_formButton__2T_oC {
        width: 130px !important;
    }
}
.NotificationsManagement_container__25zbE {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 1rem 4rem;
  gap: 1rem 4rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.NotificationsManagement_title__1xdir {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
}

.NotificationsManagement_switch__2Duvn {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

@media (max-width: 768px) {
  .NotificationsManagement_container__25zbE {
    width: 90%;
    margin: 2rem auto;
    max-width: 500px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .NotificationsManagement_title__1xdir {
    font-size: 1rem;
  }
}

.UserPartnerScreen_userScreenContainer__29Kyg {
    background-color: blueviolet;
    min-height: 100vh;
    width: 100%;
}

.UserPartnerScreen_userScreenRow__FTopA {
    max-width: 800px;
}

.UserPartnerScreen_userScreenContainer__29Kyg * {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
}

.UserPartnerScreen_userScreenCol__10sG7 {
    margin-top: 1.5rem;
    max-width: 850px !important;
}


.UserPartnerScreen_userScreenHeaderTitle__1gfjB {
    color: #E4056F;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.UserPartnerScreen_userScreenHeaderTitle__1gfjB span:last-child {
    margin-left: 8px;
}

.UserPartnerScreen_userScreenHeaderCol__3GcKf {
    max-width: 400px !important;
}

.UserPartnerScreen_actionButtonsCol__2EGJm {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    grid-gap: 1rem;
    gap: 1rem;
    position: fixed !important;
    bottom: 2rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.UserPartnerScreen_actionButton__3KH8h {
    border: none !important;
    outline: none !important;
    background: transparent !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    height: 50px !important;
    width: 45px !important;
}

.UserPartnerScreen_actionButtonHidden__1Nouq{
    display: none !important;
}

.UserPartnerScreen_actionButtonText__31d4I {
    display: none !important;
}

.UserPartnerScreen_actionButtonIcon__kthIE {
    width: 50px !important;
    height: 50px !important;
}

.UserPartnerScreen_actionButtonSave__2hBgo {
    order: -1 !important;
}

.UserPartnerScreen_actionButtonSave__2hBgo .UserPartnerScreen_actionButtonIcon__kthIE {
    transition: all .3s !important;
}


.UserPartnerScreen_companies__3pqws {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 5px;
    overflow-x: auto;
    width: auto;
}

.UserPartnerScreen_companies__3pqws::-webkit-scrollbar {
    background-color: #fff;
    width: 1px;
}

.UserPartnerScreen_companies__3pqws::-webkit-scrollbar-thumb {
    background-color: rgba(204, 204, 204, 0.3);
    border-radius: 16px;
    border: 4px solid #fff;
}

.UserPartnerScreen_companies__3pqws::-webkit-scrollbar-track {
    background-color: #fff;
}

.UserPartnerScreen_companiesEmpty__3LksO {
    color: #ccc;
    font-weight: 700;
    font-size: 15px;
    margin: 1rem 0;
}

.UserPartnerScreen_company__2ZvPv {
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: -moz-fit-content;
    min-width: -ms-fit-content;
    min-width: -webkit-fill-available;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    -ms-max-width: -ms-fit-content;
    -webkit-max-width: -webkit-fit-content;
    -o-max-width: fit-content;
    -moz-max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 1rem 1.5rem;
    position: relative;
    transition: all .3s;
}

.UserPartnerScreen_companyDeleteIcon__fAdxS {
    position: absolute;
    right: 2px;
    top: 0;
    width: 17px;
    height: 17px;
}

.UserPartnerScreen_companySelected__BHjim {
    border-color: #ccc;
    background: #80808033;
}

.UserPartnerScreen_companyLogo__1v4if {
    height: 50px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (min-width: 768px) {
    .UserPartnerScreen_actionButtonsCol__2EGJm {
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        margin-left: auto !important;
        max-width: 220px !important;
        position: static !important;
        bottom: unset !important;
        right: unset !important;
    }

    .UserPartnerScreen_actionButton__3KH8h {
        border: 1px solid #E4056F !important;
        border-radius: 8px !important;
        height: 40px !important;
        width: 100px !important;
    }

    .UserPartnerScreen_actionButtonAdd__AbDE5 {
        background: #FCC02C !important;
        border: 1px solid #FCC02C !important;
        margin-left: .5rem !important;
    }

    .UserPartnerScreen_actionButtonAdd__AbDE5:hover {
        background: #E5AF28 !important;
    }

    .UserPartnerScreen_actionButtonDelete__1RFMA {
        background: #E4056F !important;
    }

    .UserPartnerScreen_actionButtonDelete__1RFMA:hover {
        background: #B20457 !important;
    }

    .UserPartnerScreen_actionButtonText__31d4I {
        display: block !important;
        font-size: 15px !important;
        font-weight: 700 !important;
    }

    .UserPartnerScreen_actionButtonIcon__kthIE {
        display: none !important;
    }

    .UserPartnerScreen_actionButtonEdit__2XxYa,
    .UserPartnerScreen_actionButtonEditPassword__38H5a {
        color: #E4056F !important;
        border-color: #E4056F !important;
    }

    .UserPartnerScreen_actionButtonEditPassword__38H5a {
        margin-top: auto;
        border-radius: 16px !important;
        height: 47px !important;
        width: 200px !important;
    }

    .UserPartnerScreen_actionButtonSave__2hBgo {
        background: rgba(255, 25, 136, 0.678) !important;
        color: #fff !important;
        cursor: not-allowed !important;
        margin-left: .5rem !important;
        order: 1 !important;
    }

    .UserPartnerScreen_actionButtonSaveHasChanged__1p1Pi {
        background: #E4056F !important;
        cursor: pointer !important;
    }

    .UserPartnerScreen_actionButtonSaveHasChanged__1p1Pi:hover {
        background: #B20457 !important;
    }

    .UserPartnerScreen_actionButtonCancel__1OTPj {
        border-color: #808080 !important;
        color: #808080 !important;
    }

    .UserPartnerScreen_actionButtonCancel__1OTPj:hover {
        border-color: #333 !important;
        color: #333 !important;
    }
}

@media screen and (min-width: 875px) {
    .UserPartnerScreen_actionButtonsCol__2EGJm {
        max-width: 250px !important;
    }

    .UserPartnerScreen_actionButton__3KH8h {
        width: 120px !important;
    }

    .UserPartnerScreen_actionButtonSave__2hBgo {
        margin-left: 1.5rem !important;
    }

    .UserPartnerScreen_actionButtonAdd__AbDE5 {
        margin-left: 1.5rem !important;
    }
}
.AddPartnersModal_container__dWmRt {
    max-width: 33rem !important;
    width: 85% !important;
}

.AddPartnersModal_header__19tUj {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.AddPartnersModal_headerTitle__Zx0R0 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.AddPartnersModal_headerCloseIcon__1qoAa {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.AddPartnersModal_headerCloseIcon__1qoAa svg {
    height: 22px;
    width: 22px;
}

.AddPartnersModal_content__3QJ3n {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 1rem !important;
}

.AddPartnersModal_contentItem__1Midr {
    color: #333333;
    font-size: 13px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 90%;
}

.AddPartnersModal_contentItemTitle__2GGfa {
    font-weight: 600;
}

.AddPartnersModal_buttons__1211_ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1rem 1.5rem;
}

.AddPartnersModal_button__2Rmw6 {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    max-width: 20rem;
    padding: 10px 0 !important;
    width: 90% !important;
}

.AddPartnersModal_button__2Rmw6:last-child {
    background: #E4056F !important;
}

.AddPartnersModal_button__2Rmw6:hover {
    background: #E5AF28 !important;
}

.AddPartnersModal_button__2Rmw6:last-child:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 500px) {
    .AddPartnersModal_headerTitle__Zx0R0 {
        font-size: 27px;
    }

    .AddPartnersModal_headerCloseIcon__1qoAa {
        right: 2rem !important;
    }

    .AddPartnersModal_contentItem__1Midr {
        font-size: 15px;
    }

    .AddPartnersModal_buttons__1211_ {
        padding: 1.5rem 1rem 2rem;
    }

    .AddPartnersModal_button__2Rmw6 {
        max-width: 200px !important;
    }
}
.AddCompanyModal_container__2tSdY {
    max-width: 33rem !important;
    width: 85% !important;
}

.AddCompanyModal_header__3DG7V {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.AddCompanyModal_headerTitle__1rCAp {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.AddCompanyModal_headerCloseIcon__3UoU9 {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.AddCompanyModal_headerCloseIcon__3UoU9 svg {
    height: 22px;
    width: 22px;
}

.AddCompanyModal_form__7yXS_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 0 1rem 2rem !important;
}

.AddCompanyModal_formItem__2kY9Z {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 90%;
}

.AddCompanyModal_formItemLabel__2auQD {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.AddCompanyModal_formItemInput__1Rv29 {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.AddCompanyModal_formItemInputSelect__G3gfs div {
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 16px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.AddCompanyModal_formItemInput__1Rv29[disabled] {
    cursor: not-allowed !important;
}

.AddCompanyModal_formItemInput__1Rv29:focus {
    border-color: #8a8a8a !important;
}

.AddCompanyModal_formItemInput__1Rv29::placeholder {
    color: #808080 !important;
}

.AddCompanyModal_formButton__1ostr {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 90% !important;
}

.AddCompanyModal_formButton__1ostr span {
    display: block !important;
    line-height: 0 !important;
}

.AddCompanyModal_formButton__1ostr:hover {
    background: #E5AF28 !important;
}

@media screen and (min-width: 500px) {
    .AddCompanyModal_headerTitle__1rCAp {
        font-size: 27px;
    }

    .AddCompanyModal_headerCloseIcon__3UoU9 {
        right: 2rem !important;
    }

    .AddCompanyModal_formButton__1ostr {
        max-width: 140px !important;
    }
}
.AddAlliesModal_container__1UUBi {
    max-width: 33rem !important;
    text-align: center !important;
    width: 85% !important;
}

.AddAlliesModal_header__1_-u4 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.AddAlliesModal_headerTitle__25ENu {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.AddAlliesModal_headerCloseIcon__OVr6Q {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.AddAlliesModal_headerCloseIcon__OVr6Q svg {
    height: 22px;
    width: 22px;
}

.AddAlliesModal_searchbar__36dF3 {
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 18rem;
    width: 80%;
    margin: 1rem auto;
}

.AddAlliesModal_searchbarIcon__30uuH {
    position: absolute;
    left: 1rem;
    top: 25%;
}

.AddAlliesModal_searchbarInput__2VIiT {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px 12px 12px 50px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.AddAlliesModal_searchbarInput__2VIiT:focus {
    border-color: #8a8a8a !important;
}

.AddAlliesModal_searchbarInput__2VIiT::placeholder {
    color: #808080 !important;
}


.AddAlliesModal_infiniteScroll__3ES8w {
    overflow-x: hidden;
    max-width: 25rem;
    width: 90%;
    margin: 2rem auto 1rem;
}

.AddAlliesModal_list__3qXfF {
    display: flex;
    justify-content: -ms-space-evenly;
    justify-content: space-evenly;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 !important;
}

.AddAlliesModal_listSpin__33jjM {
    margin: auto !important;
}

.AddAlliesModal_listError__2wKjX {
    color: #808080;
    font-weight: 700;
    font-size: 15px;
}

.AddAlliesModal_listItem__3b0Nx {
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    -ms-max-width: -ms-fit-content;
    -webkit-max-width: -webkit-fit-content;
    -o-max-width: fit-content;
    -moz-max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 1rem;
    border: 1px solid #eee;
    transition: all .3s;
}

.AddAlliesModal_listItemSelected__1wfSN {
    border-color: #ccc;
    background: #80808033;
}

.AddAlliesModal_listItemLogo__2pfyM {
    height: 45px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}


.AddAlliesModal_button__2tRxZ {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 80% !important;
    margin: 1rem auto 2rem !important;
}

.AddAlliesModal_button__2tRxZ span {
    display: block !important;
    line-height: 0 !important;
}

.AddAlliesModal_button__2tRxZ:hover {
    background: #E5AF28 !important;
}

@media screen and (min-width: 500px) {
    .AddAlliesModal_headerTitle__25ENu {
        font-size: 27px;
    }

    .AddAlliesModal_headerCloseIcon__OVr6Q {
        right: 2rem !important;
    }

    .AddAlliesModal_button__2tRxZ {
        max-width: 140px !important;
    }
}
.text-userScreen {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 0, 0, 0.164);
}

.SearchWeb-AddAlly {
  display: flex;
  justify-content: center;
  margin: 20px 0px 30px 0px;
}

.searchInput-AddAlly {
  border-radius: 50px !important;
  border: 2px solid black !important;
  height: 50px !important;
  max-width: 20rem !important;
  text-align: center !important;
  width: 90% !important;
}


.a-userAddAllyScreen {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  text-align: center;
  text-decoration: underline;
}

.a-userAddAllyScreen:hover {
  color: black;
}

.userAddAllyScreen-ButtonHome {
  color: #ffffff !important;
  font-size: 24px !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
  background-color: #e4056f !important;
  width: 200px !important;
  height: 45px !important;
}

.userAddAllyScreen-ButtonHome:hover {
  background-color: #ff1787 !important;
  /* color: #3f3f3f !important; */
}

.userAddAllyScreen-ButtonHome:active {
  background-color: #cf0869 !important;
}

.allies-container {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -ms-justify-content: space-evenly;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 20px auto;
  max-width: 60rem;
  padding: 1.5rem 1rem;
  width: 90%;
}

.allied {
  border: 1px solid rgba(205, 205, 205, 0.5);
  border-radius: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  max-width: 13rem;
  width: 100%;
}

.allied--selected {
  background: rgba(180, 180, 180, 0.5) !important;
}

.allied:hover {
  background: rgba(236, 236, 236, 0.4);
}

.allied__logo {
  width: 80%;
  height: 100%;
  -ms-object-fit: cover;
  -webkit-object-fit: cover;
  object-fit: cover;
  -ms-object-position: center;
  -webkit-object-position: center;
  object-position: center;
}
.center-addCompany-title{
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 40px 0px 0px 0px;
}
.center-UserAddCompanyImg {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 50px 0px 30px 0px;
}
.UserAddCompanyImg {
  width: 10% !important;
  height: 50% !important;
  min-width: 12.5rem;
}

.addCompanyTitle{
  font-weight: bold;
}

.error-modal {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-modal__description {
    font-size: 20px;
    width: 95%;
    text-align: center;
    margin: 1.5rem auto;
    font-weight: bold;
}

.error-modal__buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    width: 100%;
}

.error-modal__close-button {
    display: none !important;
}

.error-modal__button {
    background-color: transparent;
    border-radius: 25px;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding: .3rem 1.8rem;
    text-decoration: underline;
    transition: all .5s;
}

.error-modal__button:hover {
    background: #E4056F;
    text-decoration: none;
    color: #fff;
}
.succes-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.succes-modal__description {
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    margin: 1rem auto;
    font-weight: bold;
}

.succes-modal__close-button svg {
    height: 2rem;
    margin-top: 1rem;
    width: 2rem;
}
.ForgotPasswordScreen_container__2v70k {
    background: #FCC02C;
    background-image: url(/static/media/trans-pattern.48a145c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 1rem 3rem;

}
.ForgotPasswordHeader_header__jciKF {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.ForgotPasswordHeader_headerTitle__fC9wo {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.ForgotPasswordHeader_headerIcon__3pPMs {
    margin-right: 7px;
    height: 50px;
    width: 50px;
}
.ForgotPasswordForm_container__2G4w- {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.ForgotPasswordForm_form__2kh04 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 1rem 2rem !important;
}

.ForgotPasswordForm_formItem__SW5Gp {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.ForgotPasswordForm_formItemLabel__3Od43 {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.ForgotPasswordForm_formItemInput__js_3G {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.ForgotPasswordForm_formItem__SW5Gp * {
    background-color: #fff !important;
}

.ForgotPasswordForm_formItemInput__js_3G:focus {
    border-color: #8a8a8a !important;
}

.ForgotPasswordForm_formItemInput__js_3G::placeholder {
    color: #ccc !important;
}

.ForgotPasswordForm_formButton__3aX05 {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
    height: 40px !important;
    width: 100% !important;
}

.ForgotPasswordForm_formButton__3aX05 span {
    
    line-height: 0 !important;
}

.ForgotPasswordForm_formButton__3aX05:hover {
    background: #B20457 !important;
}

.ForgotPasswordForm_footer__QfPme {
    width: 100%;
}

.ForgotPasswordForm_footerDescription__2MOZa {
    font-weight: 600;
    font-size: 13px;
    color: #808080;
}

.ForgotPasswordForm_footerDescription__2MOZa a {
    color: #808080;
}

.ForgotPasswordForm_footerDescription__2MOZa a:hover {
    color: #E4056F;
    text-decoration: underline;
}


.ForgotPasswordForm_forgotPasswordText__PkSFO a {
    color: #808080;
}

.ForgotPasswordForm_forgotPasswordText__PkSFO a:hover {
    color: #E4056F;
    text-decoration: underline;
}

.ForgotPasswordForm_formTitle__1Qy8P{
    color: #E4056F;
    font-weight: 600;
}

@media screen and (min-width: 770px) {

    .ForgotPasswordForm_formButton__3aX05 {
        max-width: 180px !important;
    }

    .ForgotPasswordForm_footerDescription__2MOZa {
        font-size: 15px;
    }
}
.ChangePasswordScreen_container__9CpPP {
    background: #FCC02C;
    background-image: url(/static/media/trans-pattern.48a145c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 1rem 3rem;

}
.ChangePasswordHeader_header__1FSr_ {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.ChangePasswordHeader_headerTitle__Sqmr1 {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.ChangePasswordHeader_headerIcon___YRvE {
    margin-right: 7px;
    height: 50px;
    width: 50px;
}
.ChangePasswordForm_container__pm_wX {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.ChangePasswordForm_form__3DtRF {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 1rem 2rem !important;
}

.ChangePasswordForm_formItem__KXCFh {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.ChangePasswordForm_formItemLabel__1oBxQ {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.ChangePasswordForm_formItemInput__1TBJn {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.ChangePasswordForm_formItem__KXCFh * {
    background-color: #fff !important;
}

.ChangePasswordForm_formItemInput__1TBJn:focus {
    border-color: #8a8a8a !important;
}

.ChangePasswordForm_formItemInput__1TBJn::placeholder {
    color: #ccc !important;
}

.ChangePasswordForm_formButton__1LP_c {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.ChangePasswordForm_formButton__1LP_c span {
    display: block !important;
    line-height: 0 !important;
}

.ChangePasswordForm_formButton__1LP_c:hover {
    background: #B20457 !important;
}

.ChangePasswordForm_footer__2Zp4Z {
    width: 100%;
}

.ChangePasswordForm_footerDescription__1vTud {
    font-weight: 600;
    font-size: 13px;
    color: #808080;
}

.ChangePasswordForm_footerDescription__1vTud a {
    color: #808080;
}

.ChangePasswordForm_footerDescription__1vTud a:hover {
    color: #E4056F;
    text-decoration: underline;
}


.ChangePasswordForm_forgotPasswordText__2WL6Q a {
    color: #808080;
}

.ChangePasswordForm_forgotPasswordText__2WL6Q a:hover {
    color: #E4056F;
    text-decoration: underline;
}

.ChangePasswordForm_formTitle__2-aQc{
    color: #E4056F;
    font-weight: 600;
}

@media screen and (min-width: 770px) {

    .ChangePasswordForm_formButton__1LP_c {
        max-width: 180px !important;
    }

    .ChangePasswordForm_footerDescription__1vTud {
        font-size: 15px;
    }
}

.MyCompanyScreen_title__3kEDb {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 1.5rem 2.5rem !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MyCompanyScreen_subtitle__bG0Dh {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
}

.MyCompanyScreen_statsVisualizations__1qB2Q span {
    display: inline-block;
    margin-right: 5px;
    font-size: 33px;
    font-weight: 700;
}

.MyCompanyScreen_stats__1DdZG {
    padding: 1.5rem;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    align-items: center;
}

.MyCompanyScreen_colCenter__1-a5u {
 text-align: center;
 display: flex !important;
 flex-direction: column !important;
}

.MyCompanyScreen_colLabel__2CNTt{
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
}

.MyCompanyScreen_logo__3pmzd{
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
}

.MyCompanyScreen_dataContainerItemInput__3jD12,
.MyCompanyScreen_formItemInputSelect___xP1s {
    color: #333 !important;
    background: #E6E6E6 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    transition: all .3s;
    width: 100% !important;
}

.MyCompanyScreen_dataContainerItemInputEditing__3LN6T,
.MyCompanyScreen_formItemInputSelectEditing__3NU2x,
.MyCompanyScreen_dataContainerItemInputEditing__3LN6T div {
    background-color: #fff !important;
}

.MyCompanyScreen_button__1pIrw {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
    position: fixed !important;
    bottom: 3rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.MyCompanyScreen_buttonCancel__A2zjk {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
    position: fixed !important;
    bottom: 8rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.MyCompanyScreen_buttonChangeLogo__3kLs0{
    background: #FCC02C !important;
    border: none !important;
    border-radius: 24px !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 8px 32px !important;
    margin-top: 20px !important;
    transition: all .5s !important;
    position: static !important;
    width: 100% !important;
    color: white !important;
    font-weight: bold !important;
}

.MyCompanyScreen_button__1pIrw img {
    width: 100% !important;
}

.MyCompanyScreen_button__1pIrw span {
    display: none !important;
}

.MyCompanyScreen_buttonCancel__A2zjk span {
    display: none !important;
}


.MyCompanyScreen_formItemInputSelect___xP1s {
    height: unset !important;
    padding: 0 !important;
}

.MyCompanyScreen_formItemInputSelect___xP1s>div {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-radius: 20px !important;
    border: none !important;
    border-color: transparent !important;
    background: transparent !important;
    text-align: left !important;
    margin: auto !important;
    height: 48px !important;
    outline: none !important;
    box-shadow: none !important;
    width: 95% !important;
}

@media (max-width: 769px) {
    .MyCompanyScreen_container__37wUQ{
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
        padding-bottom: 3rem;
        margin-bottom: 4rem;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }
}


@media screen and (min-width:768px) {
    .MyCompanyScreen_container__37wUQ{
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
        padding-bottom: 3rem;
        margin-bottom: 4rem;
    }

    .MyCompanyScreen_statsVisualizations__1qB2Q {
        text-align: left;
    }

    .MyCompanyScreen_dataContainerItemInput__3jD12,
    .MyCompanyScreen_formItemInputSelect___xP1s {
        width: 90% !important;
    }

    .MyCompanyScreen_button__1pIrw {
        background: #E4056F !important;
        border: none !important;
        border-radius: 24px !important;
        cursor: pointer !important;
        outline: none !important;
        padding: 8px 32px !important;
        transition: all .5s !important;
        position: static !important;
        width: 140px !important;
        height: 40px !important;
    }

    .MyCompanyScreen_buttonCancel__A2zjk{
        background: transparent !important;
        border: solid black 0.5px !important;
        border-radius: 24px !important;
        cursor: pointer !important;
        outline: none !important;
        padding: 8px 32px !important;
        transition: all .5s !important;
        position: static !important;
        width: 140px !important;
        height: 40px !important;
        margin-right: 30px !important;
    }

    .MyCompanyScreen_button__1pIrw:hover {
        background: #B20457 !important;
    }

    .MyCompanyScreen_buttonCancel__A2zjk:hover {
        color: black !important;
    }

    .MyCompanyScreen_buttonCancel__A2zjk img {
        display: none !important;
    }

    .MyCompanyScreen_button__1pIrw img {
        display: none !important;
    }

    .MyCompanyScreen_button__1pIrw span {
        color: #fff !important;
        display: block !important;
        font-weight: 700 !important;
    }

    .MyCompanyScreen_buttonCancel__A2zjk span {
        display: block !important;
        font-weight: 700 !important;
    }
}

@media screen and (min-width:1024px) {
    .MyCompanyScreen_container__37wUQ{
        padding-bottom: 5rem;
    }
    .MyCompanyScreen_title__3kEDb {
        padding: 1.5rem 5rem;
    }

    .MyCompanyScreen_statsVisualizations__1qB2Q {
        margin: 1rem auto;
    }

    .MyCompanyScreen_statsVisualizations__1qB2Q {
        padding: 0 5rem;
    }
}

@media screen and (min-width:1200px) {
    .MyCompanyScreen_stats__1DdZG {
        padding: 0 3rem;
    }
}
