.container {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
}

.container>div {
    width: 100% !important;
}

.container>div>div>div {
    background-color: #E4056F !important;
    color: #fff !important;
}

.header {
    cursor: pointer;
    text-align: center;
    padding: 5px;
    margin: -.5rem auto 1rem !important;
}

.headerTitle {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    font-size: 19px;
    font-weight: 700;
    margin: 5px auto !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    gap: 10px !important;
    text-align: center !important;
    width: 100% !important;
}


.headerSelectCompany {
    border: none !important;
    background: transparent !important;
    color: #fff !important;
    border-radius: 18px !important;
    box-shadow: none !important;
    outline: none !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    max-width: 600px !important;
    width: 100% !important;
}

.headerSelectCompany div:first-child {
    border: 1px solid #fff !important;
    background: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
    border-radius: 18px !important;
    padding: 3px 15px !important;
    padding-left: 20px !important;
    outline: none !important;
    height: auto !important;
    font-weight: 500 !important;
    text-align: left !important;
}

.headerSelectCompany div:first-child.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    border: 1px solid #fff !important;
    text-align: left !important;
    border-radius: 18px !important;
}

.headerSelectCompany span span svg {
    color: #fff !important;
}


.navList {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 0 !important;
    margin: 0 !important;
}

.navItem {
    width: 150px !important;
}

.navLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem !important;
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    transition: all .5s !important;
}

.navLink:hover{
    text-decoration: underline !important;
}

.footer {
    text-align: center;
    padding: 10px 0;
    margin-bottom: 1rem !important;
}

.footer button {
    display: flex;
    align-items: center;
    gap: .8rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

@media screen and (min-width: 768px) {
    .container {
        display: none;
    }
}