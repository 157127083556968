.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: 160px !important;
}

.switch {
    position: relative;
    transition: all .5s;
    width: 45px;
}

.switchBar {
    display: block;
    border-radius: 32px;
    height: 20px !important;
    width: 45px !important;
}

.switchBarActive {
    background: #E4056F40;
}

.switchBarDesactive {
    background: #B3B3B3;
}

.switchBall {
    display: block;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: -2px;
    transition: all .5s;
}

.switchBallActive {
    background: #E4056F;
    right: 0;
    left: unset;
}

.text {
    font-weight: 400;
    font-size: 15px;
    font-style: normal !important;
    color: #808080;
}