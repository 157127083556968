.nav {
    text-align: center;
}

.navList {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 !important;
    margin: 0 !important;
}

.navItem {
    max-width: 400px;
    width: 95%;
}

.navLink {
    box-shadow: 0px 4px 10px 0px #0000001A;
    background-color: #fff;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    transition: all .5s;
    width: 100%;
}

.navLink:hover {
    background-color: rgba(238, 238, 238, 0.5);
}

.navLink:active {
    background-color: rgba(238, 238, 238, 0.8);
}

.navLinkIcon {
    max-height: 45px;
}

.navLinkText {
    color: #E4056F;
    font-size: 16px;
    font-weight: 600;
}

@media screen and (min-width: 500px) {
    .navItem {
        width: 46%;
    }
}

@media screen and (min-width: 768px) {
    .navList {
        justify-content: flex-start;
        gap: 30px;
    }

    .navItem {
        width: 45%;
        max-width: 350px;
    }
}

@media screen and (min-width: 1024px) {
    .navItem {
        width: 30%;
    }

    .navLink {
        padding: 25px;
    }
}