.succes-modal {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.succes-modal__description {
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    margin: 1rem auto;
    font-weight: bold;
}

.succes-modal__close-button svg {
    height: 2rem;
    margin-top: 1rem;
    width: 2rem;
}