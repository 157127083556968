.header {
    background: #E4056F;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.headerTitle {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
}

.headerCloseButton {
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}

.rating,
.review {
    text-align: center;
    padding: 1rem;
}

.ratingTitle,
.reviewTitle {
    font-size: 22px;
}

.ratingStars {
    font-size: 35px !important;
}

.reviewTextarea {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
    font-size: .9rem;
    margin: 0 auto;
    min-height: 8rem;
    outline: none;
    padding: 1rem;
    -ms-resize: vertical;
    -o-resize: vertical;
    -webkit-resize: vertical;
    resize: vertical;
    width: 80%;
}

.buttonContainer {
    padding: .5rem 0;

}

.button {
    background: #E4056F;
    border: none;
    border-radius: 2rem;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1rem auto;
    padding: .3rem 4rem;
    transition: all .4s;
}

.button:hover {
    background-color: #f71c86;
    transform: scale(1.05);
}

.button:active {
    background-color: #e7157b;
    transform: none;
}