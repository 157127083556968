.container {
  margin-top: 2rem;
}

.formItemsContainer {
  display: grid !important;
  grid-template-columns: 120px 1fr !important;
  align-items: end !important;
  gap: 1rem !important;
  align-content: flex-end !important;
  width: 100%;
}

.formItem {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;
}

.filterInputsContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 0.5rem !important;
}

.formItem div {
  text-align: left !important;
  width: 100% !important;
}

.formItem > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
  padding-bottom: 0.8rem !important;
}

.formItem input {
  height: 44px !important;
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
}

.selectInputWithLabel > div,
.selectInputForValue > div {
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
}

.footer {
  padding: 0 0.5rem;
  margin-top: 1rem;
}

.footer button {
  background: #fcc02c;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 24px;
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100% !important;
  max-width: 200px;
  gap: 0.5rem;
  font-size: 1rem;
  margin: auto;
  font-weight: 700;
  margin-top: auto;
}

.footer button:hover {
  background: #e5af28;
}
