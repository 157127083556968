.container {
  border-radius: 16px !important;
  background-color: #fff !important;
  background-size: cover !important;
  cursor: pointer !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07) !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  position: relative;
  padding: 0 !important;
}

.container::after {
  border-radius: 16px 16px 0 0 !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background: rgba(228, 5, 111, 0.451);
  transition: all 0.5s;
  opacity: 0;
}

.container:hover::after {
  opacity: 1;
}

.image {
  border-radius: 16px 16px 0 0 !important;
  background: rgba(228, 5, 113, 0.4);
  cursor: pointer;
  height: 140px !important;
  object-fit: cover !important;
  object-position: center !important;
  width: 100% !important;
}

.iconContainer {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 5;
}
.icon {
  margin: auto;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0.5rem 1rem 0.8rem;
}

.title {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  margin-bottom: unset !important;
}

.discount {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: unset !important;
}
