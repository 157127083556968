.container {
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    padding: 2rem 1rem;
    text-align: center;
    width: 90%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem 0 2rem !important;
}

.formItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
}

.formItemLabel {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.formItemInput,
.formItemInputSelect {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.formItemInputSelect {
    height: unset !important;
    padding: 0 !important;
}

.formItemInputSelect>div {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-radius: 20px !important;
    border: none !important;
    border-color: transparent !important;
    background: transparent !important;
    text-align: left !important;
    margin: auto !important;
    height: 48px !important;
    outline: none !important;
    box-shadow: none !important;
    width: 95% !important;
}

.formItem * {
    background-color: #fff !important;
}

.formItemInput:focus {
    border-color: #8a8a8a !important;
}

.formItemInput::placeholder {
    color: #ccc !important;
}


@media screen and (min-width: 768px) {
    .form {
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .formItemFlex {
        width: 45% !important;
    }
}