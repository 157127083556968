.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}
.header h2 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #e4056f;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 1rem auto 2rem;
  padding: 0 0.5rem;
  width: 100%;
}

.datePickerContainer strong {
  font-size: 15px;
  font-weight: 700;
  color: #808080;
  width: 100%;
}

.datePickerContainer button {
  background: transparent;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  color: #333;
  border: none;
  outline: none;
  font-weight: 600;
}

.datePicker {
  padding: 0.5rem !important;
  border-radius: 6px !important;
  width: 90%;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.footer button {
  background: transparent;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  color: #333;
  border: 1px solid #333;
  font-weight: 600;
}

.confirmButton {
  background-color: #e4056f !important;
  color: white !important;
  border: none !important;
}

.confirmButton:hover {
  background-color: #c10560 !important;
}

.confirmButton:active {
  background-color: #e4056f !important;
}

.confirmButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cancelButton:hover {
  border-color: #000;
  color: #000;
}

@media (max-width: 768px) {
  .datePicker {
    width: 80%;
  }
}
