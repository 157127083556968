.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem 4rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
}

.switch {
  width: fit-content !important;
}

.switchContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button {
  background-color: #e4056f;
  border: none;
  color: white;
  outline: none;
  padding: 8px 30px;
  border-radius: 2rem;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 0.5rem;
}

.button:hover {
  background-color: #d0045f;
}

.button:active {
  background-color: #c0034f;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .title {
    font-size: 1rem;
  }
}
