.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem 1rem 0 !important;
    margin: 1rem auto 7rem !important;
}

.formItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 40rem;
    width: 90%;
}

.formItemLabel {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 7px;
}

.formItemInput {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #aaa !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.formItemInputTextarea {
    min-height: 7rem !important;
    -ms-resize: vertical !important;
    resize: vertical !important;
}

.formItemInputSelect div {
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    border-radius: 0 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    text-align: left !important;
    align-items: center !important;
    border-radius: 16px !important;
    width: 90% !important;
}

.formItemInput:focus {
    border-color: #8a8a8a !important;
}

.formItemInput::placeholder {
    color: #ccc !important;
}

.formButton {
    background: #E4056F !important;
    border: none !important;
    border-radius: 20px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 100% !important;
}

.formButton:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 768px) {
    .formButton {
        margin-left: auto !important;
        width: 200px !important;
    }
}