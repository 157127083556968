.userScreenContainer {
    background-color: blueviolet;
    min-height: 100vh;
    width: 100%;
}

.userScreenRow {
    max-width: 800px;
}

.userScreenContainer * {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
}

.userScreenCol {
    margin-top: 1.5rem;
    max-width: 850px !important;
}


.userScreenHeaderTitle {
    color: #E4056F;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.userScreenHeaderTitle span:last-child {
    margin-left: 8px;
}

.userScreenHeaderCol {
    max-width: 400px !important;
}

.actionButtonsCol {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem;
    position: fixed !important;
    bottom: 2rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.actionButton {
    border: none !important;
    outline: none !important;
    background: transparent !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    height: 50px !important;
    width: 50px !important;
}

.actionButtonHidden{
    display: none !important;
}

.actionButtonText {
    display: none !important;
}

.actionButtonIcon {
    width: 50px !important;
    height: 50px !important;
}

.actionButtonSave {
    order: -1 !important;
}

.actionButtonSave .actionButtonIcon {
    opacity: .5 !important;
    transition: all .3s !important;
}

.actionButtonSaveHasChanged .actionButtonIcon {
    opacity: 1 !important;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
    width: 100%;
}

.dataContainerItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.dataContainerItem label {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.dataContainerItemInput {
    color: #333 !important;
    background: #E6E6E6 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    transition: all .3s;
}

.dataContainerItemInputGender {
    border: 1px solid #E6E6E6 !important;
    background: #E6E6E6 !important;
    border-radius: 16px !important;
    height: 45px !important;
    padding: 0 12px !important;
    transition: all .3s;
    width: 100% !important;
}

.dataContainerItemInputGender div {
    background: #E6E6E6 !important;
    height: 40px !important;
    border: none !important;
    border-radius: 0 !important;
    margin-top: 2px !important;
    padding: 12px 0 !important;
    transition: all .3s;
    width: 100% !important;
}

.dataContainerItemInputGender div span:last-child {
    line-height: 15px !important;
    color: #333 !important;
}

.dataContainerItemInputPassword {
    background: #E6E6E6 !important;
    border-radius: 16px !important;
    padding: 0 12px !important;
}

.dataContainerItemInputPassword input {
    color: #333 !important;
    font-size: 15px !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    text-align: left !important;
    width: 95% !important;
}

.dataContainerItemInputPassword.dataContainerItemInputEditing input {
    cursor: pointer !important;
}

.dataContainerItemInputEditing,
.dataContainerItemInputEditing div {
    background-color: #fff !important;
}




@media screen and (min-width: 768px) {
    .actionButtonsCol {
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        gap: 0 !important;
        margin-left: auto !important;
        max-width: 220px !important;
        position: static !important;
        bottom: unset !important;
        right: unset !important;
    }

    .actionButton {
        border: 1px solid #E4056F !important;
        border-radius: 8px !important;
        height: 40px !important;
        width: 100px !important;
    }

    .actionButtonText {
        display: block !important;
        font-size: 15px !important;
        font-weight: 700 !important;
    }

    .actionButtonIcon {
        display: none !important;
    }

    .actionButtonEdit,
    .actionButtonEditPassword {
        color: #E4056F !important;
        border-color: #E4056F !important;
    }

    .actionButtonEditPassword {
        margin-top: auto;
        border-radius: 16px !important;
        height: 47px !important;
        width: 200px !important;
    }

    .actionButtonSave {
        background: rgba(255, 25, 136, 0.678) !important;
        color: #fff !important;
        cursor: not-allowed !important;
        margin-left: .5rem !important;
        order: 1 !important;
    }

    .actionButtonSaveHasChanged {
        background: #E4056F !important;
        cursor: pointer !important;
    }

    .actionButtonSaveHasChanged:hover {
        background: #B20457 !important;
    }

    .actionButtonCancel {
        border-color: #808080 !important;
        color: #808080 !important;
    }

    .actionButtonCancel:hover {
        border-color: #333 !important;
        color: #333 !important;
    }



    .dataContainer {
        flex-direction: row;
    }

    .dataContainerItem {
        width: auto;
    }

    .dataContainerItemInputPassword input {
        width: 8rem !important;
    }

    .dataContainerItemInputGender {
        width: 10rem !important;
    }
}

@media screen and (min-width: 875px) {
    .actionButtonsCol {
        max-width: 250px !important;
    }

    .actionButton {
        width: 120px !important;
    }

    .actionButtonSave {
        margin-left: 1.5rem !important;
    }
}