.container {
    height: 75px !important;
    background-color: #fff !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.9) !important;
}

.logoCol {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-left: 1rem !important;
}

.logoImage {
    cursor: pointer;
    max-height: 45px !important;
}

.content {
    padding-right: 3% !important;
}

.contentTitle {
    display: none !important;
}

.menuIcon {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
}


@media screen and (min-width:768px) {
    .menuIcon {
        display: none !important;
    }

    .contentTitle {
        color: #E4056F;
        font-size: 19px;
        font-weight: 700;
        margin: auto !important;
        display: flex !important;
        gap: 10px !important;
    }

    .contentSelectCompany {
        border: none !important;
        color: #E4056F !important;
        border-radius: 18px !important;
        box-shadow: none !important;
        outline: none !important;
        min-width: 150px !important;
        font-weight: 500 !important;
    }

    .contentSelectCompany div:first-child {
        border: 1px solid #E4056F !important;
        box-shadow: none !important;
        color: #E4056F !important;
        border-radius: 18px !important;
        padding: 1px 15px !important;
        outline: none !important;
        height: auto !important;
        font-weight: 500 !important;
        text-align: left !important;
        min-width: 150px !important;
    }

    .contentSelectCompany span span svg {
        color: #E4056F !important;
    }

}