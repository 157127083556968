.container {
    background-color: #fff;
    border-radius: 16px;
    margin: auto;
    max-width: 600px;
    padding: 2rem;
    width: 90%;
    text-align: center;
}

.title {
    font-weight: 700;
    font-size: 27.65px;
    color: #E4056F;
    line-height: 32px;
    text-align: left;
}

.description {
    text-align: left;
    margin: 1.5rem auto 1rem;
}

.button {
    background: #E4056F !important;
    border: none !important;
    border-radius: 24px !important;
    color: #fff !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 8px 0 !important;
    margin-top: 1rem;
    transition: all .5s;
    width: 180px !important;
}

.button:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 768px) {

    .container {
        text-align: left;
    }

}

@media screen and (min-width: 1024px) {
    .container {
        padding: 3rem;
    }

}