.container {
    background: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 40vh;
    margin: 4rem auto !important;
}

.message {
    color: #ccc;
    font-size: 1.5rem;
    width: 90%;
}