.container {
  padding: 0.8rem 0 0;
  width: 100%;
}

.header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}

.headerTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.link {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #e4056f;
}
.link:hover {
  color: #af0959;
}
.link:active {
  color: #e4056f;
}

@media (max-width: 768px) {
  .headerTitle {
    font-size: 16px;
  }
}
