.header {
  background-position: center !important;
  background-size: cover !important;
  border-radius: 16px 16px 0 0 !important;
  min-height: 10rem !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-end !important;
  padding-left: 5% !important;
}

.headerLogo {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(50%);
  width: 10rem;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .header {
    height: 200px !important;
    padding-left: 10% !important;
  }
}
