.header {
    background: #E4056F;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.headerTitle {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
}

.headerCloseButton {
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}

.review {
    text-align: center;
    margin: 0 auto;
    max-width: 30rem;
    padding: 0.5rem 1rem;
}

.reviewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem auto;
    padding: 0 1rem;
}

.reviewHeaderTitle {
    font-size: 20px;
    margin: 0;
}

.reviewHeaderStars {
    font-size: 30px !important;
}

.reviewComment {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 1px 1px 3px rgba(163, 163, 163, 0.1);
    font-size: .9rem;
    padding: 2rem 1.5rem;
}

.reviewEmpty {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    padding: 1.5rem;
}

.buttonContainer {
    padding: .5rem 0 2rem;
    text-align: center;
}

.paginationButton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 1.1rem;
    margin: auto;
    outline: none;
    text-decoration: underline;
    transition: all .4s;
}

.button:hover {
    background-color: #f71c86;
    transform: scale(1.05);
}

.button:active {
    background-color: #e7157b;
    transform: none;
}