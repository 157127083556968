.container {
    background-color: #fff;
    border-radius: 16px;
    max-width: 475px;
    padding: 2rem 1.5rem;
    text-align: center;
    width: 90%;
}

.header {
    margin-bottom: 1.5rem;
}

.headerTitle {
    color: #E4056F;
    font-size: 19px;
    font-weight: 600;
}

.headerSubtitle {
    color: #E4056F;
    font-size: 14px;
}

.footer {
    margin: .5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.footerButton {
    background: #E4056F;
    border: 1px solid #E4056F;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 0;
    transition: all .5s;
    width: 100%;
}

.footerButton:hover {
    background: #B20457;
    border-color: #B20457;
}

.footerGhostButton {
    background: #fff !important;
    color: #E4056F;
}

.footerGhostButton:hover {
    color: #B20457;
}


@media screen and (min-width: 500px) {

    .footerButton {
        max-width: 150px !important;
    }

    .footerDescription {
        font-size: 15px;
    }
}