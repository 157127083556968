.content {
    background: #FCC02C !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem 1rem 1rem;
    z-index: 150;
}

.contentTitle,
.contentDescription {
    color: #FFFFFF;
    margin: auto !important;
    max-width: 38rem;
    width: 95%;
}

.contentTitle {
    line-height: 38px;
    margin-bottom: 1rem !important;
    font-weight: 700;
    font-size: 26px;
}

.contentDescription {
    font-weight: 400;
    font-size: 16px;
}

.animation {
    display: flex !important;
    position: relative;
}

.animation::after {
    content: '';
    background: #FCC02C;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.animation img {
    margin: auto;
    max-width: 40rem;
    width: 90%;
    z-index: 10;
}

.wave {
    transform: rotate(180deg);
    margin-bottom: 1rem;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .container {
        background: #FCC02C !important;
        margin-bottom: -.5rem !important;
    }

    .content {
        padding: 3rem 0 !important;
        z-index: 100 !important;
    }

    .contentTitle,
    .contentDescription {
        width: 95%;
    }

    .animation {
        align-items: center !important;
    }

    .animation img {
        max-width: 35rem;
        width: 100%;
    }

    .wave {
        margin-top: -1rem;
    }
}

@media screen and (min-width: 900px) {
    .contentTitle {
        font-size: 38px;
        line-height: 48px;
    }

    .contentDescription {
        font-size: 20px;
    }

    .wave {
        margin-top: -2rem;
        margin-bottom: 3rem;
    }
}


@media screen and (min-width: 1024px) {

    .content {
        padding: 5rem 0 2rem !important;
        padding-right: 0 !important;
    }

    .animation {
        padding-top: 2rem !important;
    }

}

@media screen and (min-width: 1300px) {
    .contentTitle {
        width: 91%;
    }

    .wave {
        margin-top: -3rem;
    }

}