.wave {
    width: 100%;
    margin-top: -.5rem;
    transform: rotate(180deg);
    margin-bottom: 2rem;
}

.container {
    background: #FCC02C;
    width: 100%;
}

.carousel {
    background: #E4056F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 0 !important;
}

.carousel ul {
    bottom: 6rem !important;
    margin: 0 auto !important;
    width: 120px !important;
}

.carousel ul li {
    background: #E4056F !important;
    border-radius: 100% !important;
    height: 16px !important;
    width: 16px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    opacity: 1 !important;
}

.carousel ul li button {
    background: #808080 !important;
    border-radius: 100% !important;
    height: 16px !important;
    width: 16px !important;
}

.carouselItem {
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 0 1rem;
    padding-top: 2rem;
    height: 320px !important;
    width: 30rem !important;
    text-align: center;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border: 2px solid #E4056F;
    min-height: 15rem;
    max-width: 600px !important;
    width: 95% !important;
}

.carouselItemTitle {
    font-weight: 600;
    font-size: 27px;
    color: #E4056F;
}

.carouselItemDescription {
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    margin: auto;
    width: 90%;
}

@media screen and (min-width: 650px) {
    .container {
        margin-bottom: -1rem;
    }

    .carousel {
        padding: 50px 0 25px !important;
    }

    .carouselItem {
        height: 250px !important;
    }

    .carousel ul {
        bottom: 5rem !important;
    }

    .wave {
        margin-bottom: 0;
    }
}