.container {
    /* border: 2px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    list-style-type: none;
    overflow-x: auto !important;
    width: 97% !important;
    padding: 0 !important;
    margin: 1rem auto !important;
}

.container::-webkit-scrollbar {
    background: transparent !important;
    width: 1px;
}

.container::-webkit-scrollbar-button {
    display: none;
}

.container::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: none !important;
    background: transparent !important;
}

.container::-webkit-scrollbar-track {
    background-color: transparent;
}

.item {
    background: #E4056F;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 15px;
    height: 105px;
    min-width: 165px;
    width: 165px;
}

.itemValue,
.itemTitle {
    color: #fff;
    width: 100%;
}

.itemValue {
    font-weight: 700;
    font-size: 33px;
}

.itemTitle {
    font-size: 13px;
    margin-top: -35px;
}

@media screen and (min-width: 768px) {
    .container {
        width: 100% !important;
        padding-bottom: 8px !important;
    }


    .container::-webkit-scrollbar-thumb {
        border: 4px solid #fff !important;
        background: #ccc !important;
    }

    .item {
        width: 200px;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        margin-bottom: 2rem !important;
    }

}