.container {
  margin: 1rem auto 4rem;
  max-width: 2000px !important;
  margin: 2rem auto 4rem !important;
  padding-bottom: 4rem;
}

.promotionsContainer {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 100% !important;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 801;
}

.headingContainer[class*="stickyHeader"] {
  background: #fff;
}

.headingContainer > h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.headingContainer img {
  width: 50px;
  height: 50px;
  z-index: 10;
}

.promotions {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  width: 100% !important;
}

.promotions div[class="infinite-scroll-component__outerdiv"] {
  width: 100% !important;
}

.promotionsFiltersSticky {
  margin-left: auto;
  z-index: 800;
}
.noPromotions {
  margin: auto !important;
  width: 100% !important;
}

.promotionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
  justify-content: space-between;
  row-gap: 3rem;
  column-gap: 2rem;
  width: 100%;
  padding-bottom: 1.5rem;
}

.promotionsFiltersGridBox {
  visibility: hidden;
  padding-left: 1.5rem;
}

.interestingContent {
  margin-top: 2rem;
  width: 100%;
}

.interestingContent > h2 {
  color: #fcc02c;
  font-size: 25px;
  font-weight: 600;
  padding: 1rem;
  text-wrap: balance;
  text-align: center;
}

@media (max-width: 1600px) {
  .promotionsGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-evenly;
  }
}

@media (min-width: 769px) {
  .overlay {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 1rem !important;
  }
  .interestingContent > h2 {
    font-size: 1rem;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    z-index: 800;
  }
  .headingContainer {
    padding-left: 0.5rem;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .headingContainer[class*="stickyHeader"] {
    padding: 1rem 0;
  }
  .headingContainerWithFiltersVisible {
    background: transparent !important;
  }
  .headingContainerWithFiltersVisible[class*="stickyHeader"] > img,
  .headingContainerWithFiltersVisible[class*="stickyHeader"] > h2 {
    opacity: 0;
  }
  .promotionsFiltersGridBox {
    display: none;
  }
  .headingContainer > h2 {
    font-size: 20px;
  }
  .headingContainer img {
    width: 30px;
    height: 30px;
  }
  .promotionsFilters {
    top: 0;
  }
  .promotionsFiltersSticky {
    top: 0 !important;
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
    padding: 0.6rem 0 !important;
  }
}

@media (max-width: 600px) {
  .promotionsGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
  }
}

@media (max-width: 380px) {
  .headingContainer {
    gap: 0.3rem;
  }
  .headingContainer > h2 {
    font-size: 18px;
  }
}
