.drawer {
    background: transparent !important;
    bottom: 0 !important;
    top: unset !important;
    height: calc(100% - 75px) !important;
}
.drawer>div {
    width: 100% !important;
}

.drawerHeader {
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.drawerHeaderUserIcon {
    background: #FCC02C;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    height: 35px;
    padding: 8px;
    width: 35px;
}

.drawerHeaderUserName {
    color: #E4056F;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.drawerFooter {
    text-align: center;
    padding: 10px 0;
}

.drawerFooter button {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    background: transparent;
    border: none;
    color: #808080;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin: auto;
    outline: none;
}

.panelHeader {
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.panelHeaderImage {
    width: 24px;
    height: 24px;
}

.panelHeaderTitle {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.panelNav {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-left: 2.5rem;
}

.panelNavLink {
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #E4056F;

}

.panelHeaderTitle:hover,
.panelNavLink:hover,
.drawerFooter:hover {
    text-decoration: underline !important;
}

.panelNavLink:hover {
    color: #E4056F;
}

@media screen and (min-width: 768px) {
    .drawer>div {
        max-width: 360px !important;
    }
    .drawerHeader {
        display: none;
    }
}