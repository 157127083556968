.formContainer {
    display: flex !important;
    justify-content: space-between !important;
    gap: 15px;
    flex-wrap: wrap !important;
    padding-bottom: .7rem !important;
}

.richTextInputContainer {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.richTextInputContainer div div {
    border: none !important;
}

.richTextInputContainer div div[class^="ql-toolbar ql-snow"] {
    border-bottom: 1px solid #ccc !important;
}

.formItem,
.formItemTextarea,
.formItemUpload {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.formItem>div:first-child,
.formItemUpload>div:first-child {
    flex: unset !important;
}

.formItem>div:last-child,
.formItemUpload>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.formItem>div:last-child,
.formItemTextarea>div:last-child,
.formItemSegment>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.formItemInput,
.formItemInputTextarea {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.formItemDiscount,
.formItemURL,
.formItemUpload,
.formItemSegment,
.formItemPublic,
.formItemVisible,
.formItemStatus,
.formItemTextarea {
    width: 100% !important;
}

.formItemInputTextarea {
    box-shadow: none !important;
    padding: 16px !important;
    min-height: 5rem !important;
}

.formItemUpload {
    width: 100% !important;
}

.formItemSegment>div:last-child>div>div>div {
    height: 44px !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
}

.formItemSegment>div:last-child>div>div>div>div {
    background: transparent !important;
    border-radius: 16px !important;
    height: 44px !important;
    width: 100% !important;
    box-shadow: none !important;
}

.formItemInputUpload {
    height: fit-content !important;
    width: 100% !important;
    position: relative !important;
}

.formItemInputUpload img{
    margin: 5px auto !important;
    width: 75% !important;
    max-width: 125px !important;
}

.formItemUpload>div:last-child {
    width: 100%;
}

.formItemUpload>div:last-child span div {
    height: auto !important;
    width: 100% !important;
}

.formItemInputUploadButton {
    border: 1px solid #E6E6E6 !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    border-radius: 16px !important;
    padding: 10px !important;
    position: relative;
    padding-right: 40px !important;
    max-width: 100% !important;
    width: 100% !important;
}

.formItemInputUploadButton img {
    position: absolute;
    right: .8rem;
    top: 25%;
}

.formItemInputUploadButton input {
    background: transparent !important;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    width: 95% !important;
}

.formItemData .formItemInput {
    color: #000 !important;
}


.formItemPublic>div:last-child>div>div>div>label>span:last-child,
.formItemPublic>div:last-child>div>div>div>label>span:first-child>span {
    color: #E4056F !important;
    border-color: #E4056F !important;
}

.formItemPublic>div:last-child>div>div>div>label>span:first-child>span::after {
    background-color: #E4056F !important;
}


.formItemCode>div:last-child input {
    color: #000 !important;
}

.statusSelect {
    cursor: pointer;
    /* padding: 5px 10px !important; */
    /* max-height: 44px !important; */
    border-radius: 16px;
    width: 100%;
}

.statusSelectActive {
    border: 1px solid #49BA35 !important;
}

.statusSelectInactive {
    border: 1px solid #ff0000 !important;
}

.statusSelect div {
    border: none !important;
    outline: none !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 44px !important;
    margin-right: auto !important;
}

.statusSelectActive span,
.statusSelectActive>div>span {
    color: #49BA35 !important;
    font-weight: bold !important;
}

.statusSelectInactive>div>span,
.statusSelectInactive span {
    color: #ff0000 !important;
    font-weight: bold !important;
}



@media screen and (max-width: 600px) {
    .formItemCode {
        width: 47% !important;
        order: -8 !important;
    }

    .formItemStatusEditing {
        order: -7 !important;
        width: 47% !important;
    }
    .formItemStatusCreating{
        order: -10 !important;
    }

    .formItemURL {
        order: -6 !important;
    }

    .formItemName {
        order: -4 !important;
    }

    .formItemPartnerNameEdit {
        order: -5 !important;
    }

    .formItemTextarea{
        order: -3 !important;
    }

    .formItemPublicEditing {
        order: -2 !important;
        width: 190px !important;
    }

    .formItemDiscountEditing {
        order: -1 !important;
        width: 30% !important;
    }

}

@media screen and (min-width: 601px) {
    .formItemName {
        width: 48% !important;
    }

    .formItemSegment,
    .formItemVisible {
        width: 31% !important;
    }

    .formItemUpload,
    .formItemPublic,
    .formItemSegment {
        width: 45% !important;
    }

    .formItemDiscount,
    .formItemURL,
    .formItemStatus {
        width: 30% !important;
    }

    .formItemData {
        width: 30% !important;
    }

    .formItemCode {
        width: 13% !important;
    }

    .formItemNameEdit {
        width: 40% !important;
    }
}

@media screen and (min-width: 900px) {

    .formItemUpload {
        width: 30% !important;
    }

    .formItemPublic {
        width: 30% !important;
        /* max-width: 190px !important; */
    }

    .formItemSegment,
    .formItemDiscount,
    .formItemURL,
    .formItemStatus {
        width: 20% !important;
    }

    .formItemSegment,
    .formItemStatus {
        width: 25% !important;
    }

    .formItemNameEdit {
        width: 40% !important;
    }
}