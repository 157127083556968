.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 2rem;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mainTitle {
    font-size: 26px;
    font-weight: 700;
    margin: 0 !important;
}

.mainButton {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all .5s;
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    width: 60px;
    z-index: 100;
}

.mainButton img {
    width: 100%;
}

.mainButton span {
    display: none;
}

.rating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    width: 100%;
}

.ratingTitle {
    color: #E4056F;
    font-size: 16px;
    font-weight: 500;
    margin: 0 !important;
}

@media screen and (min-width: 768px) {
    .main {
        justify-content: space-between;
    }

    .mainTitle {
        font-size: 33px;
    }

    .mainButton {
        background: #FCC02C;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        outline: none;
        padding: 8px 32px;
        transition: all .5s;
        position: static;
        width: 120px;
    }

    .mainButton:hover {
        background: #E5AF28;
    }

    .mainButton img {
        display: none;
    }

    .mainButton span {
        color: #fff;
        display: block;
        font-weight: 700;
    }

    .rating {
        justify-content: flex-start;
    }

    .ratingTitle {
        font-size: 19px;

    }
}