.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 2rem;
}

.title {
  color: #e4056f;
  font-size: 33px;
  font-weight: 700;
  margin: 0 !important;
  text-align: left;
  width: 100%;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
  padding-right: 1rem !important;
  width: 100% !important;
}

.datePickerContainer label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: block;
  margin: auto;
  width: 100%;
}

.datePickerContainer [name="rangeDate"] {
  background-color: white;
  border: 1px solid #808080;
  font-size: 16px;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: auto;
  width: 100%;
  max-width: 300px;
}

.filtersContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.5rem;
}

.filtersContainer button {
  background: #e4056f;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  padding: 8px 32px;
  transition: all 0.5s;
  position: static;
  width: 120px;
  color: #fff;
  font-weight: 700;
}

.filtersContainer button:hover {
  background: #b20457;
}

.filtersContainer button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filtersContainer button img {
  display: none;
}

.button img {
  width: 100%;
}

.buttonGray {
  display: none;
}

.button span {
  display: none;
}

.listToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.listToggleButton {
  background: transparent !important;
  color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  transition: all 0.5s;
}

.listToggleButtonActive svg,
.listToggleButtonActive span {
  transition: all 0.5s;
  color: #e4056f;
  fill: #e4056f;
}

@media screen and (min-width: 768px) {
  .container {
    margin-top: -0.5rem;
  }
  .listToggle {
    justify-content: flex-start;
  }

  .title {
    text-align: left;
    width: 40%;
  }
}

@media screen and (max-width: 500px) {
  .filtersContainer {
    flex-wrap: wrap;
  }
}
