.container {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  padding-bottom: 1rem;
  margin-bottom: 4rem;
}

.containerBlurred {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
  margin-bottom: 4rem;
}

.blurredImage {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.title {
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.statsVisualizations {
  color: #e4056f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  padding: 1.5rem 2.5rem;
  /* width: 100%; */
}

.statsVisualizations span {
  display: inline-block;
  margin-right: 5px;
  font-size: 33px;
  font-weight: 700;
}

.stats {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1.5rem;
  align-items: center;
}

.noData {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .statsVisualizations {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .title {
    padding: 1.5rem 5rem;
  }

  .statsVisualizations {
    margin: 1rem auto;
  }

  .statsVisualizations {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    padding: 1.2rem 3rem;
  }
  .stats {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 1700px) {
  .stats {
    justify-content: space-between;
  }
}
