.container {
    max-width: 33rem !important;
    width: 85% !important;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.headerTitle {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.headerCloseIcon {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.headerCloseIcon svg {
    height: 22px;
    width: 22px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 0 1rem 2rem !important;
}

.formItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 90%;
}

.formItemLabel {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 7px;
}

.formItemInput {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    outline: none !important;
    transition: all .3s;
    width: 100% !important;
}

.formItemInputSelect div {
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 16px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.formItemInput[disabled] {
    cursor: not-allowed !important;
}

.formItemInput:focus {
    border-color: #8a8a8a !important;
}

.formItemInput::placeholder {
    color: #808080 !important;
}

.formButton {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    padding: 10px 0 !important;
    width: 90% !important;
}

.formButton span {
    display: block !important;
    line-height: 0 !important;
}

.formButton:hover {
    background: #E5AF28 !important;
}

@media screen and (min-width: 500px) {
    .headerTitle {
        font-size: 27px;
    }

    .headerCloseIcon {
        right: 2rem !important;
    }

    .formButton {
        max-width: 140px !important;
    }
}