.infoReviewsMessage {
  text-align: center;
  width: 90%;
  color: #e40571;
  font-size: 14px;
  margin: 0 !important;
}

.infoReviewsMessageLink {
  color: #e40571;
  display: inline-block;
  font-weight: bold;
}

.infoReviewsMessageLink:first-of-type {
  margin-right: 4px;
}

.infoReviewsMessageLink:last-of-type {
  margin: 0 4px;
}

.infoReviewsMessageLink:hover {
  color: #e40571 !important;
  text-decoration: underline;
}

.infoRatingStars {
  font-size: 21px !important;
}

.infoRatingStars * {
  transform: none !important;
}

.infoRatingStars:hover li[class="ant-rate-star ant-rate-star-full"] {
  transform: none !important;
  cursor: pointer;
  transition-duration: 0.1s !important;
  color: #e5af28 !important;
}

.infoRatingStars li[class="ant-rate-star ant-rate-star-full"]:hover {
  transform: none !important;
  transition-duration: 0.3s !important;
}

.infoReviews {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
}

.infoReviews button {
  border: 1px solid #e4056f;
  background-color: transparent;
  border-radius: 24px;
  color: #e4056f;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  padding: 8px 10px;
  transition: all 0.3s;
}

.infoReviews button:hover {
  border-color: #b20457;
}

.infoReviews button:last-child {
  background: #fcc02c;
  border: 1px solid #fcc02c;
  color: #fff;
}

.infoReviews button:last-child:hover {
  background: #e5af28;
}

@media screen and (min-width: 600px) {
  .infoReviews {
    gap: 1rem;
  }

  .infoReviews button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.commentButton {
  background: #e4056f !important;
  outline: none !important;
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.commentButton:hover {
  background: #b20457 !important;
}
.commentButton:active {
  background: #e4056f !important;
}
