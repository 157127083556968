.UsersScreenButton {
  color: #808080 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center !important;
  border: none !important;
  font-weight: 700 !important;
  margin: 0px 0px 1px 0px !important;
}
.UsersScreenButton:hover {
  color: #e4056f !important;
}
.UsersScreenButton:focus {
  color: #e4056f !important;
}
.Users-h1-Label {
  font-weight: bold;
  font-style: italic;
  padding-left: 40px;
}
.container-UsersScreen {
  border-radius: 50px;
  background-color: white;
  margin-bottom: 50px !important;
  border: 2px solid #808080;
  padding: 20px !important;
}
.row-UsersScreen {
  justify-content: space-evenly;
  align-items: center;
  border: 1px gray solid;
  border-radius: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-UsersScreen h2{
  font-weight: bold;
  color: #E4056F;
  opacity: 0.5;
}

.row-UsersScreen p{
  font-size: 20px;
}
.Upload-form-users {
  width: 250px !important;
  margin: 0px auto !important;
}
.Upload-input-users {
  padding: 30px !important;
  border-radius: 20px !important;
}
.Upload-p-users {
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-align: center;
  margin-right: 10px;
}

.ant-table-row {
  border: 20px solid black !important;
}

.companyUsersScreenCompanyLogo{
  width: 10vw;
}

.addUserButton{
  background-color: #e4056f;
  border-radius: 50%;
  padding: 4px !important;
  cursor: pointer;
  color: white !important
}

.excelUsersIcon{
  width: 40px;
}

.companyUsersTopButtonsContainer{
  margin-bottom: 40px;
}

.companyUsersTopButton{
  border: none !important;
  font-style: italic;
  background-color: transparent !important;
  box-shadow: transparent !important;
}

@media (max-width: 992px) {
  .companyUsersScreenCompanyLogo{
    width: 15vw;
  }
}

@media (max-width: 700px) {
  .companyUsersScreenCompanyLogo{
    width: 20vw;
  }
}

@media (max-width: 500px) {
  .companyUsersScreenCompanyLogo{
    width: 30vw;
  }
}