.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.headerTitle {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.headerIcon {
    margin-right: 7px;
    height: 50px;
    width: 50px;
}