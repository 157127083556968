.container {
  padding: 2rem 1.5rem;
}

.formItemsContainer {
  display: grid !important;
  grid-template-columns: 120px 1fr !important;
  align-items: end !important;
  gap: 1rem !important;
  align-content: flex-end !important;
  padding-right: .5rem !important;
  width: 100%;
}

.formItem {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;
}

.filterByFormItem {
  width: 200px !important;
}

.formItem div {
  text-align: left !important;
  width: 100% !important;
}

.formItem > div label {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  color: #808080 !important;
}

.formItem input {
  height: 44px !important;
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
}

.selectInputWithLabel > div,
.selectInputForValue > div {
  border-radius: 10px !important;
  border: 1px solid #808080 !important;
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
  /* width: 100% !important; */
}

.searchInputsContainer {
  display: none !important;
}

.filterInputsContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
