.formContainer {
    display: flex !important;
    justify-content: space-between !important;
    gap: 15px;
    flex-wrap: wrap !important;
}

.richTextInputContainer {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.richTextInputContainer div div {
    border: none !important;
}

.richTextInputContainer div div[class^="ql-toolbar ql-snow"] {
    border-bottom: 1px solid #ccc !important;
}

.formItem,
.formItemTextarea {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.formItem>div:first-child {
    flex: unset !important;
}

.formItem>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.formItem>div:last-child,
.formItemTextarea>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.formItemInputTextarea {
    border: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    border-radius: 16px !important;
    width: 100% !important;
}

.formItemTextarea {
    width: 100% !important;
}

.formItemInputTextarea {
    box-shadow: none !important;
    padding: 16px !important;
    min-height: 4rem !important;
}