.container {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 1.23px 6.14px 0px #00000011;
  width: 100% !important;
  height: 320px;
  max-width: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.header {
  border-bottom: 1px solid #e0e0e0;
  margin: auto auto 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 0.5rem;
  justify-content: flex-start;
  gap: 0.1rem;
  flex-wrap: wrap;
}

.header h3 {
  color: #000;
  font-size: 13px !important;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.header h3 strong {
  font-weight: 700;
  font-size: 20px;
}

@media screen and (min-width: 1200px) {
  .container{
    max-width: 350px;
  }
}