.UserScreenContent {
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: auto;
    max-width: 500px;
    padding: 24px 32px;
    width: 90%;
    position: relative !important;
    z-index: 10 !important;
}

.UserScreenContentTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
    .UserScreenContent {
        margin: 0;
        max-width: 1200px;
        width: 100%;
    }   
}