.deskContainer {
    border: 1px solid green;
}

.table {
    display: none !important;
}

.table div div div div div table,
.accordion {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 1rem 2rem 1.5rem !important;
}

.accordionEmpty {
    background: transparent !important;
    border: none !important;
    text-align: center;
    color: #aaa !important;
    font-weight: 700;
    padding: 1rem !important;
}


.accordion {
    padding: 1rem .5rem !important;
    margin-bottom: 5rem !important;
}

.accordion div {
    border-color: #eee !important;
}

.accordionPanel>div {
    font-size: 16px !important;
}

.accordionPanel>div span {
    font-size: 16px !important;
    color: #E4056F !important;
}

.accordionPanelTitle {
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 16px !important;
}

.accordionTitle,
.accordionContentItemTitle {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #E4056F !important;
    margin-left: 1rem !important;
}

.accordionContent {
    background: #E6E6E6 !important;
    border-radius: 8px !important;
    padding: 20px 15px !important;
    max-width: 400px;
    width: 100% !important;
    margin: auto;
}

.accordionContentItem {
    /* background-color: rgba(51, 51, 51, 0.226) !important; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.accordionContentItemTitle {
    font-size: 14px !important;
    margin: 0 !important;
}

.table div div div div div table thead tr th {
    border: none !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #E4056F !important;
    padding: 1rem 0 1.5rem !important;
}

.table div div div div div table thead tr th::before {
    background: transparent !important;
}

.table div div div div div table tbody tr td {
    padding: 10px 5px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

/*
.table div div div div div table tbody tr:hover td {
    background: transparent !important;
} */

.tableName,
.tableEmail,
.tableAcces,
.tableSegment {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.tableSegment {
    color: #808080 !important;
}

.tableAcces {
    color: #E4056F !important;
}


.tableEmail {
    font-weight: 700 !important;
}

.tableActions {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.tableActions button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.tableActions button img {
    max-width: 40px;
}

.tableSwitch {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (min-width: 450px) {
    .accordionContent {
        padding: 20px !important;
        margin: auto !important;
        width: 100% !important;
    }

    .accordionTitle {
        font-size: 18px !important;
    }

    .accordionContentItemTitle {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 1024px) {
    .table {
        display: block !important;
    }

    .accordion {
        display: none !important;
    }
}