.SearchWeb-AddAlly {
  display: flex;
  justify-content: center;
  margin: 20px 0px 30px 0px;
}

.searchInput-AddAlly {
  border-radius: 50px !important;
  border: 2px solid black !important;
  height: 50px !important;
  max-width: 20rem !important;
  text-align: center !important;
  width: 90% !important;
}


.a-userAddAllyScreen {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  text-align: center;
  text-decoration: underline;
}

.a-userAddAllyScreen:hover {
  color: black;
}

.userAddAllyScreen-ButtonHome {
  color: #ffffff !important;
  font-size: 24px !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
  background-color: #e4056f !important;
  width: 200px !important;
  height: 45px !important;
}

.userAddAllyScreen-ButtonHome:hover {
  background-color: #ff1787 !important;
  /* color: #3f3f3f !important; */
}

.userAddAllyScreen-ButtonHome:active {
  background-color: #cf0869 !important;
}

.allies-container {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -ms-justify-content: space-evenly;
  justify-content: space-evenly;
  gap: 1rem;
  margin: 20px auto;
  max-width: 60rem;
  padding: 1.5rem 1rem;
  width: 90%;
}

.allied {
  border: 1px solid rgba(205, 205, 205, 0.5);
  border-radius: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  max-width: 13rem;
  width: 100%;
}

.allied--selected {
  background: rgba(180, 180, 180, 0.5) !important;
}

.allied:hover {
  background: rgba(236, 236, 236, 0.4);
}

.allied__logo {
  width: 80%;
  height: 100%;
  -ms-object-fit: cover;
  -webkit-object-fit: cover;
  object-fit: cover;
  -ms-object-position: center;
  -webkit-object-position: center;
  object-position: center;
}