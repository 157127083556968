.container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    margin: 0 auto 2rem !important;
}

.header {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    width: 100% !important;
}

.title {
    font-size: 33px !important;
    font-weight: 700 !important;
    margin: 0 !important;
}

.buttonsContainer {
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
    align-items: center !important;
    justify-content: center !important;
    position: fixed !important;
    bottom: 1.5rem !important;
    right: 1rem !important;
    z-index: 100 !important;
    width: 60px !important;

}

.button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
}

.button img {
    width: 100% !important;
}

.buttonGray {
    display: none !important;
}

.button span {
    display: none !important;
}


@media screen and (min-width: 900px) {
    .container {
        margin-top: -.5rem !important;
    }

    .header {
        flex-direction: row !important;
        justify-content: flex-start !important;
        gap: 10px !important;
        width: 40% !important;
    }
}

@media screen and (min-width: 1000px) {

    .buttonsContainer {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        position: static !important;
        max-width: 450px;
        width: 60% !important;
    }

    .button {
        background: #E4056F !important;
        border: none !important;
        border-radius: 24px !important;
        outline: none !important;
        transition: all .5s !important;
        height: 40px !important;
    }

    .button:hover {
        background: #B20457 !important;
    }

    .button img {
        display: none !important;
    }

    .button span {
        color: #fff;
        display: block !important;
        font-weight: 700; 
    }

    .buttonGray {
        background: #B3B3B3 !important;
        display: inline-block !important;
        padding: 8px !important;
        width: 130px !important;
    }

    .buttonGray:hover {
        background: #808080 !important;
    }

    .buttonPreview {
        background: #FCC02C !important;
        width: 130px !important;
        padding: 8px !important;
    }

    .buttonPreview:hover {
        background: #E5AF28 !important;
    }
}