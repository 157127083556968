
.title {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 1.5rem 2.5rem !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
}

.statsVisualizations span {
    display: inline-block;
    margin-right: 5px;
    font-size: 33px;
    font-weight: 700;
}

.stats {
    padding: 1.5rem;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1.5rem;
    align-items: center;
}

.colCenter {
 text-align: center;
 display: flex !important;
 flex-direction: column !important;
}

.colLabel{
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 1.5rem 2.5rem;
}

.logo{
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
}

.dataContainerItemInput,
.formItemInputSelect {
    color: #333 !important;
    background: #E6E6E6 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 16px !important;
    height: 48px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px !important;
    transition: all .3s;
    width: 100% !important;
}

.dataContainerItemInputEditing,
.formItemInputSelectEditing,
.dataContainerItemInputEditing div {
    background-color: #fff !important;
}

.button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
    position: fixed !important;
    bottom: 3rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.buttonCancel {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    transition: all .5s !important;
    position: fixed !important;
    bottom: 8rem !important;
    right: 1rem !important;
    z-index: 100 !important;
}

.buttonChangeLogo{
    background: #FCC02C !important;
    border: none !important;
    border-radius: 24px !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 8px 32px !important;
    margin-top: 20px !important;
    transition: all .5s !important;
    position: static !important;
    width: 100% !important;
    color: white !important;
    font-weight: bold !important;
}

.button img {
    width: 100% !important;
}

.button span {
    display: none !important;
}

.buttonCancel span {
    display: none !important;
}


.formItemInputSelect {
    height: unset !important;
    padding: 0 !important;
}

.formItemInputSelect>div {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-radius: 20px !important;
    border: none !important;
    border-color: transparent !important;
    background: transparent !important;
    text-align: left !important;
    margin: auto !important;
    height: 48px !important;
    outline: none !important;
    box-shadow: none !important;
    width: 95% !important;
}

@media (max-width: 769px) {
    .container{
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
        padding-bottom: 3rem;
        margin-bottom: 4rem;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }
}


@media screen and (min-width:768px) {
    .container{
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.102);
        padding-bottom: 3rem;
        margin-bottom: 4rem;
    }

    .statsVisualizations {
        text-align: left;
    }

    .dataContainerItemInput,
    .formItemInputSelect {
        width: 90% !important;
    }

    .button {
        background: #E4056F !important;
        border: none !important;
        border-radius: 24px !important;
        cursor: pointer !important;
        outline: none !important;
        padding: 8px 32px !important;
        transition: all .5s !important;
        position: static !important;
        width: 140px !important;
        height: 40px !important;
    }

    .buttonCancel{
        background: transparent !important;
        border: solid black 0.5px !important;
        border-radius: 24px !important;
        cursor: pointer !important;
        outline: none !important;
        padding: 8px 32px !important;
        transition: all .5s !important;
        position: static !important;
        width: 140px !important;
        height: 40px !important;
        margin-right: 30px !important;
    }

    .button:hover {
        background: #B20457 !important;
    }

    .buttonCancel:hover {
        color: black !important;
    }

    .buttonCancel img {
        display: none !important;
    }

    .button img {
        display: none !important;
    }

    .button span {
        color: #fff !important;
        display: block !important;
        font-weight: 700 !important;
    }

    .buttonCancel span {
        display: block !important;
        font-weight: 700 !important;
    }
}

@media screen and (min-width:1024px) {
    .container{
        padding-bottom: 5rem;
    }
    .title {
        padding: 1.5rem 5rem;
    }

    .statsVisualizations {
        margin: 1rem auto;
    }

    .statsVisualizations {
        padding: 0 5rem;
    }
}

@media screen and (min-width:1200px) {
    .stats {
        padding: 0 3rem;
    }
}