.container {
    max-width: 33rem !important;
    width: 85% !important;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    position: relative;
}

.headerTitle {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.headerCloseIcon {
    color: #E4056F !important;
    position: absolute;
    right: 1rem;
}

.headerCloseIcon svg {
    height: 22px;
    width: 22px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem !important;
}

.contentItem {
    color: #333333;
    font-size: 13px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 25rem;
    width: 90%;
}

.contentItemTitle {
    font-weight: 600;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1rem 1.5rem;
}

.button {
    background: #FCC02C !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    height: 40px !important;
    max-width: 20rem;
    padding: 10px 0 !important;
    width: 90% !important;
}

.button:last-child {
    background: #E4056F !important;
}

.button:hover {
    background: #E5AF28 !important;
}

.button:last-child:hover {
    background: #B20457 !important;
}

@media screen and (min-width: 500px) {
    .headerTitle {
        font-size: 27px;
    }

    .headerCloseIcon {
        right: 2rem !important;
    }

    .contentItem {
        font-size: 15px;
    }

    .buttons {
        padding: 1.5rem 1rem 2rem;
    }

    .button {
        max-width: 200px !important;
    }
}