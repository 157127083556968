.DeleteSegmentsModal {
  padding: 50px !important;
  text-align: center;
  margin: 0px auto !important;
}
.DeleteSegmentsModal-content {
  padding: 0px 30px !important;
}
.DeleteSegmentsModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
  text-align: justify !important;
}
.DeleteSegmentsModal p {
  text-align: justify !important;
}
