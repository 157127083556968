.userModal-h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding: 20px 0px 0px 0px;
  padding-top: 50px;
}
.modalBody {
  width: 40% !important;
  margin: 0px auto !important;
}
.CreateAndEditUserModal-h2 {
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.modalForm {
  width: 60% !important;
  margin: 0px auto !important;
  padding: 15px 0px !important;
}

.createUserFormInput{
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  border-radius: 50px!important;
  width: 100% !important;
  padding: 10px 0px;
  padding-left: 15px !important;
}

.formSelectCreateUser{    
  text-align: center;
  border-radius: 500000px !important;
  height: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: solid rgba(0, 0, 0, 0.15) 1px;
  }

.CreateAndEditUserModal-Button {
  color: #070707 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  font-weight: 700 !important;
  border-radius: 50px !important;
  margin-bottom: 30px;
}

.CreateAndEditUserModal-Button:hover {
  color: #3f3f3f !important;
}
.CreateAndEditUserModal-Button:focus {
  background-color: #e4056f !important;
}

@media (max-width: 700px) {
  .modalBody {
    width: 60% !important;
  }
}

@media (max-width: 500px) {
  .modalBody {
    width: 70% !important;
  }
}

@media (max-width: 400px) {
  .modalBody {
    width: 90% !important;
  }
}