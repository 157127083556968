.filter {
  position: relative !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  margin-left: auto;
}

.filterButton {
  background: #fff !important;
  color: #e4056f !important;
  border: 2px solid #e4056f !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  display: flex !important;
  gap: 1rem !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 8px 40px !important;
  height: 55px !important;
  outline: none !important;
  max-width: 35rem !important;
  width: fit-content !important;
}

.filterButton:hover {
  color: #c30963 !important;
  border-color: #c30963 !important;
}

.filterButton img {
  width: 2rem !important;
  height: 2rem !important;
}

.filterIconClosed {
  transform: rotate(0deg) !important;
  transition: transform 0.3s !important;
}
.filterIconOpened {
  transform: rotate(180deg) !important;
  transition: transform 0.3s !important;
}

@media (max-width: 768px) {
  .filterButton {
    font-size: 14px !important;
    height: auto !important;
    gap: 0.4rem !important;
    padding: 8px 12px !important;
  }
  .filterButton img {
    width: 20px !important;
    height: 20px !important;
  }
  .filter {
    position: sticky !important;
    top: 4rem !important;
  }
}

@media (max-width: 430px) {
  .filterButton {
    border-radius: 6px !important;
    font-size: 14px !important;
    height: auto !important;
    gap: 0.2rem !important;
  }
  .filterButton img {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 380px) {
  .filterButton {
    padding: 8px !important;
    font-size: 13px !important;
  }
}
