.formContainer {
    display: flex !important;
    justify-content: space-between !important;
    gap: 15px;
    flex-wrap: wrap !important;
    padding-bottom: 1rem !important;
}

.formItem,
.formItemCommunes {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
}

.formItem>div:first-child,
.formItemCommunes>div:first-child {
    flex: unset !important;
}

.formItem>div:last-child,
.formItemCommunes>div:last-child {
    display: unset !important;
    flex: unset !important;
}

.formItem>div:last-child,
.formItemCommunes>div:last-child {
    border-radius: 16px !important;
    width: 100% !important;
}

.formItemCommunes {
    cursor: pointer;
    width: 100% !important;
}

.formItemInputCommunes {
    border: 1px solid #E6E6E6;
    border-radius: 16px !important;
    padding: 6px 10px !important;
    width: 100%;
}

.formItemInputCommunes div {
    border: none !important;
    box-shadow: none !important;
}

.categorySelect {
    border: 1px solid #E6E6E6;
    cursor: pointer;
    padding: 5px 10px !important;
    max-height: 44px !important;
    border-radius: 16px;
    width: 100%;
}

.categorySelect div {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: 90% !important;
    margin-right: auto !important;
}

.categorySelect>span {
    color: #E4056F !important;
}

.categoryOption {
    border-top: 1px solid #E6E6E6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px !important;
    padding: 10px 10px !important;
}

.categoryOptionText {
    font-weight: bold !important;
    color: #000 !important;
    font-size: 16px !important;
    display: inline-block !important;
    margin: auto !important;
    transform: translateY(2px) !important;
}

.categoryOptionIcon {
    width: 20px !important;
    max-height: 20px !important;
    margin-right: 7px !important;
}

@media screen and (min-width: 600px) {

    .formItemCommunes,
    .formItemCategory {
        width: 48% !important;
    }
}