.wave {
    padding: 0 !important;
    margin-top: -.5rem;
    transform: rotate(180deg);
    width: 100%;
}

.header {
    background: #FCC02C;
    background-position: top;
    padding: 3rem 0 1rem;
    width: 100%;
}

.headerTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerTitle {
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    margin: 0 !important;
}

.headerIcon {
    height: 30px;
    margin-right: 7px;
    width: 30px;
}

.headerDescription {
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin: .5rem auto;
    max-width: 35rem;
    width: 85%;
}