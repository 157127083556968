.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.headerTitle {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    margin: 0 !important;
}

.headerIcon {
    margin-right: 14px;
    border-radius: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    height: 35px;
    width: 35px;
}