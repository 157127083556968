.container {
  padding: 0.8rem 0;
  width: 100%;
}

.header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.headerTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.headerArrowButton {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  transition: transform 0.2s;
}

.rotate {
  transform: rotate(90deg);
}

.headerArrowButton img {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.checkboxGroup {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.1rem !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.checkboxGroup label {
  margin-bottom: 0.9rem;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 0.2rem !important;
  width: 100% !important;
}

.checkboxGroup .ant-checkbox-inner {
  border: 1px solid #000 !important;
}

.checkboxGroup .ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border: none !important;
}

.checkboxGroup label .ant-checkbox-inner {
  border: none !important;
}

.checkboxGroup label span:first-of-type {
  font-size: 26px;
  height: 20px;
  width: 20px;
}

.checkboxGroup label span input[type="checkbox"]:checked + span {
  background: transparent !important;
  border-color: #e4056f !important;
  border-width: 2px !important;
}

.checkboxGroup label span {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-color: black !important;
  border-width: 2px !important;
}

.checkboxGroup label span[class="ant-checkbox ant-checkbox-checked"] ~ span {
  color: #e4056f !important;
}

.checkboxGroup label span input[type="checkbox"]:checked + span:after {
  border-color: #e4056f !important;
  left: 20%;
  top: 45%;
  height: 12px;
  width: 8px;
}

@media (max-width: 768px) {
  .headerTitle {
    font-size: 16px;
  }
  .checkboxGroup label span:first-of-type {
    height: 18px;
    width: 18px;
  }
  .checkboxGroup label span {
    font-size: 14px !important;
  }
}
