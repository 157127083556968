.CreateAndEditSegmentModal {
  padding: 50px !important;
  text-align: center !important;
  margin: 0px auto !important;
}
.CreateAndEditSegmentModal-content {
  padding: 0px 30px !important;
}
.CreateAndEditSegmentModal-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0px;
}

.CreateAndEditSegmentModal-Input {
  width: 100% !important;
}
