.text-companyHomeScreen{
  max-width: 100%;
  color: #808080 !important;
  font-size: 28px !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  max-width: 80%;
 margin: 0px auto;
 padding-bottom: 35px;
}

.text-companyHomeScreen span{
  font-size: 36px;
  color: #E4056F;
}

.img-CompanyHomeScreen{
margin-right: 30px !important;
}

.align-img-CompanyHomeScreen{
text-align: center;
padding-bottom: 100px;
}
