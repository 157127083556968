/* HEADER BUTTONS */
.row-CreatePromotions {
    width: 85%;
    margin: 0px auto 50px auto;
    border-bottom: 2px solid #b3b3b3;
  }
  
  .h2-CreatePromotions {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-weight: 700 !important;
    font-size: 32px;
  }
  .p-CreatePromotions {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  /* CLOSE HEADER BUTTONS */