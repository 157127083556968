/* TABLA ANTD */

.ant-table {
  box-sizing: border-box;
  margin: 0px auto !important;
  padding: 0;
  color: rgba(34, 15, 15, 0.562) !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  font-size: 1vw !important;
  background: rgba(7, 3, 3, 0) !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: italic !important;
  font-weight: 700 !important;
}

.ant-table-cell{
  max-width: 10vw !important;
}

.ant-table table {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  text-align: left !important;
  border-radius: 30px !important;
}

.ant-table-thead > tr > th {
  color: rgba(228, 5, 111, 0.45) !important;
  font-weight: 700 !important;
  text-align: left !important;
  background: none !important;
}
.ant-table-tbody > tr > td {
  border: none !important;
  border-radius: 0px 0px 30px 30px !important;
}

.promotionIsVisibleLabel{
  text-align: center;
  background-color: #83C446;
  border-radius: 20px;
  color: white;
}

.promotionIsNotVisibleLabel{
  text-align: center;
  background-color: #E4056F;
  border-radius: 20px;
  color: white;
}

.promotionActiveStatusLabel{
  text-align: center;
  background-color: #83C446;
  border-radius: 20px;
  color: white;
}

.promotionInactiveStatusLabel{
  text-align: center;
  background-color: #808080;
  border-radius: 20px;
  color: white;
}

.promotionExpiredStatusLabel{
  text-align: center;
  background-color: #E4056F;
  border-radius: 20px;
  color: white;
}

.removePromotionModalLabel{
  padding: 20px !important;
  padding-top: 10px !important;
}

.removePromotionModalRowButtons{
  padding-bottom: 20px !important;
}

@media(max-width: 768px){
  .ant-table {
    font-size: 2vw !important;
  }
}

@media(max-width: 400px){
  .ant-table {
    font-size: 3vw !important;
  }
}