.modal {
  max-width: 900px !important;
}

.modal div:nth-child(2) {
  border-radius: 16px !important;
}

.modalCloseButton {
  font-size: 24px !important;
  background: transparent !important;
  border-radius: 100% !important;
  padding: 5px !important;
  color: #fff !important;
  font-weight: bold !important;
  margin: 1rem 3rem 0 0 !important;
  transition: all 0.5s !important;
}

.modalCloseButton:hover {
  color: #eee !important;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
