.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem 4rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
}

.switch {
  width: fit-content !important;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    margin: 2rem auto;
    max-width: 500px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  .title {
    font-size: 1rem;
  }
}
