.container {
  width: 100%;
}

.header {
  width: 100%;
}

.header button {
  background-color: #e4056f;
  border: none;
  color: white;
  outline: none;
  padding: 8px 16px;
  border-radius: 2rem;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 0.5rem;
}

.header h2 {
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 700;
  color: #e4056f;
  margin: 1rem 0 1.5rem;
}

.notificationDetailsContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  gap: 1.5rem !important;
  align-items: flex-start !important;
  padding-right: 1rem !important;
}

.notificationDetailsInputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
  padding-right: 1rem !important;
  width: 100% !important;
}

.notificationDetailsInputContainer label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: block;
  margin: auto;
  width: 100%;
}

.notificationDetailsInputContainer input {
  background-color: white;
  border: 1px solid #808080;
  font-size: 16px;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: auto;
  width: 100%;
}

.notificationBenefitsContainer {
  width: 99%;
  margin-top: auto !important;
}

.notificationBenefitsContainer h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #808080;
}

.notificationBenefitsList {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #808080;
  padding: 0.8rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  justify-content: flex-start;
  align-items: center;
  padding-right: 3rem;
}

.notificationBenefitsList strong {
  color: white;
  background-color: #e4056f;
  border-radius: 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  padding: 0.5rem 0.8rem;
}

.emailIframeContainer {
  border: 1px solid #ccc !important;
  background: white;
  border-radius: 8px;
  position: relative;
  min-height: 35rem !important;
  overflow-y: hidden;
  padding: 1rem 1rem 3rem;
}

.emailIframeContainer strong {
  display: block;
  border-bottom: 2px solid #333;
  margin-bottom: 1rem;
  width: 100%;
}

.emailIframeContainer iframe {
  width: 100%;
  border: none !important;
  height: 100% !important;
}

@media (max-width: 1000px) {
  .header h2 {
    margin: 1rem 0;
  }
  .notificationDetailsInputContainer {
    padding: 0 0.5rem !important;
  }
  .notificationDetailsContainer {
    padding-right: 0;
    margin-bottom: 2rem;
  }
  .notificationBenefitsContainer {
    margin-top: 1.5rem;
  }
  .notificationBenefitsList {
    gap: 1rem;
    padding: 1.2rem 0.6rem;
  }
  .emailIframeContainer strong {
    text-align: center;
    border-bottom: none;
    font-size: 1.2rem;
    color: #808080;
  }
}
