.center-addCompany-title{
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 40px 0px 0px 0px;
}
.center-UserAddCompanyImg {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 50px 0px 30px 0px;
}
.UserAddCompanyImg {
  width: 10% !important;
  height: 50% !important;
  min-width: 12.5rem;
}

.addCompanyTitle{
  font-weight: bold;
}
