.container {
  padding: 0 5%;
  padding-top: 5.5rem !important;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;
}

.infoTitle {
  font-weight: 900;
  font-size: 28px;
  color: #333333;
  margin: 0 !important;
  width: 100%;
}

.infoPromotionName {
  font-weight: 500;
  font-size: 16px;
  color: #e4056f;
  margin: 0 !important;
  text-wrap: balance;
  width: 100%;
}

.infoDiscount {
  color: #e4056f;
  font-size: 17px;
  margin: 0 !important;
  width: 100%;
}

.infoRating {
  width: 100%;
}

.infoRatingStars {
  font-size: 30px !important;
}

.infoDescription {
  color: #333333;
  font-size: 15px;
  margin: 1rem 0 1.5rem !important;
  width: 100%;
}

.infoConditions {
  font-size: 16px;
  width: 100%;
}

.infoConditionsTitle {
  font-weight: 600;
}

.infoConditionsDescription {
  font-weight: 400;
  padding-left: 1rem;
  width: 100%;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.infoCommunes {
  color: #b20457;
  font-weight: 600;
  font-size: 16px;
  text-wrap: balance;
  margin: 0.5rem 0 1rem;
  width: 100%;
}

.infoCommunes svg {
  font-size: 17px !important;
  margin-right: 5px;
}

.promotionType {
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 8px;
  margin-left: auto;
}

.promotionTypeLeftSide {
  margin-left: 0;
  margin-bottom: 1rem;
  margin-right: auto;
}

.promotionTypeIcon {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .promotionTypeRightSide {
    display: none !important;
  }
  .infoConditionsDescription{
    padding-left: 0;
  }
}

.promotionTypeLabel {
  font-weight: 700;
  font-size: 10px;
}

.dates {
  background-color: #e6e6e6;
  border-radius: 20px;
  padding: 20px 25px 25px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0.5rem auto;
  max-width: 325px;
  width: 100%;
}

.datesTitle {
  font-weight: 700;
  font-size: 19px;
  color: #000;
  margin-bottom: 0 !important;
}

.datesDescription {
  font-weight: normal;
}

.buttonBenefitURL {
  background: #e4056f !important;
  border: none !important;
  border-radius: 16px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  margin-top: 1.5rem !important;
  height: 32px !important;
}

.buttonBenefitURL p {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.buttonBenefitURL:hover {
  background: #b20457 !important;
}

.buttonBenefitURL:active {
  background: #e4056f !important;
}

.infoReviewsMessage {
  text-align: center;
  width: 100%;
  color: #e40571;
  font-size: 14px;
  margin: 0 !important;
  font-weight: 500;
  text-wrap: balance;
}

.infoReviewsMessageLink {
  color: #e40571;
  display: inline-block;
  font-weight: 700 !important;
}

.infoReviewsMessageLink:first-of-type {
  margin-right: 4px;
}

.infoReviewsMessageLink:last-of-type {
  margin: 0 4px;
}

.infoReviewsMessageLink:hover {
  color: #e40571 !important;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 10%;
    grid-template-columns: 60% 40%;
    gap: 2rem;
  }
  .dates {
    margin: 1.5rem auto 0.5rem;
  }
  .promotionTypeLeftSide {
    display: none;
  }
  .infoCommunes {
    margin: 1rem 0 !important;
  }
}
