.container {
    background: #FCC02C !important;
    background-image: url("../../Assets/trans-pattern.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 75px);
    padding: 4rem 1rem 2rem;
}

.illustrationCol {
    justify-content: center !important;
    text-align: center !important;
    order: 2 !important;
    padding-top: 1rem !important;
}

.illustration {
    margin: auto !important;
    max-width: 400px;
    width: 80%;
}

@media screen and (min-width: 768px) {
    .container {
        flex-wrap: nowrap;
        padding: 2rem 1rem;
    }

    .illustrationCol {
        order: -2 !important;
    }

    .illustration {
        max-width: 110%;
        max-height: 900px;
        width: 110% !important;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        padding: 0 1rem 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .illustration {
        margin-left: 3rem !important;
    }
}