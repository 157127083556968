.tableName,
.tableEmail,
.tableAcces,
.tableSegment {
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
}

.tableSegment {
    color: #808080 !important;
}

.tableAcces {
    color: #E4056F !important;
}

.tableEmail {
    font-weight: 700 !important;
}

.tableActions {
    /* background-color: #eee !important; */
    display: flex !important;
    justify-content: center;
}

.tableActions button {
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
    padding: 0 !important;
    width: 60px !important;
}

.tableActions button img {
    max-width: 40px;
}
