.companyPromotionsLabel{
  font-weight: bold;
  font-style: italic;
  padding-left: 40px;
}

.companyPromotionsTopButtonsContainer{
  margin-left: 40px;
  margin-bottom: 40px;
}

.companyPromotionsTopButton{
  border: none !important;
  font-style: italic;
  background-color: transparent !important;
  box-shadow: transparent !important;
}