.UsersExcelModal-title {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    font-size: 20px;
    padding: 20px  0px;
    width: 100%;
    margin: 0px auto;
  }
  @media (min-width: 1000px) {
    .UsersExcelModal-title {
        width: 55%;
      }
}
  .UsersExcelModal {
    padding: 50px !important;
    text-align: center !important;
    margin: 0px auto !important;
  }
  .UsersExcelModal-content {
    padding: 10px 30px !important;
    text-align: center !important;
  }
  .UsersExcelModal-h3{
      padding: 0px 0px 15px 0px;
      font-weight: bolder;
      color: #808080;
  }