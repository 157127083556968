.container {
    background: #FCC02C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.wave {
    padding: 0 !important;
    margin-top: -4rem;
    margin-bottom: -.5rem;
    width: 100%;
}

.content {
    background: #FCC02C;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    text-align: left;
    padding: 2rem 1rem 4rem;
    width: 100%;
}

.navLink {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 15rem;
}

.navLink:hover {
    color: #fff !important;
    text-decoration: underline !important;
}

@media screen and (min-width: 768px) {
    .content {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        max-width: 1100px;
        width: 90%;
        padding-bottom: 4rem;
        margin-top: -2rem;
    }

    .logo {
        cursor: pointer;
        margin: auto;
        margin-left: 20%;
        max-width: 130px;
    }

    .nav {
        padding: 0;
        width: 15rem;
    }
}